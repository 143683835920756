import { realTimeDb } from "../../../../Firebase";
import { Col, Card } from "react-bootstrap";
import { useState, useEffect, useMemo } from "react";
import { onValue, ref } from "firebase/database";

import icontemps from "../../../../images/icontemps.svg";
import style from "../assets/style.css";
import { set } from "date-fns";
import swiming from "../../../../images/swiming.svg";
import gym from "../../../../images/gym.svg";
import cardio from "../../../../images/cardio.svg";
import axios from "axios";
import { CowntDown } from "./CowntDown";
import { useNoWaitingStore } from "../noWatingStore/store";
const SPORT = ["Fitness Space", "Cardio Space", "Swimming pool"];
export const WaitingTime = (props) => {
  const [place, setplace] = useState(null);
  const [wating_time, setwating_time] = useState(0);
  const [waitcolor, setWaitcolor] = useState("bg_green");
  const noWatingStore = useNoWaitingStore();
  const pos = SPORT.indexOf(props.id);
  // console.log(noWatingStore)
  var icon;
  switch (props.id) {
    case "Fitness Space": icon = gym;break;
    case "Cardio Space": icon = cardio;break;
    case "Swimming pool": icon = swiming;break;
    default: icon = icontemps;break;
  }


  useEffect(async() => {
    // console.log(props.id)
    const inter = setInterval(() => {
      if (SPORT.includes(props.id)) {
        noWatingStore.setTimeStart(props);
      }
      
      }, 5000);
      return () => clearInterval(inter);
    },[])

    const func = () => {
      if (place) {
        let calctime = (place.Queue / place.Current_speed).toFixed(1);
        if (place.Queue === 0) {
          setwating_time(0);
        } else {
          calctime == Infinity ? setwating_time(0) : setwating_time(calctime);
        }
        if (wating_time <= 1) setWaitcolor("bg_green bg-gradient");
        if (wating_time > 1 && wating_time <= 3) setWaitcolor("bg-warning");
        if (wating_time > 3) setWaitcolor("bg_red");
      }
    }
  useMemo(() => {
    func()
    // console.log(place?.Queue)
    // console.log(place?.Sitting)
  }, [place?.Queue, place?.Sitting]);
  useEffect(() => {
    const query = ref(realTimeDb, "Places");
    onValue(query, (snapshot) => {
      if (snapshot.exists() && place === null) {
        const data = snapshot.val();
        const allplaces = Object.values(data);
        const allnew = allplaces[0]
        setplace(allnew.find((item) => item.Name == props.id));
      }
    });
  }, []);
  return (
    <Col lg={6} md={6}>
      <Card className={`widget-stat  ${waitcolor} `}>
        <Card.Body className="py-4 d-flex justify-content-between">
          <div className="media ai-icon align-items-center " style={{width:"100%"}}>
            { props?.Time && <img className="logo-abbr" src={icon} alt=""  height={"190"}/>}
            { !props?.Time && props.id === "Cardio Space" && <img className="logo-abbr" src={icon} alt=""  height={"130"}/>}
            { !props?.Time && props.id === "Fitness Space" && <img className="logo-abbr" src={icon} alt=""  height={"80"}/>}
            { !props?.Time && props.id === "Swimming pool" && <img className="logo-abbr" src={icon} alt=""  height={"90"}/>}

            <div className="media-body text-right font-tv">
              {place?.Capacity >= 36 && (
                <>
                  <h4 className="mb-0 text-white">
                    {wating_time}
                    <sup style={{ fontSize: 42 }}>min</sup>
                  </h4>
                  <p className="mb-1 text-white">Waiting time</p>
                </>
              )}
              {place?.Capacity < 36 && !noWatingStore.timeStart[pos] &&
                <>
                  <h5 className="text-white">Queue</h5>
                  <h4 className="mb-0 text-white pt-4">
                  
                    {noWatingStore.queue[pos]} {" "}<br/>
                    <sup style={{ fontSize: 30 }}>No waiting</sup>
                  </h4>
                  <p className="mb-1 text-white"></p>
                </>
                }
              { place?.Capacity < 36 && noWatingStore.timeStart[pos] &&
              <div className="media-body text-right font-tv">
              <h5 className="text-white">Time to start</h5>
                
                  <CowntDown time={noWatingStore.startime[pos]}/> {" "}<br/>
                  <h4 className="mb-0 text-white">
                    <sup style={{ fontSize: 30 }}>No Waiting</sup>
                  </h4>
                  <p className="mb-1 text-white"></p>
              
           
 
                </div>
              }
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};
