import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from "axios";
import { realTimeDb } from "../../../../Firebase";
import { onValue, ref } from "firebase/database";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

am4core.useTheme(am4themes_animated);

function ChartComp(id) {
  // console.log(id)
  
  const [data, setData] = useState([]);
  const [place, setPlace] = useState(null);
  const [currentOccupancy, setCurrentOccupancy] = useState(0);
  const chart = useRef(null);
  const first = useRef(false)
  const [h , setH] = useState(0)
  // Fetch place data when the component mounts
  useEffect(() => {
    const query = ref(realTimeDb, "Places");
    onValue(query, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const allplaces = Object.values(data);
        const allnew = allplaces
        setPlace(allnew.find((item) => item.Name === id.id));
      }
    });
  }, []);

  // Fetch and update chart data when place.Queue or place.Sitting changes
  useEffect(() => {
    const fetchData = async () => {
      const collection = [];
      const currentDate = new Date();
      const oneWeekAgo = new Date(currentDate);
      const currentHour = currentDate.getHours();
      if (h !== currentHour)
        setH(currentHour)
      oneWeekAgo.setDate(currentDate.getDate() - 7);
      const formattedDate = oneWeekAgo.toISOString().slice(0, 10);

      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_ENDPOINT}/occupancyDailyHourlyRange`,
        { params: { startDate: formattedDate,endDate :formattedDate, agency: id.id } }
      );
      const fetchedData = res.data;

      if (place) {
        for (let i = 0; i < fetchedData.length; i++) {
          const dateTimeString = fetchedData[i].date;
          const date = new Date(dateTimeString);
          const hour = date.getHours();
          
          if (hour - 1 !== currentHour) {
            collection.push({
              category: `${hour - 1} - ${hour}`,
              value: fetchedData[i]?.averageOccupancy,
              percentage:
                ((fetchedData[i].averageOccupancy / place?.Capacity) * 100)?.toFixed(2),
              hour:hour,
            });
          } else {
            setCurrentOccupancy(place.Queue + place.Sitting);
            collection.push({
              category: `${hour - 1} - ${hour}`,
              value: (place.Queue + place.Sitting),
              percentage: (((place.Queue + place.Sitting) / place.Capacity) * 100)?.toFixed(2),
              selected: true,
              hour:hour,
            });
          }
        }
        setData(collection);
        //(collection)
      }
    };
    fetchData();
    // console.log(data)
  }, [id, place?.Queue, place?.Sitting , data.length > 0]);
  useLayoutEffect(() => {
    if (!chart.current && data?.length > 0) {
      
    //("chart time")
    chart.current = am4core.create(`${id.id}`, am4charts.XYChart);

    const textColor = "#000";
    const lineColor = "#fd9e04";
    const accentColor = "#e94b4c";
    const font = "Segoe UI";
    const textTitle = "";

    chart.current.paddingLeft = 40;
    chart.current.paddingRight = 4;
    chart.current.seriesContainer.zIndex = -1;

    chart.current.data = data;
    // const maxValue = 200;
    const dataLength = data.length;

    const title = chart.current.titles.create();
    title.text = textTitle;
    title.fill = textColor;
    title.fontFamily = font;
    title.fontSize = 16;
    title.fontWeight = 600;
    title.align = "left";

    // Create axes
    const categoryAxis = chart.current.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.dataFields.category = "category";
    categoryAxis.dataFields.valueY = "value";
    categoryAxis.renderer.minGridDistance = 60;
    categoryAxis.startLocation = 0.5;
    categoryAxis.endLocation = 1;
    categoryAxis.renderer.line.strokeOpacity = 0;
    categoryAxis.renderer.line.strokeWidth = 0;
    categoryAxis.renderer.line.opacity = 0;
    categoryAxis.renderer.labels.template.fill = am4core.color(textColor);
    categoryAxis.renderer.labels.template.fontWeight = 600;
    categoryAxis.renderer.labels.template.fontSize = 17;
    categoryAxis.renderer.labels.template.fontFamily = font;

    let valueAxis = chart.current.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;
    valueAxis.tooltip.disabled = true;
    valueAxis.title.text = `Average Occupation`;
    valueAxis.cursorTooltipEnabled = false;
    // valueAxis.min = 0; // Minimum value
    // valueAxis.max = 20;

    const valueAxis2 = chart.current.yAxes.push(new am4charts.ValueAxis());
    valueAxis2.baseValue = 100;
    valueAxis2.renderer.grid.template.strokeDasharray = "3,3";
    valueAxis2.renderer.labels.template.disabled = true;

    const gradient = new am4core.LinearGradient();
    gradient.addColor(lineColor, 0);
    gradient.addColor(lineColor, 0.3);
    gradient.rotation = 0;

    const LineSeries = chart.current.series.push(new am4charts.LineSeries());
    LineSeries.dataFields.valueY = "value";
    LineSeries.dataFields.categoryX = "category";
    LineSeries.dataFields.selected = "selected";
    LineSeries.smoothing = "monotoneX";
    LineSeries.strokeWidth = 0;
    LineSeries.fillOpacity = 1;
    LineSeries.yAxis = valueAxis;
    LineSeries.tooltip.getFillFromObject = false;
    LineSeries.tooltip.background.filters.clear();
    LineSeries.tooltip.background.fill = accentColor;
    LineSeries.tooltip.background.fillOpacity = 1;
    LineSeries.tooltip.background.strokeWidth = 0;
    LineSeries.tooltip.pointerOrientation = "up";
    LineSeries.tooltip.dy = 10;
    LineSeries.tooltip.label.maxWidth = "10px";
    LineSeries.tooltip.label.padding(0, 0, 0, 0);
    LineSeries.fill = gradient;

    const bulletA = LineSeries.bullets.push(new am4charts.CircleBullet());
    bulletA.circle.radius = 6;
    bulletA.circle.fillOpacity = 0;
    bulletA.circle.strokeWidth = 0;
    bulletA.tooltipHTML = `
        <div style="
          width:45px;
          display:flex;
          flex-direction: column;
          justify-content: center;
          align-items:center"
        > 
          <span style="font-size:26px;font-weight:700">{value}</span>
          <span style="font-size:12px;" >Occup</span>
        </div>
      `;

    const LineSeries2 = chart.current.series.push(new am4charts.LineSeries());
    LineSeries2.fill = am4core.color("white");
    LineSeries2.fillOpacity = 1;
    LineSeries2.stroke = lineColor;
    LineSeries2.strokeWidth = 3;
    LineSeries2.dataFields.valueY = "value";
    LineSeries2.dataFields.categoryX = "category";
    LineSeries2.dataFields.selected = "selected";
    LineSeries2.dataFields.percentage = "percentage";
    LineSeries2.smoothing = "monotoneX";
    LineSeries2.yAxis = valueAxis2;
    LineSeries2.baseValue = 0;
    LineSeries2.maskBullets = false;
    LineSeries2.tooltip.getFillFromObject = false;
    LineSeries2.tooltip.background.filters.clear();
    LineSeries2.tooltip.background.fill = accentColor;
    LineSeries2.tooltip.background.fillOpacity = 1;
    LineSeries2.tooltip.background.stroke = accentColor;
    LineSeries2.tooltip.pointerOrientation = "down";
    LineSeries2.tooltip.dy = -10;
    LineSeries2.zIndex = 10;

    const bullet = LineSeries2.bullets.push(new am4charts.CircleBullet());
    bullet.circle.radius = 6;
    bullet.circle.fill = am4core.color(accentColor);
    bullet.circle.stroke = am4core.color("#fff");
    bullet.circle.strokeWidth = 3;
    bullet.tooltipText = "[bold]{percentage}%[/]";

    const columnSeries = chart.current.series.push(new am4charts.ColumnSeries());

    // columnSeries.data = data.map((dt) => {
    //   dt.maxValue = 1000;
    //   return dt;
    // });
    columnSeries.dataFields.valueY = 0;
    columnSeries.dataFields.selected = "selected";
    columnSeries.dataFields.categoryX = "category";
    columnSeries.zIndex = 1;
    columnSeries.yAxis = valueAxis;
    // columnSeries.columns.template.fill = accentColor;
    // // columnSeries.columns.template.width = am4core.percent(1000);
    // columnSeries.columns.template.stroke = accentColor;
    // columnSeries.columns.template.strokeWidth = 0;

    const columnSeries2 = chart.current.series.push(new am4charts.ColumnSeries());
    // columnSeries2.data = columnSeries.data;
    columnSeries2.dataFields.valueY = 0;
    columnSeries2.dataFields.selected = "selected";
    columnSeries2.dataFields.categoryX = "category";
    columnSeries2.zIndex = 1;
    // columnSeries2.columns.template.fill = accentColor;
    columnSeries2.columns.template.strokeWidth = 1;
    // columnSeries2.columns.template.stroke = accentColor;
    // columnSeries2.columns.template.width = am4core.percent(1000);
    columnSeries2.yAxis = valueAxis;

    columnSeries.columns.template.adapter.add(
      "fillOpacity",
      function (center, target) {
        if (target.dataItem.selected && target.dataItem.index !== 0) {
          return 1;
        }
        return 0;
      }
    );

    columnSeries2.columns.template.adapter.add(
      "fillOpacity",
      function (center, target) {
        if (
          target.dataItem.selected &&
          target.dataItem.index !== dataLength - 1
        ) {
          return 1;
        }
        return 0;
      }
    );

    columnSeries2.columns.template.adapter.add(
      "strokeOpacity",
      function (center, target) {
        if (
          target.dataItem.selected &&
          target.dataItem.index !== dataLength - 1
        ) {
          return 1;
        }
        return 0;
      }
    );

    bullet.circle.adapter.add("opacity", function (center, target) {
      return target.dataItem.selected ? 1 : 0;
    });

    bullet.adapter.add("showTooltipOn", function (center, target) {
      if (target.dataItem && target.dataItem.selected !== undefined) {
        return "always";
      }
      return "";
    });

    bulletA.adapter.add("showTooltipOn", function (center, target) {
      if (target.dataItem && target.dataItem.selected !== undefined) {
        return "always";
      }
      return "";
    });

    chart.current.data = data;
  }
  else if (chart.current && data?.length > 0)
  {
    if (first.current === false)
    {
      chart.current.data = data
      first.current = true
    }
    else
    {
    for (let i = 0 ; i < data.length ; ++i){
      if (chart?.current?.data[i]?.value !== data[i]?.value)
      {
        chart.current.data[i].value = data[i].value;
        chart.current.data[i].percentage = data[i].percentage;
      } 
      if (chart?.current?.data[i]?.hasOwnProperty("selected") && chart?.current?.data[i]?.hour -1 !== h )
      {
        
        delete chart.current.data[i].selected
        chart.current.invalidateData();
      }
      if (!chart?.current?.data[i]?.hasOwnProperty("selected") && chart?.current?.data[i]?.hour -1 === h )
      {
        chart.current.data[i].selected = true
        chart.current.invalidateData();

      }
    }
 
    chart.current.invalidateRawData();
  }

  }
 

  }, [place?.Queue , place?.Sitting , data.length > 0]);
  // useEffect(()=>{if (data && chart?.current?.data){chart.current.data = data;chart.current.data = data}},[h])
  useEffect(() => {if (chart?.current && data?.length > 0 ){chart.current.data = data;chart.current.invalidateData();
    // console.log("data changed")

    for (let i = 0 ; i < data.length ; ++i){
      if (chart?.current?.data[i]?.value !== data[i]?.value)
      {
        chart.current.data[i].value = data[i].value;
        chart.current.data[i].percentage = data[i].percentage;
      } 
      if (chart?.current?.data[i]?.hasOwnProperty("selected") && chart?.current?.data[i]?.hour -1 !== h )
      {
        
        delete chart.current.data[i].selected
        chart.current.invalidateData();
      }
      if (!chart?.current?.data[i]?.hasOwnProperty("selected") && chart?.current?.data[i]?.hour -1 === h )
      {

        chart.current.data[i].selected = true
        chart.current.invalidateData();

      }
    }
    chart.current.invalidateData();

    chart.current.invalidateRawData();}},[data?.length > 0 && chart?.current])

  return <div id={`${id.id}`} style={{ width: "100%", height: "410px" }}></div>;
}

export default ChartComp;