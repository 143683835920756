import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ico1 from "../../../images/ico1.svg";
import ico2 from "../../../images/ico2.svg";
import ico3 from "../../../images/ico3.svg";
import ico4 from "../../../images/ico4.svg";
import ico5 from "../../../images/ico5.svg";
import AllAgency from "./AllAgency";


const Home = () => {
   
   return (
      <>
         <div className="row">
            <div className="col-xl col-md-6">
               <div className="card">
                  <div className="card-body p-4">
                     <div className="d-inline-block mb-4 ml--12 position-relative donut-chart-sale">
                        <span
                           className="donut1"
                           data-peity='{ "fill": ["rgb(192, 255, 134)", "rgba(255, 255, 255, 1)"],   "innerRadius": 45, "radius": 10}'
                           style={{ display: "none" }}
                        >
                           4/8
                        </span>
                        <svg className="peity" height={110} width={110}>
                           <path
                              d="M 55 0 A 55 55 0 0 1 55 110 L 55 100 A 45 45 0 0 0 55 10"
                              data-value={4}
                              fill="rgb(192, 255, 134)"
                           />
                           <path
                              d="M 55 110 A 55 55 0 0 1 54.99999999999999 0 L 54.99999999999999 10 A 45 45 0 0 0 55 100"
                              data-value={4}
                              fill="rgba(255, 255, 255, 1)"
                           />
                        </svg>
                        <small className="text-primary">
                           <img className="" src={ico1} alt="" height={40} width={40} />
                        </small>
                        <span className="circle bg-primary" />
                     </div>
                     <h2 className="fs-24 text-black font-w600 mb-0">15</h2>
                     <span className="fs-14">Total des agences</span>
                  </div>
               </div>
            </div>
            <div className="col-xl col-md-6 col-sm-6">
               <div className="card">
                  <div className="card-body p-4">
                     <div className="d-inline-block mb-4 ml--12 position-relative donut-chart-sale">
                        <span
                           className="donut1"
                           data-peity='{ "fill": ["rgb(255, 195, 210)", "rgba(255, 255, 255, 1)"],   "innerRadius": 45, "radius": 10}'
                           style={{ display: "none" }}
                        >
                           3/8
                        </span>
                        <svg className="peity" height={110} width={110}>
                           <path
                              d="M 55 0 A 55 55 0 0 1 93.89087296526012 93.89087296526012 L 86.81980515339464 86.81980515339464 A 45 45 0 0 0 55 10"
                              data-value={3}
                              fill="rgb(255, 195, 210)"
                           />
                           <path
                              d="M 93.89087296526012 93.89087296526012 A 55 55 0 1 1 54.99999999999999 0 L 54.99999999999999 10 A 45 45 0 1 0 86.81980515339464 86.81980515339464"
                              data-value={5}
                              fill="rgba(255, 255, 255, 1)"
                           />
                        </svg>
                        <small className="text-primary">
                           <img className="" src={ico2} alt="" height={40} width={40} />
                        </small>
                        <span className="circle bg-danger" />
                     </div>
                     <h2 className="fs-24 text-black font-w600 mb-0">3</h2>
                     <span className="fs-14">Agences non-actives</span>
                  </div>
               </div>
            </div>
            <div className="col-xl col-md-4 col-sm-6">
               <div className="card">
                  <div className="card-body p-4">
                     <div className="d-inline-block mb-4 ml--12 position-relative donut-chart-sale">
                        <span
                           className="donut1"
                           data-peity='{ "fill": ["rgb(255, 213, 174)", "rgba(255, 255, 255, 1)"],   "innerRadius": 45, "radius": 10}'
                           style={{ display: "none" }}
                        >
                           5/8
                        </span>
                        <svg className="peity" height={110} width={110}>
                           <path
                              d="M 55 0 A 55 55 0 1 1 16.109127034739892 93.89087296526012 L 23.180194846605364 86.81980515339464 A 45 45 0 1 0 55 10"
                              data-value={5}
                              fill="rgb(255, 213, 174)"
                           />
                           <path
                              d="M 16.109127034739892 93.89087296526012 A 55 55 0 0 1 54.99999999999999 0 L 54.99999999999999 10 A 45 45 0 0 0 23.180194846605364 86.81980515339464"
                              data-value={3}
                              fill="rgba(255, 255, 255, 1)"
                           />
                        </svg>
                        <small className="text-primary">
                           <img className="" src={ico3} alt="" height={40} width={40} />
                        </small>
                        <span className="circle bg-warning" />
                     </div>
                     <h2 className="fs-24 text-black font-w600 mb-0">50%</h2>
                     <span className="fs-14">Attraction des agences</span>
                  </div>
               </div>
            </div>
            <div className="col-xl col-md-4 col-sm-6">
               <div className="card">
                  <div className="card-body p-4">
                     <div className="d-inline-block mb-4 ml--12 position-relative donut-chart-sale">
                        <span
                           className="donut1"
                           data-peity='{ "fill": ["rgb(238, 252, 255)", "rgba(255, 255, 255, 1)"],   "innerRadius": 45, "radius": 10}'
                           style={{ display: "none" }}
                        >
                           8/8
                        </span>
                        <svg className="peity" height={110} width={110}>
                           <path
                              d="M 55 0 A 55 55 0 1 1 54.99 0 L 54.99 10 A 45 45 0 1 0 55 10"
                              data-value={8}
                              fill="rgb(238, 252, 255)"
                           />
                        </svg>
                        <small className="text-primary">
                           <img className="" src={ico4} alt="" height={40} width={40} />
                        </small>
                        <span className="circle bg-info" />
                     </div>
                     <h2 className="fs-24 text-black font-w600 mb-0">
                        8 Heurs
                     </h2>
                     <span className="fs-14">Fille d’attendre</span>
                  </div>
               </div>
            </div>

            <div className="col-xl col-md-4 col-sm-6">
               <div className="card">
                  <div className="card-body p-4">
                     <div className="d-inline-block mb-4 ml--12 position-relative donut-chart-sale">
                        <span
                           className="donut1"
                           data-peity='{ "fill": ["rgb(242, 255, 253)", "rgba(255, 255, 255, 1)"],   "innerRadius": 45, "radius": 10}'
                           style={{ display: "none" }}
                        >
                           8/8
                        </span>
                        <svg className="peity" height={110} width={110}>
                           <path
                              d="M 55 0 A 55 55 0 1 1 54.99 0 L 54.99 10 A 45 45 0 1 0 55 10"
                              data-value={8}
                              fill="rgb(242, 255, 253)"
                           />
                        </svg>
                        <small className="text-primary">
                           <img className="" src={ico5} alt="" height={40} width={40} />
                        </small>
                        <span className="circle bg-success" />
                     </div>
                     <h2 className="fs-24 text-black font-w600 mb-0">
                        974 Person
                     </h2>
                     <span className="fs-14">Total de visiteurs</span>
                  </div>
               </div>
            </div>

            <div className="col-xl-12 col-xxl-12">
              <AllAgency />
            </div>
           
         </div>
      </>
   );
};

export default Home;
