import React, { Fragment, useState,useEffect } from "react";
import { Button } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import { db } from '../../../Firebase';
import { collection, addDoc,query,onSnapshot } from 'firebase/firestore';
import {useHistory } from "react-router-dom";
import Select from "react-select";

const AgenceForm = () => {

   const [name,setName] = useState();
   const [phone,setPhone] = useState();
   const [email,setEmail] = useState();
   const [address,setAddress] = useState();
   const [agences,setAgences] = useState([]);
   const [closestAgency, setclosestAgency] = useState([{ agence: "", distancekm: "" }]);

   const history = useHistory()

   useEffect(() => {
      const q = query(collection(db, "Agences_v1"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let todosArray = [];
        querySnapshot.forEach((doc) => {
          todosArray.push({ ...doc.data(), id: doc.id });
        });
        setAgences(todosArray.map(item=> {return {value:item.id,label:item.name}}));
      });
      return () => unsub();
    }, []);

   const handleSubmit = async (e) => {
      e.preventDefault();
     
          await addDoc(collection(db, "Agences_v1"), {
              name,
              phone,
              email,
              address,
              closestAgency
          });
          setName("");
          setPhone("")
          setEmail("")
          setAddress("")
          history.push('/agence-table');
  }

  // MIDOAJAX
  const handleAddField = (e) => {
   e.preventDefault();
   if(closestAgency.length<2)
   setclosestAgency([...closestAgency, { agence: "", distancekm: "" }]);
  };

  const handleChange = (index, field, value) => {
    const updatedFields = [...closestAgency];
    updatedFields[index][field] = value;
    setclosestAgency(updatedFields);
  };

   return (
      <Fragment>
         <PageTitle activeMenu="Agence" motherMenu="Form" />

         <div className="row">
            <div className="col-lg-12">
               <div className="card">
                  <div className="card-header">
                     <h4 className="card-title">Nouvelle agence</h4>
                  </div>
                  <div className="card-body">
                     <div className="form-validation">
                        <form
                           className="form-valide"
                        >
                           <div className="row">
                              <div className="col-xl-12">
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Nom de l'agence
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                       <input
                                          value={name}
                                          type="text"
                                          className="form-control"
                                          id="val-username"
                                          name="val-username"
                                          placeholder="Entrez un nom.."
                                          onChange={(e) => setName(e.target.value)}
                                       />
                                    </div>
                                 </div>
                                 
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Téléphone de l'agence
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                       <input
                                          value={phone}
                                          type="text"
                                          className="form-control"
                                          id="val-username"
                                          name="val-username"
                                          placeholder="Entrez votre téléphone.."
                                          onChange={(e) => setPhone(e.target.value)}
                                       />
                                    </div>
                                 </div>
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Email de l'agence
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                       <input
                                          value={email}
                                          type="text"
                                          className="form-control"
                                          id="val-username"
                                          name="val-username"
                                          placeholder="Entrer votre Email.."
                                          onChange={(e) => setEmail(e.target.value)}
                                       />
                                    </div>
                                 </div>
                                 {/* MIDOAJAX */}
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Liste des agences
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                          {closestAgency.map((field, index) => (
                                          <div key={index} className="row mt-2">
                                             <div className="col-lg-6">
                                             <Select
                                                value={closestAgency[index].agences}
                                                onChange={(e) => handleChange(index, "agence", e)}
                                                options={agences}
                                                style={{
                                                   lineHeight: "40px",
                                                   color: "#7e7e7e",
                                                   paddingLeft: " 15px",
                                                }}
                                       />
                                             </div>
                                             <div className="col-lg-6">
                                             <input
                                                type="text"
                                                className="form-control"
                                                value={field.distancekm}
                                                placeholder="Distance.."
                                                onChange={(e) => handleChange(index, "distancekm", e.target.value)}
                                             />
                                             </div>
                                          </div>
                                          ))}
                                          <div className="row justify-content-end">
                                          <button className="btn btn-primary mt-2" onClick={handleAddField}>
                                          <i className="fa fa-plus-circle mr-2"></i> Ajouter
                                          </button>
                                          </div>
                                    </div>
                                 </div>
                                 
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-suggestions"
                                    >
                                       Adresse de l'agence{" "}
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                       <textarea
                                          value={address}
                                          className="form-control"
                                          id="val-suggestions"
                                          name="val-suggestions"
                                          rows="5"
                                          placeholder="Entrez votre adresse.."
                                          onChange={(e) => setAddress(e.target.value)}
                                       ></textarea>
                                    </div>
                                 </div>
                                 <div className="form-group row">
                                    <div className="col-lg-4 "></div>
                                    <div className="col-lg-6">
                                       <Button onClick={handleSubmit} variant="primary">
                                       Sauvegarder{" "}
                                       </Button>
                                    </div>
                                 </div>
                              </div>
                              
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
           
         </div>
      </Fragment>
   );
};

export default AgenceForm;
