import { usePlacesStore } from "../../../../zustand-store/store";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { Chart } from "react-google-charts";

import "swiper/css";
import "swiper/css/pagination";
import { useEffect, useState } from "react";

const randomColor = () => {
  const randomHex = Math.floor(Math.random() * 16777215).toString(16);
  return "#" + "0".repeat(6 - randomHex.length) + randomHex;
};

const dataConverter = (data) => {
  if (data.length === 0) return [];
  // console.log(data);
  // console.log(typeof data);
  const result = [
    ["Visits", "Vistis per each Station"],
    ...data.stationName.map((item, index) => [item, data.stationValue[index]]),
  ];
  // console.log(result);
  return result;
};
const options = (colors) => {
  return {
    legend: "none",
    pieSliceText: "label",
    is3D: true,

    pieStartAngle: 100,
  };
};

export const WorkLoad = () => {
  const [stations, setStations] = useState([]);
  const placesStore = usePlacesStore();
  var colors = [];

  useEffect(() => {
    const localStation = [];
    placesStore?.stations?.map((agencyStations) => {
      if (agencyStations.length < 1) return;
      agencyStations.map((station) => {
        const index = localStation.findIndex(
          (item) => item.name === station.name
        );
        if (index !== -1) {
          localStation[index].stationValue = [
            ...localStation[index].stationValue,
            ...station.stationValue,
          ];
          localStation[index].stationName = [
            ...localStation[index].stationName,
            ...station.stationName,
          ];
          localStation[index].stationRate = [
            ...localStation[index].stationRate,
            ...station.stationRate,
          ];
          return;
        }
        localStation.push({
          name: station.name,
          stationValue: station.stationValue,
          stationName: station.stationName,
          stationRate: station.stationRate,
        });
      });
    });
    localStation.forEach((station) => {
      const total = station.stationValue.reduce((a, b) => a + b, 0);
      station.stationRate = station.stationRate.map(
        (_, index) =>
          parseInt(((station.stationValue[index] / total) * 100).toFixed(2)) ||
          0
      );
    });
    setStations(localStation);
  }, [placesStore]);

  return (
    <Swiper
      pagination={true}
      modules={[Pagination, Autoplay]}
      className="col-xl-6 pb-4"
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 1500,
        pauseOnMouseEnter: true,
      }}
    >
      {stations.length > 0 &&
        stations.map((station, findex, elements) => {
          return (
            <SwiperSlide key={findex}>
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h2 className="card-title">{station?.name}</h2>
                </div>
                <div className="card-body pb-0">
                  <p>Percentage du client serveur par chaque station</p>
                  {station?.stationRate?.length > 0 ? (
                    <>
                      <div style={{ display: "none" }}>
                        {
                          (colors = station.stationRate.map(() =>
                            randomColor()
                          ))
                        }
                      </div>

                      <Chart
                        chartType="PieChart"
                        options={options(colors)}
                        data={dataConverter(station)}
                        width={"100%"}
                        height={"400px"}
                        style={{ position: "relative", top: "-10px" }}
                      />
                      {station.stationRate.map((item, index) => (
                        <>
                          <li
                            className=" text-bold text-sm-left font-italic text-dark"
                            key={index}
                          >
                            <span
                              style={{
                                display: "inline-block",
                                width: "10px",
                                height: "10px",
                                borderRadius: "50%",
                                backgroundColor: `${colors[index]}`,
                              }}
                            ></span>{" "}
                            {station.stationName[index]} {" ("}{" "}
                            {item.toFixed(2)}%{") "}
                            <span className="font-weight-bold">
                              {elements[findex].stationValue[index]} Client
                            </span>
                          </li>
                        </>
                      ))}
                    </>
                  ) : (
                    <Chart
                      chartType="PieChart"
                      options={options(["#a21f1f"])}
                      data={[
                        ["Visits", "Vistis per each Station"],
                        ["No Data", 100],
                      ]}
                      width={"100%"}
                      height={"400px"}
                    />
                  )}
                </div>
              </div>
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};
