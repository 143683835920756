import React, { Fragment, useEffect, useState, useRef } from "react";
import Clock from "react-live-clock";
import PerfectScrollbar from "react-perfect-scrollbar";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import $ from "jquery";
import "bootstrap-daterangepicker";
import { format } from "date-fns";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import { usePlacesStore } from "../../../zustand-store/store";
import {
  Row,
  Col,
  Card,
  Container,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  SplitButton,
  Button,
} from "react-bootstrap";


import ChartScoring from "../../components/Dashboard/ChartScoring";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Occupation } from "./components/Occupation";
import { useChartStore } from "../../../zustand-store/chartStore";
import { WaitingTime } from "./components/WaitingTime";
import { Queue } from "./components/Queue";
import { Operations } from "./components/ServiceTime";
import { GeoState } from "./components/GeoState";
import { Attractivness } from "./components/Attractivness";
import { ConversionR } from "./components/ConversionR";
import { WorkLoad } from "./components/WorkLoad";
import { Scoring } from "./components/Scoring";

const DATE_OPTIONS = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
function convertToFrenchDate(dateStr) {
  const dateParts = dateStr.split('-');
  const year = dateParts[2];
  const month = dateParts[1];
  const day = dateParts[0];

  const date = new Date(year, month - 1, day);

  const frenchDays = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
  const frenchMonths = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

  const dayOfWeek = date.getDay();
  const monthName = frenchMonths[month - 1];

  const frenchRepresentation = `${frenchDays[dayOfWeek]} ${day} ${monthName} ${year}`;

  return frenchRepresentation;
}

export const Overview = (props) => {
  const places = usePlacesStore();
  const chartStore = useChartStore();


  const [workloadGlobale, setWorkloadGlobale] = useState();
  const [caisseWorkload, setCaisseWorkload] = useState();
  const dispatch = useDispatch();
  const colors = ["#FEC64F", "#00BF98", "#FF5E4D"];

  useEffect(() => {
    const tokenDetailsString = localStorage.getItem("userDetails");
    let tokenDetails = "";
    if (!tokenDetailsString) {
      dispatch(logout(props.history));
      return;
    }
  },[]);
  const handleDelete = (value) => {
    chartStore.setPlacesCheckedValues(
      chartStore.placesCheckedValues.filter((item) => item !== value)
    );
  };
  const history = useHistory();



  //(places.capacity);
  return (
    <Fragment>
      {/* <Nav/> */}
      <Container fluid className="py-5 px-5 bg-header-tv">
      <div className="row">
  <div className="col-xl-6 col-lg-6">
    <div className="fade alert-dismissible fade show alert alert-outline-light text-white px-2 py-1 d-flex align-items-center">
      <span style={{ fontSize: 14 }} className="col-8">
        Expérience client en point des vente:
        <span style={{ color: "#00C8FF" }} className="col-4">
          {convertToFrenchDate(chartStore.startDate.toString().split`-`.slice(0,4).reverse().join`-`)} a {convertToFrenchDate(chartStore.endDate.toString().split`-`.slice(0,4).reverse().join`-`)}
        </span>
      </span>
      <span>
        {chartStore.placesCheckedValues.length > 0 &&
          chartStore.placesCheckedValues.map((item, index) => (
            <button
              key={index + "asdx"}
              onClick={() => {
                handleDelete(item);
              }}
              style={{border:"2px solid gray"}}
              aria-label="Close"
              type="button"
              className="bg-dark text-white mx-2 text-xl rounded w-auto px-2 h-10 "
            >
              {item}
              <span aria-hidden="true" className="text-lg">
                ×
              </span>
            </button>
          ))}
      </span>
    </div>
  </div>
  <div className="col-xl-6 col-lg-6">
    <div className="fade alert-dismissible fade show alert alert-outline-light text-white px-2 py-1 d-flex align-items-center">
      <span style={{ fontSize: 14 }} className="col-6">
        Efficacité et ressources du:
        <span style={{ color: "#00C8FF" }} className="col-6">
          {convertToFrenchDate(chartStore.startDate.toString().split`-`.slice(0,4).reverse().join`-`)} a {convertToFrenchDate(chartStore.endDate.toString().split`-`.slice(0,4).reverse().join`-`)}
        </span>
      </span>
      <span>
        {chartStore.placesCheckedValues.length > 0 &&
          chartStore.placesCheckedValues.map((item, index) => (
            <button
              key={index + "asdx"}
              onClick={() => {
                handleDelete(item);
              }}
              style={{border:"2px solid gray"}}
              aria-label="Close"
              type="button"
              className="bg-dark text-white mx-2 text-xl rounded w-auto px-2 h-10 "
            >
              {item}
              <span aria-hidden="true" className="text-lg">
                ×
              </span>
            </button>
          ))}
      </span>
    </div>
  </div>
</div>

          {/* <div className="col-xl-6 col-lg-6">
            <div className="fade alert-dismissible fade show alert alert-outline-light text-white px-2 py-1 d-flex align-items-center">
              <span style={{ fontSize: 14 }} className="col-6">
              Efficacité et ressources du
              <span style={{ color: "#00C8FF" }} className="col-6">
              {convertToFrenchDate(chartStore.startDate.toString().split`-`.slice(0,4).reverse().join`-`)} a {convertToFrenchDate(chartStore.endDate.toString().split`-`.slice(0,4).reverse().join`-`)}
                </span>
              </span>
         
                   <span>
                {chartStore.placesCheckedValues.length > 0 &&
                  chartStore.placesCheckedValues.map((item, index) => (
                    <button
                      key={index + "asds"}
                      onClick={() => {
                        handleDelete(item);
                      }}
                      style={{border:"2px solid gray"}}
                      aria-label="Close"
                      type="button"
                      className="bg-dark text-white mx-2 text-xl rounded w-auto px-2 h-10 "
                    >
                      {item}
                      <span aria-hidden="true" className="text-lg">
                        ×
                      </span>
                    </button>
                  ))}
              </span>
            </div>
          </div> */}

        <div className="row">
          <div className="col-xl-6 col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <Row>
                  <div className="col-sm-6 pr-2">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title" style={{textTransform: 'none'}}>Temps d'attente</h5>
                      </div>
                      <div
                        className="card-body widget-stat"
                        style={{ cursor: "pointer" }}
                      >
                        <WaitingTime/>
                        
                      </div>
                    </div>
                  </div>
                  <Queue/>
                </Row>
              </div>

              <div className="col-lg-12">
                <Row>
                  <Operations/>

                  <Occupation />
                </Row>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-12">
            <div className="row">
              <div className="col-lg-12">
                <Row>
                  <div className="col-lg-6 pr-2">
                    <Row>
                      <GeoState/>
                      <Attractivness/>
                      <ConversionR/>
                    </Row>
                  </div>

                  <WorkLoad/>
                </Row>
                <Scoring/>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

export default Overview;
