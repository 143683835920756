import React, { useState, Fragment,useEffect } from "react";
import { Button } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import Select from "react-select";
import { RangeDatePicker, DatePicker } from "@y0c/react-datepicker";
import { db } from '../../../Firebase';
import { collection, addDoc,query,onSnapshot } from 'firebase/firestore';
import {useHistory } from "react-router-dom";

const pub = [
   { value: "pubcasa", label: "Pub Casa" },
   { value: "pubrabat", label: "Pub Rabat" },
   { value: "pubfes", label: "Pub Fes" },
];

const agences = [
   { value: "AgenceCasa", label: "Agence Casa" },
   { value: "AgenceRabat", label: "Agence Rabat" },
   { value: "AgenceFes", label: "Agence Fes" },
];

const ProgramForm = () => {
   const [selectedPub, setSelectedPub] = useState(null);
   const [selectedAgence, setSelectedAgence] = useState(null);
   const [programFrom, setProgramFrom] = useState(null);
   const [programTo, setProgramTo] = useState(null);
   const [title,setTitle]=useState("")
   const history = useHistory()
   const [advertisements,setAdvertisements] = useState([]);
   const [agences,setAgences] = useState([]);

   useEffect(() => {
      const q = query(collection(db, "advertisment"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let todosArray = [];
        querySnapshot.forEach((doc) => {
          todosArray.push({ ...doc.data(), id: doc.id });
        });
        setAdvertisements(todosArray.map(item=> {return {value:item.id,label:item.title}}));
      });
      return () => unsub();
    }, []);

    useEffect(() => {
      const q = query(collection(db, "Agences_v1"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let todosArray = [];
        querySnapshot.forEach((doc) => {
          todosArray.push({ ...doc.data(), id: doc.id });
        });
        setAgences(todosArray.map(item=> {return {value:item.id,label:item.name}}));
      });
      return () => unsub();
    }, []);
   const handleSubmit = async (e) => {
      e.preventDefault();
      //(selectedAgence)
     
          await addDoc(collection(db, "program"), {
              selectedPub,
              //selectedAgence,
              programFrom,
              programTo,
              title
          });
          setSelectedPub(null);
          //setSelectedAgence(null)
          setProgramFrom("")
          setProgramTo("")
          history.push('/program-table');
  }

   return (
      
      <Fragment>
         <PageTitle activeMenu="Program" motherMenu="Form" />

         <div className="row">
            <div className="col-lg-12">
               <div className="card">
                  <div className="card-header">
                     <h4 className="card-title">Nouveau programme</h4>
                  </div>
                  <div className="card-body">
                     <div className="form-validation">
                        <form
                           className="form-valide"
                           action="#"
                           method="post"
                        >
                           <div className="row">
                              <div className="col-xl-12">
                              <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Titre 
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                       <input
                                          value={title}
                                          type="text"
                                          className="form-control"
                                          id="val-username"
                                          name="val-username"
                                          placeholder="Entrez un titre.."
                                          onChange={(e) => setTitle(e.target.value)}
                                          
                                       />
                                    </div>
                                 </div>
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Liste des publicités
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                    <Select
                                       defaultValue={selectedPub}
                                       onChange={setSelectedPub}
                                       options={advertisements}
                                       style={{
                                          lineHeight: "40px",
                                          color: "#7e7e7e",
                                          paddingLeft: " 15px",
                                       }}
                                    />
                                    </div>
                                 </div>
                                 
                                 {/* <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Agence List
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                       <Select
                                          defaultValue={selectedAgence}
                                          onChange={setSelectedAgence}
                                          isMulti
                                          options={agences}
                                          style={{
                                             lineHeight: "40px",
                                             color: "#7e7e7e",
                                             paddingLeft: " 15px",
                                          }}
                                       />
                                    </div>
                                 </div>
                                  */}
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Programme
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-3">
                                       <input
                                          value={programFrom}
                                          type="number"
                                          className="form-control"
                                          id="val-username"
                                          name="val-username"
                                          placeholder="..."
                                          onChange={(e) => setProgramFrom(e.target.value)}
                                       />
                                    </div>
                                    <div className="col-lg-3">
                                       <input
                                          value={programTo}
                                          type="number"
                                          className="form-control"
                                          id="val-username"
                                          name="val-username"
                                          placeholder="..."
                                          onChange={(e) => setProgramTo(e.target.value)}
                                       />
                                    </div>
                                 </div>
                               
                                 <div className="form-group row">
                                    <div className="col-lg-4 "></div>
                                    <div className="col-lg-6">
                                       <Button variant="primary" onClick={handleSubmit}>
                                       Sauvegarder{" "}
                                       </Button>
                                    </div>
                                 </div>
                              </div>
                              
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
           
         </div>
      </Fragment>
   );
};

export default ProgramForm;
