import React, { Fragment, useState, useEffect } from "react";
import Clock from "react-live-clock";
import { Row, Col, Card, Container } from "react-bootstrap";
import um6plogo from "../../../images/logo_BCP.png";
import icorec from "../../../images/nowaiting.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import logoBcp from '../../../images/logo_BCP.png'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import "./assets/style.css";

import iconstore from "./assets/store.png";
import ChartComp from "./Components/chartComp";

import { useParams } from "react-router-dom";
import WeatherCard from "./Components/WeatherCard";

import { realTimeDb } from "../../../Firebase";
import { onValue, ref } from "firebase/database";
import { Occupation } from "./Components/Occupation";
import { WaitingTime } from "./Components/WaitingTime";
import { ExtraCard } from "./Components/ExtraCard";
import { Qr } from "./Components/Qr";
import { useNoWaitingStore } from "./noWatingStore/store";
import { CodeCards } from "./Components/CodeCards";
import { SoundPlayer } from "./Components/Sound";
const DATE_OPTIONS = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
const ALLSPACES = [
  "Fitness Space",
  "Cardio Space",
  "Swimming pool",
  "EMINES Cafeteria",
  "CCI Cafeteria",
  "Campus Restaurant",
  "Modular Restaurant",
  "CCI Restaurant",
];

function toggleFullscreen() {
  if (
    !document.fullscreenElement &&
    !document.mozFullScreenElement &&
    !document.webkitFullscreenElement
  ) {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(
        Element.ALLOW_KEYBOARD_INPUT
      );
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }
}

const TvScreen = () => {
  const addBodyClass = (className) => document.body.classList.add(className);

  const [arrayOfPlaces, setarrayOfPlaces] = useState([]);
  var [sportSales, setSportSales] = useState([]);
  const noWaitingStore = useNoWaitingStore();
  let { id } = useParams();

  // console.log("hi index")
  useEffect(() => {
    const query = ref(realTimeDb, "Places");
    onValue(
      query,
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          // console.log(data)
          // setSportSales(data.sports.map((item) => item.Name));
          const allplaces = Object.values(data);
          console.log(allplaces);
          for (let i = 0; i < allplaces.length; ++i) {
            if (allplaces[i].find((item) => item.Name == id)) {
              setarrayOfPlaces(allplaces[i]);
            }
          }
        }
      },
      { onlyOnce: true }
    );
    addBodyClass("no-scrollbar");
  }, []);
  // console.log(arrayOfPlaces)
  const [weatherResult, setWeatherResult] = useState();
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `http://api.weatherapi.com/v1/current.json?key=9d53191434354008baa180142230210&q=Benguerir&aqi=no`
      );
      const data = await response.json();
      setWeatherResult(data);
    }
    fetchData();
  }, []);

  console.log(arrayOfPlaces);
  return (
    <Fragment>
      {!sportSales.includes(id) ? (
        <Container
          fluid
          className="py-5 px-5 bg-header-tv no-scrollbar"
          style={{ overflow: "hidden" }}
        >
          <Row>
            <Col lg={12}>
              <div className="justify-content-between row align-items-center">
                <div className="mb-3 col-lg-3">
                  <div className="mb-4">
                    <img
                      className="logo-abbr"
                      src={um6plogo}
                      alt=""
                      width={"250"}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        toggleFullscreen();
                      }}
                      className="btn header-item noti-icon waves-effect"
                      data-toggle="fullscreen"
                    >
                      <i className="mdi mdi-fullscreen"></i>
                    </button>
                  </div>
                </div>
                <div className="mb-3 col-lg-5">
                  <div className="mb-4 border-right border-left text-center">
                    <h4
                      className="card-title card-intro-title text-white mb-1 text-uppercase"
                      style={{ fontSize: 28, fontWeight: 100 }}
                    >
                      AFFLUENCE DE {id}
                    </h4>
                    <h2
                      className="text-white"
                      style={{ fontSize: 38, letterSpacing: 2 }}
                    >
                      {" "}
                      ET CELLES QUI L'ENTOURENT
                    </h2>
                  </div>
                </div>
                <div className="mb-3 col-lg-4 text-right">
                  <div className="mb-4">
                    <h1 className="mb-1 text-white">
                      <Clock
                        format={"HH:mm:ss"}
                        ticking={true}
                        timezone={"Africa/Casablanca"}
                      />
                    </h1>
                    <h5 className="text-white text-capitalize">
                      {new Date().toLocaleDateString("fr-fr", DATE_OPTIONS)}
                    </h5>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={8} lg={12}>
              <Row>
                <Occupation {...{ x: 6, y: 6, id: id }} />
                <WaitingTime {...{ id }} Time={true} />
              </Row>
              <Row>
                <Col lg={12} md={12}>
                  <Card className="widget-stat">
                    <Card.Body>
                      <ChartComp {...{ id }} />
                      {/* <Qr /> */}
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={12} md={12}>
                  <Card className="widget-stat box-news">
                    <Card.Body>
                      <Row className="align-items-center">
                        <Col lg={3} className="pt-1">
                          <div className="">
                            <h4 className="text-white">
                              {" "}
                              <img
                                className="logo-abbr mx-2"
                                src={logoBcp}
                                alt=""
                                width={"100"}
                              />{" "}
                              News
                            </h4>
                          </div>
                        </Col>
                        <Col lg={9}>
                          <div className="">
                            <marquee>
                              {/* {news && news.map((item) => */}
                              <>
                                <img
                                  className="logo-abbr mx-2"
                                  src={
                                    logoBcp
                                  }
                                  alt=""
                                  width={"80"}
                                />

                                <img
                                  className="logo-abbr mx-2"
                                  src={
                                    logoBcp
                                  }
                                  alt=""
                                  width={"80"}
                                />
                              </>
                              {/* )} */}
                            </marquee>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col xl={4} lg={4}>
              <Row>
                {weatherResult ? (
                  <WeatherCard weatherResult={weatherResult} {...{ id }} />
                ) : null}
                {arrayOfPlaces.map((s, i) => {
                  if (s.Name !== id)
                    return (
                      <Fragment key={i}>
                        <ExtraCard {...{ id: s.Name }} />
                      </Fragment>
                    );
                })}
              </Row>

              {/* <Row className="align-items-center px-2">
                <Col lg={2} md={2}>
                  <img className="logo-abbr" src={icorec} alt="" width={"60"} />
                </Col>
                <Col lg={6} md={6}>
                  <p className="mb-0" style={{ lineHeight: 1.2 }}>
                    Pour plus d’information télécharger <br />{" "}
                    <span
                      style={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: "#9B2522",
                      }}
                    >
                      No Waiting Um6p
                    </span>{" "}
                  </p>
                </Col>
                <Col lg={3} md={3}>
                  <Qr code={id} />
                </Col>
              </Row> */}
              {/* <Col lg={3} md={3}>
                <img
                  className="logo-abbr"
                  src={iconstore}
                  alt=""
                  width={"122"}
                />
              </Col> */}
            </Col>
          </Row>
          <Row></Row>
        </Container>
      ) : (
        <Container fluid className="py-5 px-5 bg-header-tv">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay]}
          >
            {sportSales.map((id, index) => {
              return (
                <SwiperSlide key={index}>
                  <Row>
                    <SoundPlayer />
                    <Col lg={12}>
                      <div className="justify-content-between row align-items-center">
                        <div className="mb-3 col-lg-3">
                          <div className="mb-4">
                            <img
                              className="logo-abbr"
                              src={um6plogo}
                              alt=""
                              width={"250"}
                            />
                            <button
                              type="button"
                              onClick={() => {
                                toggleFullscreen();
                              }}
                              className="btn header-item noti-icon waves-effect"
                              data-toggle="fullscreen"
                            >
                              <i className="mdi mdi-fullscreen"></i>
                            </button>
                          </div>
                        </div>
                        <div className="mb-3 col-lg-5">
                          <div className="mb-4 border-right border-left text-center">
                            <h4
                              className="card-title card-intro-title text-white mb-1 text-uppercase"
                              style={{ fontSize: 28, fontWeight: 100 }}
                            >
                              AFFLUENCE DE {id}
                            </h4>
                            <h2
                              className="text-white"
                              style={{ fontSize: 38, letterSpacing: 2 }}
                            >
                              {" "}
                              ET CELLES QUI L'ENTOURENT
                            </h2>
                          </div>
                        </div>
                        <div className="mb-3 col-lg-4 text-right">
                          <div className="mb-4">
                            <h1 className="mb-1 text-white">
                              <Clock
                                format={"HH:mm:ss"}
                                ticking={true}
                                timezone={"Africa/Casablanca"}
                              />
                            </h1>
                            <h5 className="text-white text-capitalize">
                              {new Date().toLocaleDateString(
                                "fr-fr",
                                DATE_OPTIONS
                              )}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={8} lg={12}>
                      <Row>
                        <Occupation {...{ x: 6, y: 6, id: id }} />
                        <WaitingTime {...{ id }} />
                      </Row>
                      <Row>
                        <Col lg={12} md={12}>
                          <Card className="widget-stat">
                            <Card.Body>
                              <ChartComp {...{ id }} />
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col lg={12} md={12}>
                          <Card className="widget-stat box-news">
                            <Card.Body>
                              <Row className="align-items-center">
                                <Col lg={3} className="pt-1">
                                  <div className="">
                                    <h4 className="text-white">
                                      {" "}
                                      <img
                                        className="logo-abbr mx-2"
                                        src={logoBcp}
                                        alt=""
                                        width={"100"}
                                      />{" "}
                                      News
                                    </h4>
                                  </div>
                                </Col>
                                <Col lg={9}>
                                  <div className="">
                                    <marquee>
                                      {/* {news && news.map((item) => */}
                                      <>
                                        <img
                                          className="logo-abbr mx-2"
                                          src={
                                            logoBcp
                                          }
                                          alt=""
                                          width={"80"}
                                        />

                                        <img
                                          className="logo-abbr mx-2"
                                          src={
                                            logoBcp
                                          }
                                          alt=""
                                          width={"80"}
                                        />
                                      </>
                                      {/* )} */}
                                    </marquee>
                                  </div>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Col>

                    <Col xl={4} lg={4}>
                      <Row>
                        {arrayOfPlaces.map((s, i) => {
                          return s.Name === id ? (
                            <Fragment key={i}>
                              {weatherResult ? (
                                <WeatherCard
                                  weatherResult={weatherResult}
                                  id={s?.Name}
                                  image={s.image_url}
                                />
                              ) : null}
                            </Fragment>
                          ) : null;
                        })}
                        {arrayOfPlaces.map((s, i) => {
                          return s.Name !== id ? (
                            <Fragment key={i}>
                              <ExtraCard {...{ id: s.Name }} />
                            </Fragment>
                          ) : null;
                        })}
                      </Row>

                      <Row className="align-items-center px-2">
                        <Col lg={2} md={2}>
                          <img
                            className="logo-abbr"
                            src={icorec}
                            alt=""
                            width={"60"}
                          />
                        </Col>
                        <Col lg={6} md={6}>
                          <p className="mb-0" style={{ lineHeight: 1.2 }}>
                            Pour plus d’information télécharger <br />{" "}
                            <span
                              style={{
                                fontSize: 18,
                                fontWeight: 600,
                                color: "#9B2522",
                              }}
                            >
                              No Waiting Um6p
                            </span>{" "}
                          </p>
                        </Col>
                        <Col lg={3} md={3}>
                          <Qr code={id} />
                        </Col>
                      </Row>
                      <Col lg={3} md={3}>
                        <img
                          className="logo-abbr"
                          src={iconstore}
                          alt=""
                          width={"122"}
                        />
                      </Col>
                    </Col>
                  </Row>
                  <Row>
                    {/* {noWaitingStore.name.map((item , index) => {
            return (
                <CodeCards item={item} index={index} /> 
            )
           })}  */}
                  </Row>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Container>
      )}
    </Fragment>
  );
};

export default TvScreen;
