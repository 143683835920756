import logo from "../../../images/logo_BCP.png";
import React, { useEffect, useState, useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import $ from "jquery";
import "bootstrap-daterangepicker";
import { format } from "date-fns";
import { DateRangePicker } from "react-bootstrap-daterangepicker";
import { usePlacesStore } from "../../../zustand-store/store";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/actions/AuthActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Container,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  SplitButton,
  Button,
} from "react-bootstrap";
import profile from "../../../images/17.jpg";
import { useChartStore } from "../../../zustand-store/chartStore";
import { MealsFilter } from "./MealsFilter";
var MEALS_FILTER = ["breakfast", "lunch", "dinner"];
const RESTURANTS = [
  "Campus Restaurant",
  "Modular Restaurant",
  "CCI Restaurant",
];
function convertToFrenchDate(dateStr) {
  const dateParts = dateStr.split("-");
  // const year = dateParts[2];
  // const month = dateParts[1];
  // const day = dateParts[0];

  // const date = new Date(year, month - 1, day);

  // const frenchDays = ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'];

  // const dayOfWeek = date.getDay();

  // const frenchRepresentation = `${frenchDays[dayOfWeek]} ${month}, ${year}`;

  return dateParts.join("-");
}

export const Nav = ({ children }) => {
  const places = usePlacesStore();
  const chartStore = useChartStore();
  const [firstRender, setFirstRender] = useState(true);
  const [agences, setAgences] = useState(places.places_names);

  const dateRangePickerRef = useRef(null);
  const [formatedDate, setFormatedDate] = useState("");
  const dispatch = useDispatch();
  const filters = ["Agences"];
  useEffect(() => {
    places.setPlaces();

    if (places && places.places_names.length > 0) {
      // chartStore.setmealsCheckedValues([MEALS_FILTER[0]]);
      chartStore.setPlacesCheckedValues([
        places.places_names.length > 1
          ? places.places_names[1]
          : places.places_names[0],
      ]);
      places.setMaxOccupation(
        chartStore.startDate,
        chartStore.endDate,
        chartStore.placesCheckedValues,
        chartStore.mealsCheckedValues
      );
      places.setMaxWaitingTime(
        chartStore.startDate,
        chartStore.endDate,
        chartStore.placesCheckedValues,
        chartStore.mealsCheckedValues
      );
      // places.setScoring(
      //   chartStore.startDate,
      //   chartStore.endDate,
      //   chartStore.placesCheckedValues,
      // chartStore.mealsCheckedValues
      // );
      places.setStations(
        chartStore.startDate,
        chartStore.endDate,
        chartStore.placesCheckedValues,
        chartStore.mealsCheckedValues
      );
      places.setAttractivness(
        chartStore.startDate,
        chartStore.endDate,
        chartStore.placesCheckedValues,
        chartStore.mealsCheckedValues
      );
      places.setEffectiveOperations(
        chartStore.startDate,
        chartStore.endDate,
        chartStore.placesCheckedValues,
        chartStore.mealsCheckedValues
      );
      places.setMaxQueue(
        chartStore.startDate,
        chartStore.endDate,
        chartStore.placesCheckedValues,
        chartStore.mealsCheckedValues
      );
      places.setConversion(
        chartStore.startDate,
        chartStore.endDate,
        chartStore.placesCheckedValues,
        chartStore.mealsCheckedValues
      );
      setFirstRender(false);
    }
  }, [places.places_names.length > 0]);

  useEffect(() => {
    setAgences(places.places_names);
  }, [places.places_names]);
  useEffect(() => {
    const tokenDetailsString = localStorage.getItem("userDetails");
    let tokenDetails = "";
    if (!tokenDetailsString) {
      dispatch(logout());
      return;
    }
  });

  const history = useHistory();

  useEffect(() => {
    const datePicker = $(dateRangePickerRef.current);

    datePicker.daterangepicker(
      {
        opens: "left",
        autoUpdateInput: false,
        locale: {
          format: "DD/MM/YYYY",
        },
      },
      (start, end, label) => {
        const formattedStartDate = start.format("DD-MM-YYYY");
        const formattedEndDate = end.format("DD-MM-YYYY");
        datePicker.val(`${formattedStartDate} - ${formattedEndDate}`);
      }
    );

    const formattedStartDate = format(chartStore.startDate, "yyyy-MM-dd");
    const formattedEndDate = format(chartStore.endDate, "yyyy-MM-dd");
    chartStore.setStartDate(formattedStartDate);
    chartStore.setEndDate(formattedEndDate);
    datePicker.val(`${formattedStartDate} - ${formattedEndDate}`);
    setFormatedDate(
      `${chartStore.startDate.toString().split`-`.slice(0, 4).reverse()
        .join`-`} - ${chartStore.endDate.toString().split`-`
        .slice(0, 4)
        .reverse().join`-`}`
    );
  }, []);

  const handleApply = (event, picker) => {
    const { startDate, endDate } = picker;
    const formattedStartDate = format(startDate.toDate(), "yyyy-MM-dd");
    const formattedEndDate = format(endDate.toDate(), "yyyy-MM-dd");
    chartStore.setStartDate(formattedStartDate);
    chartStore.setEndDate(formattedEndDate);

    const datePicker = $(dateRangePickerRef.current);
    datePicker.val(`${formattedStartDate} - ${formattedEndDate}`);
  };

  useEffect(() => {
    places.setMaxOccupation(
      chartStore.startDate,
      chartStore.endDate,
      chartStore.placesCheckedValues,
      chartStore.mealsCheckedValues
    );
    places.setMaxWaitingTime(
      chartStore.startDate,
      chartStore.endDate,
      chartStore.placesCheckedValues,
      chartStore.mealsCheckedValues
    );
    // places.setScoring(
    //   chartStore.startDate,
    //   chartStore.endDate,
    //   chartStore.placesCheckedValues,
    // chartStore.mealsCheckedValues
    // );
    places.setStations(
      chartStore.startDate,
      chartStore.endDate,
      chartStore.placesCheckedValues,
      chartStore.mealsCheckedValues
    );
    places.setAttractivness(
      chartStore.startDate,
      chartStore.endDate,
      chartStore.placesCheckedValues,
      chartStore.mealsCheckedValues
    );
    places.setEffectiveOperations(
      chartStore.startDate,
      chartStore.endDate,
      chartStore.placesCheckedValues,
      chartStore.mealsCheckedValues
    );
    places.setMaxQueue(
      chartStore.startDate,
      chartStore.endDate,
      chartStore.placesCheckedValues,
      chartStore.mealsCheckedValues
    );
    places.setConversion(
      chartStore.startDate,
      chartStore.endDate,
      chartStore.placesCheckedValues,
      chartStore.mealsCheckedValues
    );
    let capacity = 0;
    if (
      places &&
      places.places &&
      places.places.length > 0 &&
      chartStore.placesCheckedValues.length > 0
    ) {
      for (let i = 0; i < places.places.length; ++i) {
        for (let j = 0; j < places.places[i].length; ++j) {
          if (
            chartStore.placesCheckedValues.includes(places.places[i][j].Name) &&
            places.places[i][j].Capacity
          )
            capacity += places.places[i][j].Capacity;
        }
      }
    }
    places.setCapacity(capacity);
    setFormatedDate(
      `${convertToFrenchDate(
        chartStore.startDate.toString().split`-`.slice(0, 4).reverse().join`-`
      )} - ${convertToFrenchDate(
        chartStore.endDate.toString().split`-`.slice(0, 4).reverse().join`-`
      )}`
    );
  }, [
    chartStore.placesCheckedValues,
    chartStore.startDate,
    chartStore.endDate,
    chartStore.mealsCheckedValues.length,
  ]);

  const handleAgencesCheckboxChange = (checked, value) => {
    if (checked) {
      if (!RESTURANTS.includes(value)) {
        chartStore.setMealsCheckedValues([]);
      } else if (
        RESTURANTS.includes(value) &&
        chartStore.mealsCheckedValues.length === 0
      ) {
        chartStore.setMealsCheckedValues([MEALS_FILTER[0]]);
      }
      var cateArray = places.categoryPlaces.filter((cate) => {
        if (cate.includes(value)) {
          return cate;
        }
      });
      cateArray = [].concat.apply([], cateArray);
      console.log(cateArray);
      console.log(chartStore.placesCheckedValues);
      chartStore.setPlacesCheckedValues([
        ...chartStore.placesCheckedValues.filter((item) =>
          cateArray.includes(item) ? item : null
        ),
        value,
      ]);
    } else {
      chartStore.setPlacesCheckedValues(
        chartStore.placesCheckedValues.filter((item) => item !== value)
      );
    }
  };
  return (
    <>
      <nav
        className="navbar navbar-expand-md navbar-light sticky-top"
        style={{
          backgroundColor: "#2d2e4e",
          borderBottom: "1px solid #55567B",
        }}
      >
        <Link to="/overview" className="brand-logo">
          <img className="logo-abbr" src={logo} alt="" width={150} />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto pl-5 ml-5">
            <li className="nav-item">
              <div className="nav-btton">
                {/* <Link to="/overview" className="active">
                  <i className="la la-home mr-2"></i> Overview
                </Link> */}
              </div>
            </li>
          </ul>
          <ul className="navbar-nav">
            <li className="mr-2 d-flex align-items-center">
              <DateRangePicker
                onApply={handleApply}
                initialSettings={{
                  startDate: chartStore.startDate,
                  endDate: chartStore.endDate,
                }}
                displayFormat="DD-MM-YYYY"
                inputFormat="DD-MM-YYYY"
              >
                <input
                  type="text"
                  value={`${formatedDate.toString()}`}
                  className="form-control form-control-dashboard"
                />
              </DateRangePicker>
            </li>
            <li className="px-4">
              <Dropdown as="li" className="nav-item  notification_dropdown">
                <Dropdown.Toggle
                  variant="outline-primary"
                  size="sm"
                  className="mt-1 btn-right-outline px-4"
                >
                  <i className="flaticon-381-funnel" /> Filters
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" className="mt-2">
                  <PerfectScrollbar className="widget-media dz-scroll p-3 height450 ps">
                    <ul className="timeline">
                      {agences &&
                        agences.map((agency, index) => (
                          <>
                            {index === 0 ? <h4>Agences</h4> : null}
                            {index === 2 ? <h4>Restaurants</h4> : null}
                            {index === 2 ? <MealsFilter /> : null}
                            {index === 5 ? <h4>Sport places</h4> : null}
                            <span>
                              <div
                                className="form-check mb-2"
                                key={index + "agence"}
                              >
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id={index + "agence"}
                                  value={agency}
                                  onChange={(e) =>
                                    handleAgencesCheckboxChange(
                                      e.target.checked,
                                      agency
                                    )
                                  }
                                  checked={
                                    chartStore.placesCheckedValues.includes(
                                      agency
                                    )
                                      ? true
                                      : false
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={index + "agence"}
                                >
                                  {" "}
                                  {agency}{" "}
                                </label>
                              </div>
                            </span>
                          </>
                        ))}
                    </ul>

                    <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
                      <div
                        className="ps__thumb-x"
                        tabIndex={0}
                        style={{ left: 0, width: 0 }}
                      />
                    </div>
                    <div className="ps__rail-y" style={{ top: 0, right: 0 }}>
                      <div
                        className="ps__thumb-y"
                        tabIndex={0}
                        style={{ top: 0, height: 0 }}
                      />
                    </div>
                  </PerfectScrollbar>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="px-2"></li>
            <li>
              <Dropdown className="nav-item header-profile ">
                <Dropdown.Toggle
                  as="a"
                  to="#"
                  variant=""
                  className="nav-link i-false c-pointer p-1"
                >
                  <img
                    src={profile}
                    width="40"
                    className="rounded-circle"
                    alt=""
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" className="mt-2">
                  <Link to="/" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ml-2">Profile </span>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </nav>
      {children}
    </>
  );
};
