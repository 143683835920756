import Sound from 'react-sound';
import ArabSound from '../assets/No_Waiti.m4a'
import EnglishSoung  from '../assets/No_Waiting_service_.m4a'
import { useSound } from '../noWatingStore/sound';
import { useEffect, useState } from 'react';
export const SoundPlayer = () => {
    const soundIndex = [ArabSound , EnglishSoung]
    const soundStore = useSound();
    const [playing , setPlaying] = useState(false);
    const [index , setIndex] = useState(0);
    useEffect(() => {
        console.log(`minutes ${soundStore.minutes} `)
       
            soundStore.minutes === 5 ? setPlaying(true) : setPlaying(false)
            console.log("started")
        
    },[soundStore.minutes] )
   
    const trackHandler = () => {
        if (index === 0)
            setIndex(1)
        else
        {
            setPlaying(false)
            setIndex(0)
        }
    }
    return (
        <div style={{display: 'none'}}>
			<Sound url={soundIndex[index]}
				playStatus={playing ? Sound.status.PLAYING : Sound.status.STOPPED}
				playFromPosition={10}
                loop={false}
				onFinishedPlaying={trackHandler}
			/>	
		</div>
    )
}