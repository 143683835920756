import React, { useState,useEffect } from "react";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import {db} from '../../Firebase';
import { realTimeDb } from "../../Firebase";
import { onValue, ref } from "firebase/database";
import {useHistory } from "react-router-dom";

/// Images
import img1 from "../../images/slide1.png";
import img2 from "../../images/slide2.png";
import {
   collection,
   query,
   onSnapshot,
 } from "firebase/firestore";

/// Bootstrap
import { Row, Col, Card, Carousel, Container } from "react-bootstrap";
import { set } from "date-fns";

/// carousel data
const carousel1 = [img1, img2];


const AdvertisingTvScreen = () => {
   const history = useHistory()
   let { programId } = useParams();
   let { advertismentId } = useParams();
   let { agenceId } = useParams();
   let {uniqueId} =useParams()
   const [program,setProgram] = useState();
   const [advertisment,setAdvertisment] = useState();
   const [media,setMedia] = useState([]);
   const [agence,setAgence]= useState(null)
   const [adsScreen,setAdsScreen]= useState(null)
   useEffect(() => {
      const q = query(collection(db, "adsScreen"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let todosArray = [];
        querySnapshot.forEach((doc) => {
          todosArray.push({ ...doc.data(), id: doc.id });
        });
        const adscreen = todosArray.find(item => item.uniqueId === uniqueId)
        setAdsScreen(adscreen)
        if(adscreen && adscreen.active===false){
         history.push('/page-error-404');
        }
      });
      return () => unsub();
    }, []);
   useEffect(() => {
      const query = ref(realTimeDb, "Agences");
      return onValue(query, (snapshot) => {
        const data = snapshot.val();
        if (snapshot.exists()) {
          // Object.values(data).map((agence) => {
          //   setAgences((agences) => [...agences, agence]);
          // });
          setAgence(data.find(item => item.name ===agenceId))
        }
      });
      
    }, []);

   useEffect(() => {
      //("program",program)
      const q = query(collection(db, "advertisment"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let todosArray = [];
        querySnapshot.forEach((doc) => {
          todosArray.push({ ...doc.data(), id: doc.id });
        });
        const selectedProgram = program && program.find(item => agence?.occupation >= item.programFrom  && agence?.occupation < item.programTo);
        const adv = todosArray.find(item => item.id ===selectedProgram?.selectedPub?.value)
        //(adv)
        setAdvertisment(adv);
        setMedia(adv?.files)
        //(adv)
      });
      return () => unsub();
    }, [agence?.occupation,program]);
    useEffect(() => {
      //("agence?.occupation",agence)
      const q = query(collection(db, "program"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let todosArray = [];
        querySnapshot.forEach((doc) => {
          todosArray.push({ ...doc.data(), id: doc.id });
        });
        const filter = todosArray.filter((pr)=>adsScreen?.selectedProgram.map((item)=>item.value).includes(pr.id))
       // setProgram(todosArray.find(item => agence?.occupation >= item.programFrom  && agence?.occupation < item.programTo));
       setProgram(filter);
       //("screen",adsScreen)
      });
      return () => unsub();
    }, [adsScreen]);
   return (
      <Fragment>
        <Container fluid className="py-2 px-2">
            <Row>
               <Col lg={12}>
               <Carousel interval={advertisment && advertisment.time ? advertisment.time*1000 : 1000}>
                  {media && media.map((carousel, i) => (
                     <Carousel.Item key={i}>
                        <img
                           src={carousel}
                           className="d-block w-100"
                           alt={`Slide ${i + 1}`}
                        />
                     </Carousel.Item>
                  ))}
               </Carousel>
               </Col>
            </Row>
         </Container>
      </Fragment>
   );
};

export default AdvertisingTvScreen;
