import React, { Fragment,useEffect,useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
   Row,
   Col,
   Card,
   Table,
   Button
} from "react-bootstrap";
import {
   collection,
   query,
   onSnapshot,
   doc,
   updateDoc,
   deleteDoc,
 } from "firebase/firestore";
 import {db} from '../../../Firebase';
/// imge
import a1 from "../../../images/a1.jpg";
import { Link } from "react-router-dom";

const ScreenAdsTable = () => {
   const [screesnAds,setScreesnAds] = useState([]);
   useEffect(() => {
      const q = query(collection(db, "adsScreen"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let todosArray = [];
        querySnapshot.forEach((doc) => {
          todosArray.push({ ...doc.data(), id: doc.id });
        });
        setScreesnAds(todosArray);
      });
      return () => unsub();
    }, []);

    const handleDelete = async (id) => {
      await deleteDoc(doc(db, "adsScreen", id)); 
   }
   return (
      <Fragment>
         <Row className="justify-content-between align-items-center">
         <PageTitle activeMenu="Écran des publicités" motherMenu="Liste Publicités" />
         <Link to="/screen-ads-form" className="ai-icon">
            <Button variant="primary">
               Ajouter des publicités{" "}
               <span className="btn-icon-right">
                  <i className="fa fa-plus-circle" />
               </span>
            </Button>
         </Link>
         </Row>
         <Row>
            <Col lg={12}>
               <Card>
                  <Card.Header>
                     <Card.Title>Liste des publicités</Card.Title>
                  </Card.Header>
                  <Card.Body>
                     <Table responsive>
                        <thead>
                           <tr>
                              <th className="width50">
                                 <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                    <input
                                       type="checkbox"
                                       className="custom-control-input"
                                       id="checkAll"
                                       required=""
                                    />
                                    <label
                                       className="custom-control-label"
                                       htmlFor="checkAll"
                                    ></label>
                                 </div>
                              </th>
                              <th>
                                 <strong>NO.</strong>
                              </th>
                              <th>
                                 <strong>Titre</strong>
                              </th>
                              <th>
                                 <strong>URL</strong>
                              </th>
                              <th>
                                 <strong>Agence</strong>
                              </th>
                              <th>
                                 <strong>Programme</strong>
                              </th>
                              <th>
                                 <strong>Statut</strong>
                              </th>
                           </tr>
                        </thead>
                        <tbody>
                        { screesnAds && screesnAds.map((screen)=>
                           <tr>
                              <td>
                                 <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                    <input
                                       type="checkbox"
                                       className="custom-control-input"
                                       id="customCheckBox2"
                                       required=""
                                    />
                                    <label
                                       className="custom-control-label"
                                       htmlFor="customCheckBox2"
                                    ></label>
                                 </div>
                              </td>
                              <td>
                                 <strong>{screen.id}</strong>
                              </td>
                              <td>
                                 <div className="d-flex align-items-center">
                                   
                                    <span className="w-space-no">
                                      {screen.title} 
                                    </span>
                                 </div>
                              </td>
                              <td><a href={screen.url} target="_blank">{screen.url}</a></td>
                              <td>{screen.selectedAgence.label}</td>
                              <td>{screen.selectedProgram.label}</td>
                              <td>
                                 <div className="d-flex align-items-center">
                                    <i className="fa fa-circle text-success mr-1"></i>{" "}
                                    {screen.active ? 'activer' : 'désactiver'}
                                 </div>
                              </td>
                              <td>
                              <div className="d-flex">
                                  <a
                                     href={"screen-ads-form/"+screen.id}
                                     className="btn btn-danger shadow btn-xs sharp"
                                     target="_blank"
                                  >
                                     <i className="fa fa-pencil"></i>
                                  </a>
                                   
                               </div>
                                 <div className="d-flex">
                                 <Button
                                     onClick={() => handleDelete(screen.id)}
                                      href="#"
                                      className="btn btn-danger shadow btn-xs sharp"
                                   >
                                      <i className="fa fa-trash"></i>
                                   </Button>
                                 </div>
                              </td>
                           </tr>
                        )}
                        </tbody>
                     </Table>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
      </Fragment>
   );
};

export default ScreenAdsTable;
