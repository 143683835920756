import { useState } from "react";
import { usePlacesStore } from "../../../../zustand-store/store";
import { Link } from "react-router-dom";
export const Operations = () => {
  const [filter, setFilter] = useState("CAISSE");
  const placeStore = usePlacesStore();

  return (
    <div className="col-sm-6 pr-2 py-4">
      <div className="card">
        <div
          className="card-header"
          style={{
            display: "flex",
            alignItems: "start",
          }}
        >
          <h5 className="card-title" style={{ textTransform: "none" }}>
            Nombre et durée réels des opérations par{" "}
            {filter != "CAISSE" && "bureau du "} {filter.toLowerCase()}
          </h5>
          <div
            style={{
              width: "30px",
              overflow: "hidden",
            }}
          >
            <select
              name="filter"
              style={{
                width: "20px",
                border: "none",
                outline: "none",
                "-webkit-appearance": "menulist-button",
                cursor: "pointer",
              }}
              // style={{ width: 200, marginLeft: 20, marginTop: 20 }}
              onChange={(e) => setFilter(e.target.value)}
            >
              {placeStore?.effectiveOperations.length &&
                placeStore?.effectiveOperations?.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.type}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <Link to={"overview/Operations"}>
          <div className="card-body widget-stat">
            <div className="media ai-icon border-bottom py-4">
              <span className="mr-3 bgl-green text-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  viewBox="0 0 58.722 60.367"
                >
                  <g id="ZvEJsk.tif" transform="translate(-574.796 -633.378)">
                    <g
                      id="Group_8220"
                      data-name="Group 8220"
                      transform="translate(574.796 633.378)"
                    >
                      <path
                        id="Path_4947"
                        data-name="Path 4947"
                        d="M574.8,661.08c.433-.259.876-.5,1.3-.781,1.816-1.2,3.627-2.4,5.513-3.656l8.712,13.116c-.228.154-.417.286-.61.415q-2.84,1.89-5.682,3.779a4.115,4.115,0,0,0-.387.332H583.4a2.9,2.9,0,0,0-.191-.411q-4.052-6.115-8.113-12.227a2.865,2.865,0,0,0-.3-.331Z"
                        transform="translate(-574.796 -613.919)"
                        fill="#00c6a1"
                      />
                      <path
                        id="Path_4948"
                        data-name="Path 4948"
                        d="M595.261,637.926a7.616,7.616,0,0,1,.716.308,17.971,17.971,0,0,1,1.715,1.026.971.971,0,0,0,1.221.057,1.027,1.027,0,0,0,.41-.49c.22-.731.389-1.476.584-2.215a1.026,1.026,0,0,1,1.168-.918c1.355,0,2.71.007,4.066-.005a1.026,1.026,0,0,1,1.135.88c.174.724.375,1.44.56,2.16a1.016,1.016,0,0,0,1.528.621c.626-.367,1.247-.742,1.877-1.1a1.039,1.039,0,0,1,1.478.189q1.427,1.408,2.834,2.833a1.011,1.011,0,0,1,.185,1.475c-.382.663-.786,1.313-1.17,1.976a1.025,1.025,0,0,0,.568,1.4c.718.2,1.442.367,2.161.555a1.079,1.079,0,0,1,.979,1.25q.017,1.975,0,3.95c-.005.746-.22,1-.953,1.2-.641.176-1.287.338-1.932.505a1.027,1.027,0,0,0-.711,1.678c.351.591.694,1.184,1.052,1.77a1.044,1.044,0,0,1-.171,1.432c-.962.955-1.913,1.923-2.876,2.876a1.016,1.016,0,0,1-1.475.173c-.645-.373-1.282-.76-1.926-1.135a.987.987,0,0,0-1.458.591c-.195.738-.384,1.478-.573,2.218a1.077,1.077,0,0,1-1.148.893c-1.355,0-2.711,0-4.066,0a1.067,1.067,0,0,1-1.142-.894c-.187-.762-.384-1.519-.584-2.277a.982.982,0,0,0-1.377-.586c-.679.395-1.355.791-2.026,1.2a1.043,1.043,0,0,1-1.431-.184c-.955-.962-1.919-1.915-2.87-2.881a1.009,1.009,0,0,1-.165-1.476c.365-.628.745-1.247,1.116-1.87a.978.978,0,0,0-.61-1.515c-.74-.193-1.478-.389-2.218-.577a.986.986,0,0,1-.823-1.06c0-1.394.007-2.79,0-4.185a1.027,1.027,0,0,1,.859-1.1c.758-.2,1.519-.385,2.275-.586a.984.984,0,0,0,.567-1.412c-.378-.643-.764-1.28-1.139-1.925a1.05,1.05,0,0,1,.195-1.524c.94-.949,1.88-1.9,2.841-2.828A4.247,4.247,0,0,1,595.261,637.926Zm13.775,11.957A5.94,5.94,0,1,0,603,655.836,5.945,5.945,0,0,0,609.036,649.883Z"
                        transform="translate(-562.993 -631.445)"
                        fill="#00c6a1"
                      />
                      <path
                        id="Path_4949"
                        data-name="Path 4949"
                        d="M580.023,662.8c1.506-1.056,2.964-2.083,4.422-3.105,1.126-.79,2.272-1.55,3.373-2.369a7.962,7.962,0,0,1,6.951-1.39c5.4,1.273,10.807,2.463,16.212,3.691a1.787,1.787,0,0,1,1.524,1.7,1.754,1.754,0,0,1-1.271,1.831,2.428,2.428,0,0,1-1.1.016c-3.856-.8-7.708-1.616-11.562-2.424-.676-.143-1.166.125-1.284.676a.981.981,0,0,0,.88,1.225c3.908.834,7.814,1.678,11.729,2.475a3.555,3.555,0,0,0,3.728-1.341,3.851,3.851,0,0,0,.327-4.321l2.991-2.246q4.43-3.325,8.859-6.646a1.972,1.972,0,0,1,3.069.577,1.922,1.922,0,0,1-.432,2.453q-3.361,3.35-6.732,6.69c-3.2,3.184-6.448,6.334-9.6,9.575a4.544,4.544,0,0,1-4.35,1.388c-3.893-.613-7.8-1.109-11.707-1.653-1.477-.206-2.949-.439-4.431-.593a1.769,1.769,0,0,0-1.124.3c-1.37.982-2.7,2.022-4.1,3.089Z"
                        transform="translate(-570.424 -619.403)"
                        fill="#00c6a1"
                      />
                      <path
                        id="Path_4950"
                        data-name="Path 4950"
                        d="M582.138,642.82c.512.288.99.547,1.458.819.705.408.764.386.968-.417.481-1.884.4-1.846,2.351-1.823.551.007,1.1.016,1.651,0a1.038,1.038,0,0,1,1.137.887c.1.38.184.762.277,1.142.108.444.332.6.76.323.345-.224.7-.43,1.058-.637a1.022,1.022,0,0,1,1.344.147c.645.634,1.282,1.275,1.921,1.912a1.061,1.061,0,0,1,.169,1.4c-.195.34-.4.672-.6,1.012-.3.514-.239.645.356.806l1.19.325a.938.938,0,0,1,.762.982c0,.942,0,1.886,0,2.828a.985.985,0,0,1-.812,1.014c-.433.123-.87.237-1.308.341-.4.1-.465.31-.268.645.209.354.422.707.626,1.065a1.035,1.035,0,0,1-.173,1.48c-.591.6-1.19,1.2-1.792,1.792a1.039,1.039,0,0,1-1.524.178c-.34-.195-.681-.388-1.012-.6-.408-.263-.608-.106-.711.312s-.213.834-.318,1.252a.962.962,0,0,1-.964.8c-.962.024-1.924.022-2.887,0a1,1,0,0,1-1-.837c-.117-.455-.233-.911-.352-1.365-.086-.329-.279-.4-.569-.228-.371.22-.74.444-1.115.659a1.044,1.044,0,0,1-1.479-.169c-.619-.6-1.223-1.223-1.84-1.827a1.053,1.053,0,0,1-.171-1.436c.213-.353.406-.718.634-1.06.27-.406.123-.608-.3-.709-.4-.1-.8-.2-1.2-.3a1.05,1.05,0,0,1-.885-1.091c-.009-.9-.011-1.807,0-2.71a1.013,1.013,0,0,1,.834-1.063c.419-.1.836-.209,1.252-.319s.571-.31.3-.72c-.237-.36-.45-.736-.661-1.113a1.022,1.022,0,0,1,.173-1.341c.639-.639,1.265-1.291,1.925-1.908A4,4,0,0,1,582.138,642.82Zm5.1,12.524A4.257,4.257,0,1,0,583,651.076,4.246,4.246,0,0,0,587.237,655.344Z"
                        transform="translate(-572.519 -626.674)"
                        fill="#00c6a1"
                      />
                      <path
                        id="Path_4951"
                        data-name="Path 4951"
                        d="M591.51,646.04c-.1.375-.178.7-.279,1.008a.94.94,0,0,1-.955.725c-.628,0-1.256.005-1.884,0-.731-.005-.977-.195-1.179-.889-.075-.261-.149-.523-.24-.848-.3.176-.564.333-.828.487a1,1,0,0,1-1.335-.18c-.428-.432-.856-.867-1.291-1.291a1.029,1.029,0,0,1-.185-1.429c.145-.231.276-.472.448-.771-.283-.084-.533-.165-.784-.237-.766-.215-.944-.444-.949-1.249,0-.51,0-1.023,0-1.533.005-.863.185-1.1,1.017-1.324.224-.061.448-.125.669-.186a.528.528,0,0,0-.018-.143c-.78-1.329-.933-1.377.4-2.674,1.252-1.218,1.24-1.232,2.868-.415.085-.281.182-.551.246-.828a1.014,1.014,0,0,1,1.129-.885c.569.013,1.139,0,1.708,0,.751,0,1.012.2,1.218.922.077.263.149.525.228.8a2.247,2.247,0,0,0,.231-.1c1.355-.775,1.355-.775,2.442.31l.041.041c1.5,1.478,1.392,1.3.521,2.955.209.063.408.125.608.178.905.244,1.085.472,1.089,1.392q0,.738,0,1.473c-.006.821-.209,1.089-.986,1.3-.226.061-.453.114-.749.187.162.292.3.56.457.817a1.013,1.013,0,0,1-.182,1.381c-.389.387-.777.779-1.166,1.166-.613.608-.927.646-1.66.217C591.96,646.307,591.761,646.187,591.51,646.04Zm-2.286-2.152a3.313,3.313,0,1,0-3.289-3.35A3.284,3.284,0,0,0,589.224,643.888Z"
                        transform="translate(-568.733 -633.378)"
                        fill="#00c6a1"
                      />
                      <path
                        id="Path_4952"
                        data-name="Path 4952"
                        d="M598.481,649.24a3.981,3.981,0,0,1-4.036-3.965,4,4,0,1,1,8.009-.061A3.969,3.969,0,0,1,598.481,649.24Z"
                        transform="translate(-558.362 -626.815)"
                        fill="#00c6a1"
                      />
                      <path
                        id="Path_4953"
                        data-name="Path 4953"
                        d="M586.19,647.709a2.32,2.32,0,1,1-2.294-2.29A2.329,2.329,0,0,1,586.19,647.709Z"
                        transform="translate(-569.146 -623.306)"
                        fill="#00c6a1"
                      />
                      <path
                        id="Path_4954"
                        data-name="Path 4954"
                        d="M587.979,637.933a1.382,1.382,0,1,1-1.392-1.383A1.427,1.427,0,0,1,587.979,637.933Z"
                        transform="translate(-566.082 -630.724)"
                        fill="#00c6a1"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <div className="media-body">
                <p className="mb-2">Nombre d'opérations</p>
                <h3 className="mb-0">
                  {placeStore?.effectiveOperations.length &&
                    placeStore?.effectiveOperations?.find(
                      (place) => place.type == filter
                    ).overAllOperation}
                  <sup style={{ fontSize: 16, marginLeft: 5 }}>
                    Operation(s)
                  </sup>
                </h3>
              </div>
            </div>
            <div className="media ai-icon py-4">
              <span className="mr-3 bgl-green text-primary">
                <svg
                  id="Group_8166"
                  data-name="Group 8166"
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="45"
                  viewBox="0 0 61.314 60.689"
                >
                  <path
                    id="Path_4757"
                    data-name="Path 4757"
                    d="M134.314,198.328a.531.531,0,0,1-.553.534c-.053,0-.106,0-.159,0q-29.945,0-59.889,0c-.053,0-.106,0-.159,0a.531.531,0,0,1-.553-.534v-1.047a.536.536,0,0,1,.535-.536q13.462,0,26.922-.005,16.661,0,33.322.005a.536.536,0,0,1,.535.536Z"
                    transform="translate(-73 -152.916)"
                    fill="#37c3a3"
                  />
                  <path
                    id="Path_4758"
                    data-name="Path 4758"
                    d="M128.654,175.551v16.032a.425.425,0,0,1-.424.424h-7.379a.423.423,0,0,1-.418-.358q-.274-1.757-.549-3.515c-.194-1.24-.364-2.485-.588-3.719a2.23,2.23,0,0,0-2.213-1.894,2.188,2.188,0,0,0-2.248,1.844c-.39,2.236-.711,4.486-1.062,6.729-.029.183-.06.365-.093.553a.426.426,0,0,1-.418.353H105.88a.424.424,0,0,1-.424-.424V175.552a.424.424,0,0,1,.424-.424c.913,0,1.806-.008,2.7.012a.92.92,0,0,1,.456.252,12.551,12.551,0,0,0,16.046,0,.929.929,0,0,1,.454-.253c.893-.019,1.786-.014,2.7-.012A.424.424,0,0,1,128.654,175.551Z"
                    transform="translate(-70.544 -154.552)"
                    fill="#37c3a3"
                  />
                  <path
                    id="Path_4759"
                    data-name="Path 4759"
                    d="M105.373,195.781h-3.864a.187.187,0,0,1-.186-.186v-7.989a.185.185,0,0,0-.186-.186H99.419a.186.186,0,0,0-.186.186v8.021a.188.188,0,0,1-.188.186H97.552a17.27,17.27,0,0,0-1.975.014,1.852,1.852,0,0,1-2.234-1.143,5.406,5.406,0,0,0-4.793-3.08c-1.844-.024-3.688-.005-5.533-.005h-.539a.187.187,0,0,1-.186-.187v-4.875a.186.186,0,0,0-.187-.186H80.414a.185.185,0,0,0-.186.186v6.978a.185.185,0,0,0,.186.186h3.763a.187.187,0,0,1,.187.186v1.693a.186.186,0,0,1-.187.186H76.129l-.186-.186q0-4.926,0-9.853a3.272,3.272,0,0,1,2.748-3.587q2.393-.649,4.786-1.3a.78.78,0,0,1,.174.009l.98,5.832a.187.187,0,0,0,.281.13l1.3-.775c1.334-.8,2.676-1.587,4-2.41a.861.861,0,0,1,1.065-.017c1.64,1.023,3.31,2,4.969,2.995.109.065.219.127.347.2a.186.186,0,0,0,.275-.131L97.82,181a.189.189,0,0,1,.234-.151c1.371.375,2.7.778,4.045,1.082,2.962.67,3.531,2.016,3.474,4.557-.065,2.872-.015,5.747-.015,8.62v.487A.186.186,0,0,1,105.373,195.781Z"
                    transform="translate(-72.777 -154.119)"
                    fill="#37c3a3"
                  />
                  <path
                    id="Path_4760"
                    data-name="Path 4760"
                    d="M84.842,168.285c1.259,0,2.478.067,3.685-.025a15.193,15.193,0,0,0,2.3-.532c.159-.038.373-.127.482-.061a6.019,6.019,0,0,0,3.42.619c.177,0,.355,0,.625,0-.085,2.183.437,4.419-1.074,6.333a5.225,5.225,0,0,1-5.469,1.944,5.127,5.127,0,0,1-3.947-4.573C84.746,170.789,84.842,169.568,84.842,168.285Z"
                    transform="translate(-72.107 -155.118)"
                    fill="#37c3a3"
                  />
                  <path
                    id="Path_4761"
                    data-name="Path 4761"
                    d="M84.793,166.632a4.874,4.874,0,0,1,2.82-4.77,4.694,4.694,0,0,1,5.21.628c.517.4,1.032.811,1.534,1.232a3.042,3.042,0,0,1,.976,2.842c-1.622.232-3.143.177-4.1-1.38-.124.034-.15.032-.158.044a3.884,3.884,0,0,1-3.825,1.416C86.462,166.576,85.663,166.632,84.793,166.632Z"
                    transform="translate(-72.108 -155.594)"
                    fill="#37c3a3"
                  />
                  <path
                    id="Path_4762"
                    data-name="Path 4762"
                    d="M116.682,184.931l1.6,10.342a.159.159,0,0,1-.157.183h-3.5a.159.159,0,0,1-.157-.183l1.6-10.342Z"
                    transform="translate(-69.862 -153.809)"
                    fill="#37c3a3"
                  />
                  <path
                    id="Path_4763"
                    data-name="Path 4763"
                    d="M93.041,177.414c.151.754.117,1.3-.56,1.715a19.023,19.023,0,0,0-2.1,1.567.606.606,0,0,1-.913-.015,17.617,17.617,0,0,0-2.056-1.53c-.724-.428-.711-1-.583-1.723A7.448,7.448,0,0,0,93.041,177.414Z"
                    transform="translate(-71.959 -154.378)"
                    fill="#37c3a3"
                  />
                  <path
                    id="Path_4764"
                    data-name="Path 4764"
                    d="M85.8,194.853v-1.94c2.444-.3,4.127-.13,5.007,1.94Z"
                    transform="translate(-72.031 -153.214)"
                    fill="#37c3a3"
                  />
                  <path
                    id="Path_4765"
                    data-name="Path 4765"
                    d="M84.984,180.134a3.473,3.473,0,0,1,.98.265c.739.5,1.439,1.06,2.207,1.636l-2.6,1.555Z"
                    transform="translate(-72.093 -154.173)"
                    fill="#37c3a3"
                  />
                  <path
                    id="Path_4766"
                    data-name="Path 4766"
                    d="M94.069,183.588l-2.6-1.559c.829-.622,1.593-1.222,2.394-1.768.161-.111.463-.016.765-.016Z"
                    transform="translate(-71.602 -154.167)"
                    fill="#37c3a3"
                  />
                  <path
                    id="Path_4767"
                    data-name="Path 4767"
                    d="M132.006,201.744v10.513a1.048,1.048,0,0,1-1.049,1.049h-4.191a1.048,1.048,0,0,1-1.049-1.049V208.1a1.049,1.049,0,0,0-1.05-1.049H82.386a1.048,1.048,0,0,0-1.049,1.049v4.144a1.05,1.05,0,0,1-1.049,1.05H76.054A1.049,1.049,0,0,1,75,212.242v-10.5a1.048,1.048,0,0,1,1.049-1.049h54.9A1.048,1.048,0,0,1,132.006,201.744Z"
                    transform="translate(-72.848 -152.617)"
                    fill="#37c3a3"
                  />
                  <rect
                    id="Rectangle_17476"
                    data-name="Rectangle 17476"
                    width="40.12"
                    height="4.13"
                    rx="0.691"
                    transform="translate(10.592 56.558)"
                    fill="#37c3a3"
                  />
                  <g id="VMsl0E.tif" transform="translate(35.477)">
                    <g id="Group_8165" data-name="Group 8165">
                      <path
                        id="Path_4768"
                        data-name="Path 4768"
                        d="M117.616,156c.4.053.81.094,1.211.16a10.743,10.743,0,0,1,4.706,2.012c.033.026.069.048.148.1v-.812a.13.13,0,0,1,.13-.13h1.047a.131.131,0,0,1,.13.133l-.063,2.643a.129.129,0,0,1-.105.125l-2.6.483a.13.13,0,0,1-.152-.1l-.189-1.009a.129.129,0,0,1,.1-.152l.584-.111a.132.132,0,0,0,.054-.234,9.305,9.305,0,0,0-3.791-1.637,9.413,9.413,0,0,0-6.944,1.273,9.7,9.7,0,1,0,13.917,4.2.127.127,0,0,1,.06-.169l.925-.441a.13.13,0,0,1,.175.068c.1.244.208.475.3.713a10.661,10.661,0,0,1,.668,3,1.4,1.4,0,0,0,.037.187v1.375c-.013.055-.028.11-.037.166-.074.509-.122,1.023-.226,1.526a11,11,0,0,1-8.816,8.445c-.406.067-.813.113-1.22.169h-1.383c-.047-.012-.095-.027-.143-.034-.5-.073-1.009-.122-1.5-.221a11,11,0,0,1-8.487-8.8c-.067-.409-.114-.821-.17-1.232l0-1.435c.067-.473.119-.949.207-1.418a10.993,10.993,0,0,1,8.866-8.677c.4-.067.807-.112,1.211-.167Z"
                        transform="translate(-105.981 -155.999)"
                        fill="#37c3a3"
                      />
                      <path
                        id="Path_4769"
                        data-name="Path 4769"
                        d="M121.974,166.314v.886a.2.2,0,0,0,.2.2h2.006a.2.2,0,0,1,.2.216A7.687,7.687,0,0,1,117.6,174.4a.2.2,0,0,1-.219-.2v-2.009a.2.2,0,0,0-.2-.2H116.3a.2.2,0,0,0-.2.2v2a.2.2,0,0,1-.215.2,7.684,7.684,0,0,1-6.793-6.769.2.2,0,0,1,.2-.221H111.3a.2.2,0,0,0,.2-.2v-.886a.2.2,0,0,0-.2-.2h-2a.2.2,0,0,1-.2-.213,7.676,7.676,0,0,1,6.761-6.8.2.2,0,0,1,.222.2v2.006a.2.2,0,0,0,.2.2h.9a.2.2,0,0,0,.2-.2v-2a.2.2,0,0,1,.218-.2,7.688,7.688,0,0,1,6.782,6.782.2.2,0,0,1-.2.218h-2.009A.2.2,0,0,0,121.974,166.314Zm-5.866-3.042v3.906a.211.211,0,0,0,.211.211h3.9a.211.211,0,0,0,.211-.211v-.856a.21.21,0,0,0-.211-.211h-2.64a.211.211,0,0,1-.211-.211v-2.628a.21.21,0,0,0-.211-.211h-.842A.211.211,0,0,0,116.109,163.272Z"
                        transform="translate(-105.746 -155.763)"
                        fill="#37c3a3"
                      />
                    </g>
                  </g>
                </svg>
              </span>
              <div className="media-body">
                <p className="mb-2">
                  Pourcentage d'opérations qui ont pris plus de
                  <span style={{ color: "#00C6A1" }}>5 min</span>
                </p>
                <h3 className="mb-0">
                  {(placeStore?.effectiveOperations.length &&
                    placeStore?.effectiveOperations
                      ?.find((place) => place.type == filter)
                      .overallAttractivnessRate?.toFixed(2)) ||
                    0}
                  %
                </h3>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
