import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  // apiKey: "AIzaSyBidCgIhsmvJvfyzo4u9Ok8r8CEHDKiU0Y",
  // authDomain: "no-waiting-um6p.appspot.com",
  // databaseURL: "https://no-waiting-um6p.firebaseio.com",
  // projectId: "no-waiting-um6p",
  // storageBucket: "no-waiting-um6p.appspot.com",
  // messagingSenderId: "500583023100",
  // appId: "1:500583023100:android:6f236bb66c3e2f48b93279"
  apiKey: "AIzaSyCGVveZ3LhRvTLQ1sMtbErzN22dCMc-dfY",
  authDomain: "bcp-project-dd528.firebaseapp.com",
  databaseURL: "https://bcp-project-dd528-default-rtdb.firebaseio.com/",
  projectId: "bcp-project-dd528",
  storageBucket: "bcp-project-dd528.appspot.com",
  messagingSenderId: "471706049088",
  appId: "1:471706049088:web:5bac31711e04c2b4cadda5",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const realTimeDb = getDatabase(app);
export const storage = getStorage(app);
export { db };
