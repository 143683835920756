import React, { Fragment, useEffect, useState} from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "bootstrap-daterangepicker";
import { Container } from "react-bootstrap";

import ChartKPIs1 from "./components/ChartKPIs1";
import ChartKPIs2 from "./components/ChartKPIs2";
import ChartKPIs3 from "./components/ChartKPIs3";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useChartStore } from "../../../zustand-store/chartStore";
const DATE_OPTIONS = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
function formatDate(inputDate) {
  const date = new Date(inputDate);
  
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
  const year = date.getFullYear();


  const formattedDate = `${day}-${month}-${year}`;
  
  return formattedDate;
}

export const Charts = () => {

  const [chartTitle , setChartTitle] = useState("");
  const { name } = useParams();
  const chartStore = useChartStore();
  const dispatch = useDispatch();
   useEffect(() => {
  switch (name) {
    case "occupation" : setChartTitle("Occupation maximal");break;
    case "waitingtime" : setChartTitle("Temps d'attente maximal");break;
    case "queue": setChartTitle("Longueur maximale de la file d'attente");break;
    case "Operations" : setChartTitle("Nombre d'opérations");break;
    case "attractivness" : setChartTitle("Attrativite");break;
    }
   },[])
  useEffect(() => {
    const tokenDetailsString = localStorage.getItem("userDetails");
    let tokenDetails = "";
    if (!tokenDetailsString) {
      dispatch(logout());
      return;
    }
    if (!chartStore?.startDate || !chartStore?.endDate) {
      chartStore.setStartDate(new Date())
      chartStore.setEndDate(new Date())
    }
  },[]);
  return (
    <Fragment>
      <Container fluid className="py-5 px-5 bg-header-tv">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title"> {chartTitle}  </h4>
                <h5 className="card-title">{chartStore?.startDate && formatDate(chartStore.startDate)} / {chartStore?.endDate && formatDate(chartStore.endDate)}</h5>

              </div>
              <div className="card-body p-2">
                <ChartKPIs1 name={name} />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">{chartTitle}: {chartStore.clickedPlace}</h4>
                <h5 className="card-title">{chartStore?.startDate && formatDate(chartStore.startDate)} / {chartStore?.endDate && formatDate(chartStore.endDate)}</h5>
              </div>
              <div className="card-body p-2">
                <ChartKPIs2 name={name} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">{chartTitle}: {chartStore.clickedPlace}</h4>
                <h5 className="card-title">{formatDate(chartStore.startDate)} / {formatDate(chartStore.endDate)}</h5>

              </div>
              <div className="card-body">
                <ChartKPIs3 name={name} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  );
};

export default Charts;
