import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///
//  import drump from "../../../images/card/drump.png";

class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
      // this.mm("dispose");
      // //(this.mm);
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
   /// Open menu
   componentDidMount() {
      // sidebar open/close
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);   
   }
   render() {
      /// Path
      let path = window.location.pathname;
      path = path.split("/");
      path = path[path.length - 1];

      /// Active menu
      let dashboard = ["dashboard", ""],
         agency = ["agence-form", "agence-table"],
         advertis = ["advertising-form", "advertising-table"],
         program = ["program-form", "program-table"],
         news = ["news-form", "news-table"],
         screenads = ["screen-ads-form", "screen-ads-table"];

         ;
      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <MM className="metismenu" id="menu">
               <li className={`${dashboard.includes(path) ? "" : ""}`}>
                        <a
                           href="/overview"
                           className="ai-icon"
                           
                        >
                           <i className="flaticon-381-networking"></i>
                           <span className="nav-text">Overview</span>
                        </a>
                  </li>
                  <li className={`${dashboard.includes(path) ? "mm-active" : ""}`}>
                        <Link
                           to="/"
                           className="ai-icon"
                           
                        >
                           <i className="flaticon-381-networking"></i>
                           <span className="nav-text">Affluence</span>
                        </Link>
                  </li>
                  <li className={`${agency.includes(path) ? "mm-active" : ""}`}>
                        <Link
                           to="/agence-table"
                           className="ai-icon"
                           
                        >
                           <i className="flaticon-381-home"></i>
                           <span className="nav-text">Liste Agences</span>
                        </Link>
                  </li>
                  <li className={`${advertis.includes(path) ? "mm-active" : ""}`}>
                        <Link
                           to="/advertising-table"
                           className="ai-icon"
                           
                        >
                          <i className="fa fa-bullhorn" aria-hidden="true"></i>
                           <span className="nav-text">Liste Publicité</span>
                        </Link>
                  </li>
                  <li className={`${program.includes(path) ? "mm-active" : ""}`}>
                        <Link
                           to="/program-table"
                           className="ai-icon"
                           
                        >
                           <i className="flaticon-381-calendar-1"></i>
                           <span className="nav-text">Liste programmes</span>
                        </Link>
                  </li>
                  <li className={`${news.includes(path) ? "mm-active" : ""}`}>
                        <Link
                           to="/news-table"
                           className="ai-icon"
                           
                        >
                          <i className="fa fa-newspaper-o" aria-hidden="true"></i>
                           <span className="nav-text">Actualités</span>
                        </Link>
                  </li>
                  <li className={`${screenads.includes(path) ? "mm-active" : ""}`}>
                        <Link
                           to="/screen-ads-table"
                           className="ai-icon"
                           
                        >
                          <i className="flaticon-381-television"></i>
                           <span className="nav-text">Screen Ads</span>
                        </Link>
                  </li>
                  
               </MM>
              
            </PerfectScrollbar>
         </div>
      );
   }
}

export default SideBar;
