import { create } from 'zustand'

export const useChartStore = create((set) => ({
    
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    endDate : new Date(new Date().getFullYear(), new Date().getMonth(), 30),
    placesCheckedValues : [],
    mealsCheckedValues : [],
    clickedPlace : "",
    chartOne: [],
    chartTwo:[],
    chartThree:[],
    setMealsCheckedValues: (values) => set(() => ({ mealsCheckedValues: values})),
    setChartOne: (chart) => set(() => ({ chartOne: chart})),
    setChartTwo: (chart) => set(() => ({ chartTwo: chart})),
    setChartThree: (chart) => set(() => ({ chartThree: chart})),
    setStartDate: (date) => set(() => ({ startDate: date})),
    setEndDate: (date) => set(() => ({ endDate: date})),
    setPlacesCheckedValues: (values) => set(() => ({ placesCheckedValues: values})),
    setClickedPlace: (place) => set(() => ({ clickedPlace: place})),
}))