import React, { Fragment, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "bootstrap-daterangepicker";
import { Container } from "react-bootstrap";

import ChartKPIs2 from "./components/ChartKPIs2";
import ChartKPIs3 from "./components/ChartKPIs3";
import { logout } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useChartStore } from "../../../zustand-store/chartStore";
import axios from "axios";
const DATE_OPTIONS = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};
function formatDate(inputDate) {
  const date = new Date(inputDate);

  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}

export const Scoring = () => {
  const { name } = useParams();
  const chartStore = useChartStore();
  const dispatch = useDispatch();
  const [scoringData, setScoringData] = useState([]);
  console.log(`name is ${name}`);
  useEffect(() => {
    const tokenDetailsString = localStorage.getItem("userDetails");
    let tokenDetails = "";
    if (!tokenDetailsString) {
      dispatch(logout());
      return;
    }
    if (!chartStore?.startDate || !chartStore?.endDate) {
      chartStore.setStartDate(new Date());
      chartStore.setEndDate(new Date());
    }
  }, []);
  useEffect(async () => {
    const res = await axios.get("/stationDailyHourlyRange", {
      params: {
        startDate: chartStore.startDate,
        endDate: chartStore.endDate,
        agency: name,
        filter: chartStore.mealsCheckedValues,
      },
    });
    setScoringData(res.data);

    console.log(res.data);
  }, [chartStore.startDate, chartStore.endDate, chartStore.mealsCheckedValues]);
  return (
    <Fragment>
      <Container fluid className="py-5 px-5 bg-header-tv">
        <div className="row">
          {scoringData.map((item, index) => {
            return (
              <div key={index} className="col-xl-4 col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title text-sm-left">
                      {name}: {item.station}
                    </h4>
                    <h5 className="card-title text-sm-left">
                      {chartStore?.startDate &&
                        formatDate(chartStore.startDate)}{" "}
                      / {chartStore?.endDate && formatDate(chartStore.endDate)}
                    </h5>
                  </div>
                  <div className="card-body p-2">
                    <ChartKPIs2 name={name} data={item} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="row">
          {scoringData.map((item, index) => {
            return (
              <div key={index} className="col-xl-4 col-lg-6">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      {name}: {item.station}
                    </h4>
                    <h5 className="card-title">
                      {chartStore?.startDate &&
                        formatDate(chartStore.startDate)}{" "}
                      / {chartStore?.endDate && formatDate(chartStore.endDate)}
                    </h5>
                  </div>
                  <div className="card-body p-2">
                    <ChartKPIs3 name={name} data={item} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </Fragment>
  );
};
