import React, { Fragment,useEffect,useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
   Row,
   Col,
   Card,
   Table,
   Badge,
   Button
} from "react-bootstrap";
import {
   collection,
   query,
   onSnapshot,
   doc,
   updateDoc,
   deleteDoc,
 } from "firebase/firestore";
 import {db} from '../../../Firebase';
/// imge
import { Link } from "react-router-dom";

const ProgramTable = () => {
   const [programmes,setProgrammes] = useState([]);
   useEffect(() => {
      const q = query(collection(db, "program"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let todosArray = [];
        querySnapshot.forEach((doc) => {
          todosArray.push({ ...doc.data(), id: doc.id });
        });
        setProgrammes(todosArray);
      });
      return () => unsub();
    }, []);

    const handleDelete = async (id) => {
      await deleteDoc(doc(db, "program", id)); 
   }
   return (
      <Fragment>
         <Row className="justify-content-between align-items-center">
         <PageTitle activeMenu="Programmes" motherMenu="Liste des programmes" />
         <Link to="/program-form" className="ai-icon">
            <Button variant="primary">
            Nouveau programme{" "}
               <span className="btn-icon-right">
                  <i className="fa fa-plus-circle" />
               </span>
            </Button>
         </Link>
         </Row>
         <Row>
            <Col lg={12}>
               <Card>
                  <Card.Header>
                     <Card.Title>Liste des programmes</Card.Title>
                  </Card.Header>
                  <Card.Body>
                     <Table responsive>
                        <thead>
                           <tr>
                              <th className="width50">
                                 <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                    <input
                                       type="checkbox"
                                       className="custom-control-input"
                                       id="checkAll"
                                       required=""
                                    />
                                    <label
                                       className="custom-control-label"
                                       htmlFor="checkAll"
                                    ></label>
                                 </div>
                              </th>
                              <th>
                                 <strong>NO.</strong>
                              </th>
                              <th>
                                 <strong>Titre du programme</strong>
                              </th>
                              <th>
                                 <strong>Publicité</strong>
                              </th>
                              <th>
                                 <strong>Statut</strong>
                              </th>
                           </tr>
                        </thead>
                        <tbody>
                           { programmes && programmes.map((program)=>
                            <tr>
                            <td>
                               <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                  <input
                                     type="checkbox"
                                     className="custom-control-input"
                                     id="customCheckBox2"
                                     required=""
                                  />
                                  <label
                                     className="custom-control-label"
                                     htmlFor="customCheckBox2"
                                  ></label>
                               </div>
                            </td>
                            <td>
                               <strong>{program.id}</strong>
                            </td>
                            <td>
                               <div className="d-flex align-items-center">
                                 
                                  <span className="w-space-no">
                                  {program.title}
                                  </span>
                               </div>
                            </td>
                            <td>
                               <Badge variant="light">{program.selectedPub.label}</Badge>
                             </td>
                            <td>
                               <div className="d-flex align-items-center">
                                  <i className="fa fa-circle text-success mr-1"></i>{" "}
                                  Successful
                               </div>
                            </td>
                            <td>
                            <div className="d-flex">
                                  <a
                                     href={"program-form/"+program.id}
                                     className="btn btn-danger shadow btn-xs sharp"
                                     target="_blank"
                                  >
                                     <i className="fa fa-pencil"></i>
                                  </a>
                                   
                               </div>
                               <div className="d-flex">
                                  <Button
                                   onClick={() => handleDelete(program.id)}
                                     href="#"
                                     className="btn btn-danger shadow btn-xs sharp"
                                  >
                                     <i className="fa fa-trash"></i>
                                  </Button>
                                   


                               </div>
                            </td>
                         </tr>
                           )}
                          
                  
                        </tbody>
                     </Table>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
      </Fragment>
   );
};

export default ProgramTable;
