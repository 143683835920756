import React, { Fragment, useState,useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
   Row,
   Col,
   Card,
   Table,
   Button
} from "react-bootstrap";
import {
   collection,
   query,
   onSnapshot,
   doc,
   updateDoc,
   deleteDoc,
 } from "firebase/firestore";
 import {db} from '../../../Firebase';

/// imge
import a1 from "../../../images/a1.jpg";
import { Link } from "react-router-dom";

const AgenceTable = () => {
   const [agences,setAgences] = useState([]);
   useEffect(() => {
      const q = query(collection(db, "Agences_v1"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let todosArray = [];
        querySnapshot.forEach((doc) => {
          todosArray.push({ ...doc.data(), id: doc.id });
        });
        setAgences(todosArray);
      });
      return () => unsub();
    }, []);
    const handleDelete = async (id) => {
      await deleteDoc(doc(db, "Agences_v1", id)); 
   }
   return (
      <Fragment>
         <Row className="justify-content-between align-items-center">
         <PageTitle activeMenu="Agences" motherMenu="Liste des agences" />
         <Link to="/agence-form" className="ai-icon">
            <Button variant="primary">
            Nouvelle agence{" "}
               <span className="btn-icon-right">
                  <i className="fa fa-plus-circle" />
               </span>
            </Button>
         </Link>
         </Row>
         <Row>
            <Col lg={12}>
               <Card>
                  <Card.Header>
                     <Card.Title>Liste des agences</Card.Title>
                  </Card.Header>
                  <Card.Body>
                     <Table responsive>
                        <thead>
                           <tr>
                              <th className="width50">
                                 <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                    <input
                                       type="checkbox"
                                       className="custom-control-input"
                                       id="checkAll"
                                       required=""
                                    />
                                    <label
                                       className="custom-control-label"
                                       htmlFor="checkAll"
                                    ></label>
                                 </div>
                              </th>
                              <th>
                                 <strong>NO.</strong>
                              </th>
                              <th>
                                 <strong>Nom d'agence</strong>
                              </th>
                              <th>
                                 <strong>Tél d'agence</strong>
                              </th>
                              <th>
                                 <strong>Email</strong>
                              </th>
                              <th>
                                 <strong>Statut</strong>
                              </th>
                           </tr>
                        </thead>
                        <tbody>
                           { agences && agences.map((agence)=>
                           <tr>
                           <td>
                              <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                 <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="customCheckBox2"
                                    required=""
                                 />
                                 <label
                                    className="custom-control-label"
                                    htmlFor="customCheckBox2"
                                 ></label>
                              </div>
                           </td>
                           <td>
                              <strong>{agence.id}</strong>
                           </td>
                           <td>
                              <div className="d-flex align-items-center">
                                 <img
                                    src={a1}
                                    className="rounded-lg mr-2"
                                    width="24"
                                    alt=""
                                 />{" "}
                                 <span className="w-space-no">
                                 {agence.name}
                                 </span>
                              </div>
                           </td>
                           <td>{agence.phone} </td>
                           <td>{agence.email}</td>
                           <td>
                              <div className="d-flex align-items-center">
                                 <i className="fa fa-circle text-success mr-1"></i>{" "}
                                 Successful
                              </div>
                           </td>
                           <td>
                           <div className="d-flex">
                                  <a
                                     href={"agence-form/"+agence.id}
                                     className="btn btn-danger shadow btn-xs sharp"
                                     target="_blank"
                                  >
                                     <i className="fa fa-pencil"></i>
                                  </a>
                                   
                               </div>
                              <div className="d-flex">
                                 <Button
                                    onClick={() => handleDelete(agence.id)}
                                    href="#"
                                    className="btn btn-danger shadow btn-xs sharp"
                                 >
                                    <i className="fa fa-trash"></i>
                                 </Button>
                              </div>
                           </td>
                        </tr>
                           )
                           }
                           
                        </tbody>
                     </Table>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
      </Fragment>
   );
};

export default AgenceTable;
