import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { usePlacesStore } from "../../../../zustand-store/store"
import { useChartStore } from "../../../../zustand-store/chartStore";

export const WaitingTime = () => {
    const places = usePlacesStore()

    return (
        <> 
        
    <Link to={"overview/waitingtime"}>
    <div className="media ai-icon border-bottom pb-4">
    <span className="mr-3 bgl-primary text-primary">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 52.754 52.787"
      >
        <g id="hfcSyP.tif" transform="translate(0)">
          <g id="Group_8200" data-name="Group 8200">
            <path
              id="Path_4834"
              data-name="Path 4834"
              d="M507.926,1004.489a1.693,1.693,0,0,1,1.131,1.505,1.525,1.525,0,0,1-1.489,1.58c-.512.016-1.025,0-1.6,0,0,1.25-.011,2.463.017,3.674,0,.11.239.244.391.316,1.326.63,2.724,1.14,3.975,1.893a16.32,16.32,0,0,1,6.021,6.549c-.156.015-.281.039-.4.039-1.442,0-2.886-.029-4.327.014a9.126,9.126,0,0,0-1.968.3.669.669,0,0,1-.752-.212,10.784,10.784,0,1,0-10.986,17.879,9.837,9.837,0,0,0,5.071.4,1.889,1.889,0,0,1,.256-.029,2.036,2.036,0,0,1,.2.01c-.811,3.337-.811,3.337.761,6.117-.705.081-1.375.194-2.05.229a16.986,16.986,0,0,1-6.086-33.124c.473-.154.638-.349.617-.847-.041-1.044-.013-2.09-.013-3.207-.467,0-.906,0-1.344,0a1.553,1.553,0,0,1-.684-3.017c.028-.014.046-.047.069-.072Z"
              transform="translate(-471.862 -1004.489)"
              fill="#3841e2"
            />
            <path
              id="Path_4835"
              data-name="Path 4835"
              d="M471.42,1042.937a13.649,13.649,0,0,1,.884-1.115c1.421-1.468,2.887-2.893,4.284-4.383a4.333,4.333,0,0,0,1.12-3.067c0-4.415.014-8.83-.01-13.243a5.2,5.2,0,0,1,1.4-3.647q4.037-4.51,8.051-9.042c1.079-1.215,2.427-.948,2.939.6.166.5.484,1.074.365,1.516-.1.386-.711.649-1.114.942a19.734,19.734,0,0,0-8.18,12.751,20.124,20.124,0,0,0,13.644,22.772,17.425,17.425,0,0,0,7.1.889c.12-.011.241-.015.36-.016a.463.463,0,0,1,.1.006l.441,3.162c-.06,0-.239.018-.417.018-2.525,0-5.049.03-7.574-.009a5.478,5.478,0,0,0-4.354,1.815c-1.209,1.313-2.485,2.566-3.737,3.84a1.592,1.592,0,0,1-2.585,0q-6.031-6.03-12.053-12.066a7.469,7.469,0,0,1-.665-.9Z"
              transform="translate(-471.42 -1004.598)"
              fill="#3841e2"
            />
            <path
              id="Path_4836"
              data-name="Path 4836"
              d="M517.732,1023.806c1.767,0,3.535,0,5.3,0a2.319,2.319,0,1,1,.016,4.631q-5.327.007-10.654,0a2.319,2.319,0,1,1-.016-4.631C514.163,1023.8,515.948,1023.806,517.732,1023.806Z"
              transform="translate(-472.737 -1005.15)"
              fill="#3841e2"
            />
            <path
              id="Path_4837"
              data-name="Path 4837"
              d="M514.462,1031.809c1.8,0,3.6-.008,5.4,0a2.311,2.311,0,0,1,.558,4.559,2.21,2.21,0,0,1-.557.068q-5.4.005-10.809,0a2.31,2.31,0,0,1-.556-4.557,2.905,2.905,0,0,1,.711-.071C510.962,1031.806,512.712,1031.809,514.462,1031.809Z"
              transform="translate(-472.627 -1005.423)"
              fill="#3841e2"
            />
            <path
              id="Path_4838"
              data-name="Path 4838"
              d="M512.97,1039.813c1.253,0,2.5-.008,3.757,0a2.316,2.316,0,1,1,0,4.63q-3.807.012-7.615,0a2.316,2.316,0,1,1,0-4.63C510.4,1039.806,511.684,1039.812,512.97,1039.813Z"
              transform="translate(-472.628 -1005.697)"
              fill="#3841e2"
            />
            <path
              id="Path_4839"
              data-name="Path 4839"
              d="M500.116,1026.788c0-1.506,0-2.961,0-4.417a2.372,2.372,0,0,1,.084-.707,1.533,1.533,0,0,1,2.994.44q.017,3.142,0,6.286a1.482,1.482,0,0,1-1.48,1.479q-3.142.022-6.286,0a1.539,1.539,0,0,1,.014-3.077c1.356-.016,2.713-.005,4.07-.005Z"
              transform="translate(-472.19 -1005.04)"
              fill="#3841e2"
            />
            <path
              id="Path_4840"
              data-name="Path 4840"
              d="M511.372,1052.449c-.772,0-1.543.009-2.314,0a2.314,2.314,0,1,1,0-4.628q2.315-.019,4.63,0a2.314,2.314,0,1,1,0,4.628C512.915,1052.457,512.144,1052.449,511.372,1052.449Z"
              transform="translate(-472.628 -1005.97)"
              fill="#3841e2"
            />
          </g>
        </g>
      </svg>
    </span>
    <div className="media-body">
      <p className="mb-2">Temps d'attente maximal</p>
      <h3 className="mb-0">
        {places?.maxWaitingTime?.maxWaitingTime?.toFixed(2) ?? 0}
        <sup>min</sup>
      </h3>
      {/* <span className='badge badge-primary'>+3.5%</span> */}
    </div>
  </div>
  <div className="media ai-icon mt-4">
  <span className="mr-3 bgl-primary text-primary">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 61.503 60.395"
    >
      <g id="KQRCEA.tif" transform="translate(0)">
        <g
          id="Group_8162"
          data-name="Group 8162"
          transform="translate(0 0)"
        >
          <path
            id="Path_4733"
            data-name="Path 4733"
            d="M644.563,204.851a2.05,2.05,0,0,1-2.248,1.2,6.686,6.686,0,0,0-.72,0,2.442,2.442,0,0,0-2.7,2.719c-.005,1.882,0,3.763,0,5.644q0,5.193,0,10.387a3.739,3.739,0,0,1-.089.89,1.627,1.627,0,0,1-3.138-.009,3.483,3.483,0,0,1-.086-.831c-.008-1.681.05-3.365-.015-5.043a33.752,33.752,0,0,1,.868-9.162,3.879,3.879,0,0,0-.5-3.055,1.43,1.43,0,0,1,.573-1.785c1.368-.611,2.749-1.194,4.109-1.821a5.861,5.861,0,0,0,1.321-.808,1.546,1.546,0,0,1,2.382.365c.092.152.166.315.248.474Z"
            transform="translate(-583.06 -166.481)"
            fill="#3a41d9"
          />
          <path
            id="Path_4734"
            data-name="Path 4734"
            d="M534,203.975a1.823,1.823,0,0,1,1.586-1.271,1.41,1.41,0,0,1,1.187.391,5.214,5.214,0,0,0,1.358.854c1.248.583,2.519,1.119,3.779,1.679a1.284,1.284,0,0,1,.589,1.849,4.193,4.193,0,0,0-.519,3.283c.178.8.327,1.606.531,2.4A16.978,16.978,0,0,1,543,217.73c-.043,2.421-.005,4.843-.015,7.264a1.665,1.665,0,0,1-1.447,1.831,1.6,1.6,0,0,1-1.81-1.294,4.244,4.244,0,0,1-.057-.835q0-7.925,0-15.849c0-1.921-.911-2.835-2.818-2.838a5.8,5.8,0,0,0-.6,0,2.048,2.048,0,0,1-2.247-1.2Z"
            transform="translate(-534 -166.446)"
            fill="#3a41d9"
          />
          <path
            id="Path_4735"
            data-name="Path 4735"
            d="M561.917,186.9c-.708-1.546-1.384-3.039-2.079-4.522-.072-.152-.258-.251-.392-.375a1.87,1.87,0,0,0-.167.519c-.016,1.04-.033,2.082,0,3.122a.769.769,0,0,1-.559.89c-.877.335-1.717.762-2.585,1.118a4.281,4.281,0,0,0-2.582,2.417c-.08-.086-.147-.129-.173-.19a4.035,4.035,0,0,0-2.206-2.094c-1.007-.441-2.006-.9-3.021-1.319a.6.6,0,0,1-.432-.668c.021-1.1.014-2.2,0-3.3a1.8,1.8,0,0,0-.147-.482c-.135.119-.327.212-.4.362-.7,1.484-1.37,2.976-2.078,4.525.2.1.383.194.575.279,1.664.734,3.337,1.447,4.99,2.2a2.146,2.146,0,0,1,.888,3.389,2.051,2.051,0,0,1-2.684.569c-2-.866-3.989-1.749-5.983-2.626-.494-.217-.993-.422-1.479-.657a2.119,2.119,0,0,1-1.137-2.9c1.007-2.329,2.023-4.656,3.119-6.943a4.578,4.578,0,0,1,4.232-2.786q5.881-.088,11.765,0a4.644,4.644,0,0,1,4.294,2.885c1.052,2.221,2.038,4.473,3.02,6.726a2.151,2.151,0,0,1-1.2,3.063q-3.638,1.628-7.3,3.213a2.257,2.257,0,0,1-3.081-1.108,2.19,2.19,0,0,1,1.336-2.874c1.659-.742,3.328-1.466,4.991-2.2C561.6,187.075,561.734,187,561.917,186.9Z"
            transform="translate(-536.929 -154.218)"
            fill="#3a41d9"
          />
          <path
            id="Path_4736"
            data-name="Path 4736"
            d="M602.615,181.957c-.9,1.561-1.521,3.265-2.36,4.938.217.106.4.206.593.29,1.663.734,3.333,1.454,4.99,2.2a2.133,2.133,0,0,1,1.359,2.19,2,2,0,0,1-1.507,1.878,2.937,2.937,0,0,1-1.623-.115c-2.489-1.038-4.951-2.141-7.412-3.246a2.161,2.161,0,0,1-1.19-3.028c.992-2.27,1.989-4.539,3.051-6.777a4.609,4.609,0,0,1,4.25-2.853q5.911-.1,11.825,0a4.612,4.612,0,0,1,4.245,2.862c1.061,2.238,2.055,4.508,3.043,6.78a2.141,2.141,0,0,1-1.193,3.019q-3.693,1.654-7.406,3.261a2.177,2.177,0,1,1-1.761-3.978c1.655-.751,3.327-1.468,4.991-2.2.179-.079.353-.17.572-.275-.72-1.573-1.421-3.115-2.139-4.649-.053-.112-.228-.166-.346-.248a1.255,1.255,0,0,0-.165.411c-.014,1.12-.024,2.242,0,3.362a.635.635,0,0,1-.461.711c-.889.358-1.749.788-2.636,1.15a4.355,4.355,0,0,0-2.664,2.567c-.652-1.887-2.255-2.392-3.749-3.048-.673-.3-1.587-.46-1.925-.985s-.105-1.43-.113-2.168c-.007-.654,0-1.309,0-1.962Z"
            transform="translate(-563.586 -154.218)"
            fill="#3a41d9"
          />
          <path
            id="Path_4737"
            data-name="Path 4737"
            d="M586.385,132.558a7.6,7.6,0,1,1-7.572,7.616A7.575,7.575,0,0,1,586.385,132.558Zm1,5.257c0-.761.011-1.522,0-2.282-.014-.691-.418-1.125-1-1.109a1,1,0,0,0-.953,1.1,12.543,12.543,0,0,0,.013,2.1,2.4,2.4,0,0,1-1.63,2.985.706.706,0,0,0-.154.092,1.022,1.022,0,0,0-.422,1.438.993.993,0,0,0,1.474.233c.7-.421,1.39-.87,2.092-1.293a1.142,1.142,0,0,0,.587-1.1C587.372,139.257,587.384,138.536,587.383,137.815Z"
            transform="translate(-555.652 -132.558)"
            fill="#3a41d9"
          />
          <path
            id="Path_4738"
            data-name="Path 4738"
            d="M587.385,203.043c1.436,1.3,3.489,1.782,5.326,2.68a1.332,1.332,0,0,1,.643,2.062,4,4,0,0,0-.465,3.007c.3,1.469.622,2.933.929,4.4a3.273,3.273,0,0,1,.078.652q.007,4.684,0,9.369a1.631,1.631,0,0,1-1.844,1.781,1.578,1.578,0,0,1-1.407-1.346,6.128,6.128,0,0,1-.037-.839q0-7.928,0-15.855c0-1.46-.607-2.473-1.829-2.659a11.646,11.646,0,0,0-3.215,0,2.153,2.153,0,0,0-1.78,2.006,7.444,7.444,0,0,0-.047.9q0,7.778,0,15.555c0,.24,0,.481-.017.72a1.634,1.634,0,0,1-3.266-.079c-.025-1.1-.008-2.2-.009-3.3s.049-2.2-.01-3.3a27.007,27.007,0,0,1,.789-7.608,4.4,4.4,0,0,0-.5-3.573,1.5,1.5,0,0,1,.64-1.671c.947-.427,1.912-.818,2.848-1.268C585.256,204.16,586.284,203.607,587.385,203.043Z"
            transform="translate(-556.42 -166.614)"
            fill="#3a41d9"
          />
          <path
            id="Path_4739"
            data-name="Path 4739"
            d="M627.3,214.131c-.224,1.193-.479,2.568-.743,3.942q-1.006,5.242-2.018,10.484a2.427,2.427,0,1,1-4.769-.787q1.353-7.1,2.739-14.193a2.4,2.4,0,0,1,2.564-2.038A2.47,2.47,0,0,1,627.3,214.131Z"
            transform="translate(-575.411 -170.713)"
            fill="#3a41d9"
          />
          <path
            id="Path_4740"
            data-name="Path 4740"
            d="M572.115,214.2c-.291,1.534-.617,3.263-.948,4.991q-.875,4.565-1.758,9.129a6.506,6.506,0,0,1-.215.872,2.358,2.358,0,0,1-2.82,1.542,2.322,2.322,0,0,1-1.8-2.659c.647-3.54,1.336-7.072,2.012-10.607.259-1.355.5-2.714.8-4.06a2.351,2.351,0,0,1,2.632-1.851A2.452,2.452,0,0,1,572.115,214.2Z"
            transform="translate(-548.753 -170.718)"
            fill="#3a41d9"
          />
          <path
            id="Path_4741"
            data-name="Path 4741"
            d="M612.968,230.8a2.4,2.4,0,0,1-2.379-2.039q-.7-3.221-1.389-6.446c-.552-2.579-1.11-5.158-1.651-7.739a2.41,2.41,0,1,1,4.7-1.028c.462,2.087.932,4.174,1.317,6.277a5.094,5.094,0,0,1-.16,1.761c-.357,1.946-.746,3.886-1.12,5.829A4.266,4.266,0,0,0,612.968,230.8Z"
            transform="translate(-569.505 -170.719)"
            fill="#3a41d9"
          />
          <path
            id="Path_4742"
            data-name="Path 4742"
            d="M557.776,230.751a2.331,2.331,0,0,1-2.323-1.9c-.617-2.952-1.265-5.9-1.9-8.846q-.574-2.665-1.142-5.332a2.433,2.433,0,0,1,1.753-3.072,2.408,2.408,0,0,1,2.953,2.013c.46,2.067.947,4.13,1.305,6.214a6.386,6.386,0,0,1-.233,2.121q-.484,2.653-1.028,5.294A4.435,4.435,0,0,0,557.776,230.751Z"
            transform="translate(-542.86 -170.716)"
            fill="#3a41d9"
          />
          <path
            id="Path_4743"
            data-name="Path 4743"
            d="M617.063,166.144a4.51,4.51,0,0,1-.024-9.02,4.51,4.51,0,1,1,.024,9.02Z"
            transform="translate(-571.952 -144.427)"
            fill="#3a41d9"
          />
          <path
            id="Path_4744"
            data-name="Path 4744"
            d="M561.828,166.141a4.508,4.508,0,0,1,.132-9.014,4.508,4.508,0,1,1-.132,9.014Z"
            transform="translate(-545.301 -144.428)"
            fill="#3a41d9"
          />
          <path
            id="Path_4745"
            data-name="Path 4745"
            d="M619.822,207.415l1.416,1.816c-1.229,1.254-1.065,3.045-1.583,4.8-.2-.925-.332-1.719-.556-2.485s-.545-1.52-.828-2.291Z"
            transform="translate(-574.716 -168.726)"
            fill="#3a41d9"
          />
          <path
            id="Path_4746"
            data-name="Path 4746"
            d="M564.564,207.455l1.42,1.784c-1.214,1.248-1.064,3.038-1.572,4.731-.531-1.636-.433-3.345-1.5-4.583Z"
            transform="translate(-547.97 -168.746)"
            fill="#3a41d9"
          />
        </g>
      </g>
    </svg>
  </span>
  <div className="media-body">
    <p className="mb-2">
    Pourcentage des personnes qui ayant attendu plus de
      <span style={{ color: "#3841E2" }}>
         5 min
      </span>
    </p>
    <h3 className="mb-0">
     {places?.maxWaitingTime?.waitingTimeRate?.toFixed(2) ?? 0}%<sup></sup>
    </h3>
    {/* <span className='badge badge-primary'>+3.5%</span> */}
  </div>
</div></Link>
</>
    )
}