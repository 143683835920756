import React, { useState } from 'react'
/// React router dom
import {Switch, Route } from 'react-router-dom'
/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

/// Dashboard
import Home from "./components/Dashboard/Home";
import AgenceForm from './components/Dashboard/AgenceForm'
import AdvertisingForm from './components/Dashboard/AdvertisingForm'

/// Pages
import Login from './pages/Login'
import AgenceTvScreen from './pages/PlaceTvInfo'
import Error404 from './pages/Error404'

//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';
import ProgramForm from './components/Dashboard/ProgramForm'
import AgenceTable from './components/tables/AgenceTable'
import AdvertisingTable from './components/tables/AdvertisingTable'
import ProgramTable from './components/tables/ProgramTable'
import AdvertisingTvScreen from './pages/AdvertisingTvScreen'
import NewsForm from './components/Dashboard/NewsForm'
import NewsTable from './components/tables/NewsTable'
import ScreenFormAds from './components/Dashboard/ScreenForm'
import ScreenAdsTable from './components/tables/ScreenAdsTable'
import AgenceFormUpdate from './components/Dashboard/AgenceFormUpdate'
import NewsFormUpdate from './components/Dashboard/NewsFormUpdate'
import ProgramFormUpdate from './components/Dashboard/ProgramFormUpdate'
import AdvertisingFormUpdate from './components/Dashboard/AdvertisingFormUpdate'
import ScreenFormUpdate from './components/Dashboard/ScreenFormUpdate'
import AgenceTvScreen2 from './pages/RtrtDashboard'


const Markup = () => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
    { url: '', component: Home },
    { url: 'dashboard', component: Home },
    { url: 'agence-form', component: AgenceForm },
    { url: 'news-form', component: NewsForm },
    { url: 'screen-ads-form', component: ScreenFormAds },
    { url: 'advertising-form', component: AdvertisingForm },
    { url: 'program-form', component: ProgramForm },
    { url: 'agence-table', component: AgenceTable },
    { url: 'news-table', component: NewsTable },
    { url: 'advertising-table', component: AdvertisingTable },
    { url: 'screen-ads-table', component: ScreenAdsTable },
    { url: 'program-table', component: ProgramTable },
    { url: ':id/page-tv-screen', component: AgenceTvScreen },
    { url: ':programId/:advertismentId/:agenceId/:uniqueId/page-advertising-tv-screen', component: AdvertisingTvScreen },
    { url: 'page-login', component: Login },
    { url: 'page-error-404', component: Error404 },
    //Updates
    { url: 'agence-form/:id', component: AgenceFormUpdate },
    { url: 'news-form/:id', component: NewsFormUpdate },
    { url: 'program-form/:id', component: ProgramFormUpdate },
    { url: 'advertising-form/:id', component: AdvertisingFormUpdate },
    { url: 'screen-ads-form/:id', component: ScreenFormUpdate },

  ]

  return (
       <> 
          <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show' : 'mh100vh'}`}
          >
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />
            )}
            <div
              className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
                !pagePath ? 'content-body' : ''
              }`}
            >
              <div
                className={`${!pagePath ? 'container-fluid' : ''}`}
                // style={{ minHeight: window.screen.height - 60 }}
              >
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </Switch>
              </div>
            </div>
            {!pagePath && <Footer />}
          </div>
         <ScrollToTop />
       </>
  )
}

export default Markup
