import { Row } from "reactstrap";
import { usePlacesStore } from "../../../../zustand-store/store";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import { useState } from "react";

const randomColor = () => {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
};
export const Scoring = () => {
  const [filter, setFilter] = useState("CAISSE");

  const placesStore = usePlacesStore();

  return (
    <Swiper
      pagination={true}
      modules={[Pagination, Autoplay]}
      spaceBetween={30}
      className="pb-2"
      centeredSlides={true}
      autoplay={{
        delay: 1500,
        pauseOnMouseEnter: true,
      }}
    >
      {placesStore?.stations.length > 0 &&
        placesStore?.stations.map((places) =>
          places
            ?.filter((place) => place?.type === filter)
            .map((place, index) =>
              place?.stationValue?.length > 0 ? (
                <SwiperSlide key={index}>
                  <Row>
                    <div className="col-lg-12 py-0">
                      <div className="card">
                        <div className="card-header border-0 pb-2 pt-2">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 20,
                            }}
                          >
                            <h2 className="card-title">
                              Scoring des ressources humaines par{" "}
                              {filter != "CAISSE" && "bureau du "}{" "}
                              {filter.toLowerCase()}
                              <br />
                              <span style={{ fontSize: 14 }}>
                                {" "}
                                Nombre de clients / Temps total pris
                              </span>
                            </h2>
                            <div
                              style={{
                                width: "40px",
                                overflow: "hidden",
                              }}
                            >
                              <select
                                name="filter"
                                style={{
                                  width: "20px",
                                  border: "none",
                                  outline: "none",
                                  "-webkit-appearance": "menulist-button",
                                  cursor: "pointer",
                                }}
                                onChange={(e) => setFilter(e.target.value)}
                              >
                                {places.map((item, i) => (
                                  <option key={i} value={item.type}>
                                    {item.type}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <p style={{ fontSize: 22 }}>{place.name}</p>
                        </div>
                        <Link
                          to={`overview/scoring/${place.name}`}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="card-body pb-0">
                            <Row>
                              {place?.stationRate &&
                                place?.stationScore &&
                                place.stationScore.map((item, i) => (
                                  <div className="col-md-3" key={i}>
                                    <div
                                      className="gauge"
                                      style={{
                                        width: "150px",
                                        "--rotation": `${
                                          (item / 180) * 180
                                        }deg`,
                                        "--color": `${randomColor()}`,
                                        "--background": "#e9ecef",
                                      }}
                                    >
                                      <div className="percentage"></div>
                                      <div className="mask"></div>
                                      <span className="value">
                                        {item.toFixed(2)}
                                      </span>
                                    </div>
                                    <h6 className="text-center mt-2">
                                      <span
                                        style={{
                                          fontSize: 22,
                                          color: "#00BF98",
                                          marginRight: 5,
                                        }}
                                      >
                                        &#x29BF;
                                      </span>
                                      {place.stationName[i]}
                                    </h6>
                                  </div>
                                ))}
                              <div className="col-md-3 border-left text-center pt-4">
                                <h3 style={{ fontSize: 36, fontWeight: 500 }}>
                                  {place?.stationValue?.reduce(
                                    (a, b) => a + b,
                                    0
                                  )}
                                </h3>
                                <p style={{ fontSize: 14 }}>
                                  Nombre total de clients
                                </p>
                              </div>
                            </Row>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </Row>
                </SwiperSlide>
              ) : (
                <SwiperSlide key={index}>
                  <Row>
                    <div className="col-lg-12 py-0">
                      <div className="card" style={{ cursor: "pointer" }}>
                        <div className="card-header border-0 pb-2 pt-2">
                          <h2 className="card-title">
                            Évaluation des ressources humaines {place.name}
                          </h2>
                          <p style={{ fontSize: 14 }}>
                            Nombre de clients / Temps total pris
                          </p>
                        </div>

                        <div className="card-body pb-0">
                          <Row>
                            <>
                              <div className="col-md-3">
                                <div
                                  className="gauge"
                                  style={{
                                    width: "150px",
                                    "--rotation": `${(0 / 180) * 100}deg`,
                                    "--color": `${randomColor()}`,
                                    "--background": "#e9ecef",
                                  }}
                                >
                                  <div className="percentage"></div>
                                  <div className="mask"></div>
                                  <span className="value">{0}</span>
                                </div>
                                <h6 className="text-center mt-2">
                                  <span
                                    style={{
                                      fontSize: 22,
                                      color: "#00BF98",
                                      marginRight: 5,
                                    }}
                                  >
                                    &#x29BF;
                                  </span>
                                  Stations 1
                                </h6>
                              </div>
                              <div className="col-md-3">
                                <div
                                  className="gauge"
                                  style={{
                                    width: "150px",
                                    "--rotation": `${(0 / 180) * 100}deg`,
                                    "--color": `${randomColor()}`,
                                    "--background": "#e9ecef",
                                  }}
                                >
                                  <div className="percentage"></div>
                                  <div className="mask"></div>
                                  <span className="value">{0}</span>
                                </div>
                                <h6 className="text-center mt-2">
                                  <span
                                    style={{
                                      fontSize: 22,
                                      color: "#00BF98",
                                      marginRight: 5,
                                    }}
                                  >
                                    &#x29BF;
                                  </span>
                                  Stations 2
                                </h6>
                              </div>
                              <div className="col-md-3">
                                <div
                                  className="gauge"
                                  style={{
                                    width: "150px",
                                    "--rotation": `${(0 / 180) * 100}deg`,
                                    "--color": `${randomColor()}`,
                                    "--background": "#e9ecef",
                                  }}
                                >
                                  <div className="percentage"></div>
                                  <div className="mask"></div>
                                  <span className="value">{0}</span>
                                </div>
                                <h6 className="text-center mt-2">
                                  <span
                                    style={{
                                      fontSize: 22,
                                      color: "#00BF98",
                                      marginRight: 5,
                                    }}
                                  >
                                    &#x29BF;
                                  </span>
                                  Stations 3
                                </h6>
                              </div>
                            </>

                            <div className="col-md-3 border-left">
                              <h3 style={{ fontSize: 36, fontWeight: 500 }}>
                                {"0"}
                              </h3>
                              <p style={{ fontSize: 14 }}>
                                {/* Nombre total de clients */}
                                Pas de données disponibles
                              </p>
                            </div>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Row>
                </SwiperSlide>
              )
            )
        )}
    </Swiper>
  );
};
