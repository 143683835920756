import React, { Fragment, useState,useEffect,useId } from "react";
import { Button } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import { db } from '../../../Firebase';
import { collection,doc, updateDoc,query,onSnapshot } from 'firebase/firestore';
import Select from "react-select";
import { setCulture } from "@syncfusion/ej2-base";
import { extractQuerystring } from "@firebase/util";
import { v4 as uuid } from 'uuid';
import {useHistory,useParams } from "react-router-dom";

const ScreenFormUpdate = () => {
   const history = useHistory()
   const [url,setUrl]=useState("")
   const [title,setTitle]=useState("")
   const [selectedProgram, setSelectedProgram] = useState(null);
   const [selectedAgence, setSelectedAgence] = useState(null);
   const [programs, setPrograms] = useState(null);
   const [agences, setAgences] = useState(null);
   const [active,setActive] = useState(false)
   const [uniqueId,setUniqueId] = useState(uuid())
   let { id } = useParams();
   useEffect(() => {
      const q = query(collection(db, "adsScreen"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let todosArray = [];
        querySnapshot.forEach((doc) => {
          todosArray.push({ ...doc.data(), id: doc.id });
        });
        const adsScreen = todosArray.find(item=> item.id === id );
        setSelectedAgence(adsScreen.selectedAgence);
        setSelectedProgram(adsScreen.selectedProgram)
        setTitle(adsScreen.title)
        setActive(adsScreen.active)
      });
      return () => unsub();
    }, []);

   useEffect(() => {
      const q = query(collection(db, "program"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let todosArray = [];
        querySnapshot.forEach((doc) => {
          todosArray.push({ ...doc.data(), id: doc.id });
        });
        setPrograms(todosArray.map(item=> {return {value:item.id,label:item.title,advertising:item.selectedPub.value}}));
      });
      return () => unsub();
    }, []);

    useEffect(() => {
      const q = query(collection(db, "Agences_v1"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let todosArray = [];
        querySnapshot.forEach((doc) => {
          todosArray.push({ ...doc.data(), id: doc.id });
        });
        setAgences(todosArray.map(item=> {return {value:item.id,label:item.name}}));
      });
      return () => unsub();
    }, []);
  const generateUrl = () =>{
   const url = `${window.location.protocol}//${window.location.host}/apptv/${selectedAgence?.label}/${uniqueId}/p-page-advertising-tv-screen`
   //(uniqueId)
   setUrl(url)
  }

  const handleSubmit = async (e) => {
   e.preventDefault();
   const adsScreen = doc(db, 'adsScreen', id)
   await updateDoc(adsScreen, {
      selectedAgence,
      selectedProgram,
      title,
      url,
      active,
      uniqueId:uniqueId
    })
       history.push('/screen-ads-table');
}
   return (
      <Fragment>
         <PageTitle activeMenu="Ads" motherMenu="Form" />

         <div className="row">
            <div className="col-lg-12">
               <div className="card">
                  <div className="card-header">
                     <h4 className="card-title">Nouveau Écran TV</h4>
                  </div>
                  <div className="card-body">
                     <div className="form-validation">
                        <form
                           className="form-valide"
                        >
                           <div className="row">
                              <div className="col-xl-12">
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Titre
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                       <input
                                          value={title}
                                          type="text"
                                          className="form-control"
                                          id="val-username"
                                          name="val-username"
                                          placeholder="Entrez un titre.."
                                          onChange={(e) => setTitle(e.target.value)}
                                          
                                       />
                                    </div>
                                 </div>
                                 
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Programme
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                    <Select
                                          defaultValue={selectedProgram}
                                          onChange={setSelectedProgram}
                                          options={programs}
                                          value={selectedProgram}
                                          isMulti
                                          style={{
                                             lineHeight: "40px",
                                             color: "#7e7e7e",
                                             paddingLeft: " 15px",
                                          }}
                                       />
                                    </div>
                                 </div>
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Agences
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                    <Select
                                          defaultValue={selectedAgence}
                                          onChange={setSelectedAgence}
                                          options={agences}
                                          value={selectedAgence}
                                          style={{
                                             lineHeight: "40px",
                                             color: "#7e7e7e",
                                             paddingLeft: " 15px",
                                          }}
                                       />
                                    </div>
                                 </div>
                                 <div className="form-group row align-items-center">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                      URL
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-4">
                                       <input
                                          value={url}
                                          type="numeric"
                                          className="form-control"
                                          id="val-username"
                                          name="val-username"
                                         
                                       />
                                    </div>
                                    <div className="col-lg-2">
                                       <Button onClick={generateUrl} variant="info light">
                                       Générer une URL{" "}
                                       </Button>
                                    </div>
                                 </div>
                                 
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-suggestions"
                                    >
                                       Active{" "}
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                    <div className="custom-control custom-checkbox">
                                                <input
                                                   type="checkbox"
                                                   className="custom-control-input"
                                                   id="gridCheck"
                                                   onChange={(e) => setActive(!active)}
                                                   checked={active}
                                                />
                                                <label
                                                   className="custom-control-label"
                                                   htmlFor="gridCheck"
                                                >
                                                   Oui / Non
                                                </label>
                                             </div>
                                    </div>
                                 </div>
                                 <div className="form-group row">
                                    <div className="col-lg-4 "></div>
                                    <div className="col-lg-6">
                                       <Button variant="primary" onClick={handleSubmit} disabled={url===""}>
                                       Sauvegarder{" "}
                                       </Button>
                                    </div>
                                 </div>
                              </div>
                              
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
           
         </div>
      </Fragment>
   );
};

export default ScreenFormUpdate;
