import { create } from 'zustand'
import axios from 'axios'
const SPORT = ["Fitness Space", "Cardio Space", "Swimming pool"];

const ALLSPACES = ["Fitness Space", "Cardio Space", "Swimming pool","EMINES Cafeteria","CCI Cafeteria","Campus Restaurant","Modular Restaurant","CCI Restaurant"]
export const useNoWaitingStore = create((set) => ({
    occupation:Array(SPORT.length).fill(0),
    endtime :Array(SPORT.length).fill(""),
    startime : Array(SPORT.length).fill(""),
    timeStart :Array(SPORT.length).fill(false),
    time :Array(SPORT.length).fill(""),
    queue :Array(SPORT.length).fill(0),
    code : Array(SPORT.length).fill(""),
    qr_code : Array(ALLSPACES.length).fill(""),
    name : Array(SPORT.length).fill(""),
    setOccupation: (occupation) => set({occupation:occupation}),
    setTimeStart: async(props) => {
        const getdata = await axios.get(`http://204.48.16.65:8888/api/places/${props.id}`)
        const data = getdata.data;
        const startafter30 = new Date(data.session?.start_time)
        startafter30.setMinutes(startafter30.getMinutes() + 20);
        var diff = new Date() - startafter30;
        const m = Math.floor((diff/1000)/60)
        // console.log(`diff m after 30 m ${m}`) 
        set((state) => {
            const pos = SPORT.indexOf(props.id);
          
            const qrpos = ALLSPACES.indexOf(props.id);
            if (pos !== -1) {
              const newqueue = [...state.queue];
              const newcode = [...state.code];
              const newendtime = [...state.endtime];
              const newStart = [...state.timeStart];
              const newstarttime = [...state.startime];
              const newqr_code = [...state.qr_code];
              const newName = [...state.name];

              newName[pos] = props.id;
              newqr_code[qrpos] = data.qr_code
              newstarttime[pos] = data.session?.start_time;
              newendtime[pos] = data.session?.end_time;
              newcode[pos] = data.code;
              newqueue[pos] = data.queue;
              newStart[pos] = m < 0 ? true : false;
      
              return {
                timeStart: newStart,
                queue: newqueue,
                endtime: newendtime,
                startime: newstarttime,
                code: newcode,
                qr_code: newqr_code,
                name: newName,
              };
            }
            return state;
          });
        },
}))