import QRCode from 'react-qr-code';
import { useNoWaitingStore } from '../noWatingStore/store';
const ALLSPACES = ["Fitness Space", "Cardio Space", "Swimming pool","EMINES Cafeteria","CCI Cafeteria","Campus Restaurant","Modular Restaurant","CCI Restaurant"]


export const Qr = (props) => {
    const noWaitingStore = useNoWaitingStore();
    var pos = ALLSPACES.indexOf(props.code);
    if (pos == -1) pos = 0;
    const qrcode = noWaitingStore.qr_code[pos];
    // console.log(props)
    // console.log(qrcode)
    return (
        <div  style={{ width:"40px"}}>
            <QRCode value={qrcode}  size={128} bgColor='transparent'/>
        </div>
    )

}