import React, {  useLayoutEffect, useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useChartStore } from "../../../../zustand-store/chartStore";

am4core.useTheme(am4themes_animated);
const convertedDataValues = (data) => {
  return data.map((item) => {
    return {
      date: new Date(item.date),
      station: item.station,
      scoring: item.scoring,
    };
  })
}
function ChartKPIs2(props) {
  const chartStore = useChartStore();
  const [showData , setShowData] = useState("");
  const [Title , setTitle] = useState("");
  const [data , setData] = useState([])

  useEffect(() => {
    setData(convertedDataValues(props.data.values))
  },[props.data])
    

  useLayoutEffect(() => {
    let chart = am4core.create(props.data.station, am4charts.XYChart);
    chart.paddingRight = 20;
    
    chart.data = data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    dateAxis.tooltipDateFormat = "HH:mm, d MMMM";

    dateAxis.zoomToDates(chartStore.startDate, chartStore.endDate);


    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.title.text = `${Title}`;
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "scoring";
    series.tooltipText =  `{valueY.value} ${showData}`;

    series.fillOpacity = 0.3;
    // dateAxis.renderer.labels.template.adapter.add("text", function(text, target) {
    //   if (dateAxis.axisRanges.length > 0) {
    //     let startDate = dateAxis.axisRanges.getIndex(0).date;
    //     let endDate = dateAxis.axisRanges.getIndex(dateAxis.axisRanges.length - 1).endDate;
    //     if (endDate - startDate < 60 * 60 * 1000) {
    //       // If the range is less than 1 hour, format as "HH:00 - HH+1:00"
    //       return am4core.time.format(startDate, "HH:00") + " - " + am4core.time.format(endDate, "HH:00");
    //     }
    //   }
    //   // Default format: "HH:mm"
    //   return am4core.time.format(target.dataItem.date, "HH:mm");
    // });
    
    
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;
    chart.scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX.series.push(series);
  
   
    dateAxis.start = 10000;
    dateAxis.keepSelection = true;
    // dateAxis.renderer.labels.template.adapter.add("text", (text, target) => {
    //   console.log(dateAxis.zoomLevel)
    //   if (dateAxis.zoomLevel >= 20) {
    //     const startDate = new Date(target.dataItem.dates.date);
    //     const endDate = new Date(startDate);
    //     endDate.setHours(endDate.getHours() + 1); // Assuming 1-hour intervals
    //     const dateFormatter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric' });
    //     return `${dateFormatter.format(startDate)} - ${dateFormatter.format(endDate)}`;
    //   } else {
    //     const dateFormatter = new Intl.DateTimeFormat('en-US', {  day: 'numeric', month: 'long' });
    //     return dateFormatter.format(target.dataItem.dates.date);
    //   }
    // });
  // 
    return () => {
      chart.dispose(); 
    };
  }, [data]);

  return <div id={props.data.station} style={{ width: "100%", height: "410px" }}></div>;
}
export default ChartKPIs2;
