import React, { Fragment,useState,useEffect } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
   Row,
   Col,
   Card,
   Table,
   Button
} from "react-bootstrap";
import {
   collection,
   query,
   onSnapshot,
   doc,
   updateDoc,
   deleteDoc,
 } from "firebase/firestore";
 import {db} from '../../../Firebase';

/// imge
import { Link } from "react-router-dom";
import moment from "moment";

const AdvertisingTable = () => {
   const [advertisements,setAdvertisements] = useState([]);

   useEffect(() => {
      const q = query(collection(db, "advertisment"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let todosArray = [];
        querySnapshot.forEach((doc) => {
          todosArray.push({ ...doc.data(), id: doc.id });
        });
        setAdvertisements(todosArray);
      });
      return () => unsub();
    }, []);
    const handleDelete = async (id) => {
      await deleteDoc(doc(db, "advertisment", id)); 
   }
   return (
      <Fragment>
         <Row className="justify-content-between align-items-center">
         <PageTitle activeMenu="Publicité" motherMenu="Liste Publicité" />
         <Link to="/advertising-form" className="ai-icon">
            <Button variant="primary">
            Nouvelle publicité{" "}
               <span className="btn-icon-right">
                  <i className="fa fa-plus-circle" />
               </span>
            </Button>
         </Link>
         </Row>
         <Row>
            <Col lg={12}>
               <Card>
                  <Card.Header>
                     <Card.Title>Liste Publicité</Card.Title>
                  </Card.Header>
                  <Card.Body>
                     <Table responsive>
                        <thead>
                           <tr>
                              <th className="width50">
                                 <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                    <input
                                       type="checkbox"
                                       className="custom-control-input"
                                       id="checkAll"
                                       required=""
                                    />
                                    <label
                                       className="custom-control-label"
                                       htmlFor="checkAll"
                                    ></label>
                                 </div>
                              </th>
                              <th>
                                 <strong>NO.</strong>
                              </th>
                              <th>
                                 <strong>Titre de la publicité</strong>
                              </th>
                              <th>
                                 <strong>Type de publicité</strong>
                              </th>
                              <th>
                                 <strong>Date</strong>
                              </th>
                              <th>
                                 <strong>Statut</strong>
                              </th>
                           </tr>
                        </thead>
                        <tbody>
                           {advertisements && advertisements.map((item)=>
                             <tr>
                             <td>
                                <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                   <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id="customCheckBox2"
                                      required=""
                                   />
                                   <label
                                      className="custom-control-label"
                                      htmlFor="customCheckBox2"
                                   ></label>
                                </div>
                             </td>
                             <td>
                                <strong>{item.id}</strong>
                             </td>
                             <td>
                                <div className="d-flex align-items-center">
                                   
                                   <span className="w-space-no">
                                     {item.title}
                                   </span>
                                </div>
                             </td>
                             <td>  {item.type} </td>
                             <td>  {moment(item.date).format('DD-MM-YYYY')}</td>
                             <td>
                                <div className="d-flex align-items-center">
                                   <i className="fa fa-circle text-success mr-1"></i>{" "}
                                   Successful
                                </div>
                             </td>
                             <td>
                             <div className="d-flex">
                                  <a
                                     href={"advertising-form/"+item.id}
                                     className="btn btn-danger shadow btn-xs sharp"
                                     target="_blank"
                                  >
                                     <i className="fa fa-pencil"></i>
                                  </a>
                                   
                               </div>
                                <div className="d-flex">
                                  
                                   <Button
                                     onClick={() => handleDelete(item.id)}
                                      href="#"
                                      className="btn btn-danger shadow btn-xs sharp"
                                   >
                                      <i className="fa fa-trash"></i>
                                   </Button>
                                </div>
                             </td>
                          </tr>
                           )}
                         
                        </tbody>
                     </Table>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
      </Fragment>
   );
};

export default AdvertisingTable;
