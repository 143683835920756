
const agencyData = {
   data: [
      [
         "Completed",
         [[" Agence Abdelmoumen"], [" agency@example.com"]],
         "20/04/2020",
         "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149",
         "Completed",
         "Preview",
         "",
      ],
      [
         "Canceled",
         [[" Agence Abdelmoumen"], [" agency@example.com"]],
         "20/04/2020",
         "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149",
         "Canceled",
         "Preview",
         "",
      ],
      [
         "",
         [[" Agence Abdelmoumen"], [" agency@example.com"]],
         "20/04/2020",
         "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149",
         "Canceled",
         "Preview",
         "",
      ],
      [
         "Pending",
         [[" Agence Abdelmoumen"], [" agency@example.com"]],
         "20/04/2020",
         "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149",
         "Pending",
         "Preview",
         "",
      ],
      [
         "Completed",
         [[" Agence Abdelmoumen"], [" agency@example.com"]],
         "20/04/2020",
         "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149",
         "Completed",
         "Preview",
         "",
      ],
   ],
   columns: ["Nom ", "Action"],
};

const data = {
   agencyData
};

export default data;
