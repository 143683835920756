import React, { useEffect, useState } from 'react';
import { useSound } from '../noWatingStore/sound';
import moment from 'moment';
import './cownt.css';
export const CowntDown = (props) => {
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const [showSemicolon, setShowSemicolon] = useState(false);
	const soundStore = useSound();
	useEffect(() => {
		const daten = new Date(props.time)
		daten.setMinutes(daten.getMinutes() + 20);
		setInterval(() => {
			const now = moment();
			const then = moment(daten, 'YYYY-MM-DD hh:mm:ss');
			const duration = moment.duration(then - now);
			const hours = Math.floor(duration.asHours());
			const minutes = duration.minutes();
			const seconds = duration.seconds();
			setHours(hours);
			setMinutes(minutes);
			setSeconds(seconds);
		}, 1000);
	}, []);
	useEffect(() => {
		console.log(hours, minutes, seconds);			

		soundStore.setMinutes(minutes);
	}, [minutes]);
	return (
		<div className="app">
		
		<div className="timer-container">
		
			{showSemicolon ? <div className="semicolon">:</div> : null}
			<div className="timer text-white">
				{hours}
				<span>hour</span>
			</div>
			{showSemicolon ? <div className="semicolon">:</div> : null}
			<div className="timer text-white">
				{minutes}
				<span>minute</span>
			</div>
			{showSemicolon ? <div className="semicolon">:</div> : null}
			<div className="timer text-white">
				{seconds}
				<span>second</span>
			</div>
		</div>
	</div>
	);
}

