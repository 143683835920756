import { realTimeDb } from "../../../../Firebase";
import { Col, Card } from "react-bootstrap";
import { useState, useEffect } from "react";
import { onValue, ref } from "firebase/database";
import { useNoWaitingStore } from '../noWatingStore/store'
import iconfille from "../../../../images/iconfille.svg";
import style from "../assets/style.css";
const SPORT = ["Fitness Space", "Cardio Space", "Swimming pool"];

export const Occupation = (props) => {
  const [agence, setAgence] = useState(null);
  const noWatingStore = useNoWaitingStore();
  const [ocupationColor, setOcupationColor] = useState();
  useEffect(() => {
    if (agence) {
      const percentage =
        ((agence.Queue + agence.Sitting) / agence.Capacity) * 100;
      if (percentage <= 50) setOcupationColor("bg_green bg-gradient");
      if (percentage > 50 && percentage <= 80) setOcupationColor("bg-warning");
      if (percentage > 80) setOcupationColor("bg_red");
    }
  }, [agence?.Queue, agence?.Sitting]);
  useEffect(() => {
    const query = ref(realTimeDb, "Places");
    onValue(query, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const allplaces = Object.values(data);
        const allnew = allplaces[0]
        console.log(allnew)
        const a = allnew.find((item) => item.Name == props.id)
        setAgence(a);
        console.log(a)
        console.log(`a is ${a.Name}`)
        const pos = SPORT.indexOf(a?.Name);
        console.log(pos)
      }
    });
  }, []);
  return (
    <Col key={props.id} lg={props.x} md={props.y}>
      <Card className={`widget-stat ${ocupationColor}`}>
        <Card.Body className="py-4">
          <div className="media ai-icon align-items-end">
            <img className="logo-abbr" src={iconfille} alt="" width={"220"} />
            <div className="media-body text-right font-tv">
              <h5 className="text-white">{agence && agence.Name}</h5>
              {agence?.Capacity >= 25 && (
                <>
                  <h4 className="mb-0 text-white">
                    {agence?.Queue} {" "}
                    <sup style={{ fontSize: 30 }}>Queue</sup>
                  </h4>
                  <p className="mb-1 text-white"></p>
                </>
              )}
              {agence?.Capacity < 25 && (
                    <>
                  <h4 className="mb-0 text-white">
                    {agence?.Sitting + agence?.Queue} {" "}
                    <sup style={{ fontSize: 30 }}>Occupation</sup>
                  </h4>
                  <p className="mb-1 text-white"></p>
                </>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};
