import {
    Row,
    Col,
    Card,
 } from "react-bootstrap";
 import { useNoWaitingStore } from "../noWatingStore/store";
 const SPORT = ["Fitness Space", "Cardio Space", "Swimming pool"];

const WeatherCard = (props) => {
    
    const noWatingStore = useNoWaitingStore();

    const getWeatherIcon = (iconParameter) => {
        const icon = `https://${iconParameter}`
        return <img src = {icon} alt = "" height={130} />
    }
    // console.log(props)
    // console.log("weart")
    var pos = SPORT.indexOf(props.id);
    // conso
    // if (pos == -1) pos = 0;
    // console.log(props)
    // console.log(image)
    
    return (<>
        <Card className="widget-stat col-md-12">
            <Card.Body className="py-2" style={{backgroundImage:`url()`,backgroundSize:"contain",backgroundRepeat:"no-repeat"}}>
            <Row lg={4} >
                <Col lg={4} >
                <div className="box-agence text-agence pt-4">
                <h4 className="mb-0">{props.weatherResult.location.name}</h4>
                    <div className="rounded-circle shadow-sm">
                    {getWeatherIcon(props.weatherResult.current.condition.icon)}
                    </div>
                </div>
                </Col>
                <Col lg={4} className="d-flex flex-column justify-content-around align-items-center align-items-center">
              
                     {/* { pos !== -1 && !noWatingStore.timeStart[pos] && 
                        <>
                            <h5 className="text-center">No wating code</h5>
                            <h2 className="">{noWatingStore.code[pos]}</h2>
                        </>
                    }  */}
                </Col>
                <Col lg={4}>
                <div className="box-agence text-agence border-left pt-2">
                    <p className="">Ressemble à</p>
                    <h2 className="">{props.weatherResult.current.temp_c}&deg;C</h2>
                    
                    <p className="">Humidité</p>
                    <h2 className="">{props.weatherResult.current.humidity}%</h2>
                </div>
                </Col>
            </Row>
            </Card.Body>
        </Card>
        </>
    )
}

export default WeatherCard;