import React, { Fragment, useState ,useEffect} from "react";
import { Button } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import { DatePicker } from "@y0c/react-datepicker";
import "../../../index.css";
import { db } from '../../../Firebase';
import { collection, addDoc} from 'firebase/firestore';
import {
   ref,
   uploadBytes,
   getDownloadURL,
   deleteObject
 } from "firebase/storage";
 import {useHistory } from "react-router-dom";
 import { storage } from  '../../../Firebase';
const AdvertisingForm = () => {
   const currentDate = new Date()
   const [title,setTitle] = useState("");
   const [type,setType] = useState("");
   const [file,setFile] = useState("");
   const [date,setDate] = useState(new Date(currentDate).toString());
   const [imagesToUpload, setImagesToUpload] = useState([]);
   const [loading,setLoading] = useState(false);
   const [time, setTime] = useState(null);
   const [imagesUrls,setImageUrls]=useState([])
   const [uploadLoading,setUploadLoading] = useState(false)

   const history = useHistory()

   const deleteImageFromUpload = (url) => {
      deleteObject(ref(storage, url));
      setImageUrls(imagesUrls.filter((item)=> item !== url))
   }
   useEffect(async() => {
      setUploadLoading(true);
      for (const element of imagesToUpload) {
         const imageRef = ref(storage, `advertisementMedia/${element.name}`);
         const snapshot = await uploadBytes(imageRef, element)
         const   url = await getDownloadURL(snapshot.ref)
         setImageUrls((prev) => [...prev, url]);
         }
      setUploadLoading(false)  
    }, [imagesToUpload]);
 
   const handleSubmit = async (e) => {
      setLoading(true)
      e.preventDefault();
      /*let imagesUrl = [] 
      if (imagesToUpload.lenght < 1) return;
         //const imageRef = ref(storage, `testAdvertisment/${imageUpload.name}`);
         for (const element of imagesToUpload) {
            const imageRef = ref(storage, `advertisementMedia/${element.name}`);
            const snapshot = await uploadBytes(imageRef, element)
            const   url = await getDownloadURL(snapshot.ref)
            imagesUrl.push(url)
            } */
          await addDoc(collection(db, "advertisment"), {
              title,
              type,
              files:imagesUrls,
              date,
              time
          });
          setTitle("");
          setType("")
          setFile("")
          setDate(currentDate.toString())
          setLoading(false)
          setTime(null)
          setImagesToUpload([])
          setImageUrls([])
          history.push('/advertising-table');
  }


  
   return (
      <Fragment>
         <PageTitle activeMenu="Publicité" motherMenu="Form" />

         <div className="row">
            <div className="col-lg-12">
               <div className="card">
                  <div className="card-header">
                     <h4 className="card-title">Nouvelle publicité</h4>
                  </div>
                  <div className="card-body">
                     <div className="form-validation">
                        <form
                           className="form-valide"
                           action="#"
                           method="post"
                        >
                           <div className="row">
                              <div className="col-xl-12">
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Titre de la publicité
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                       <input
                                          value={title}
                                          type="text"
                                          className="form-control"
                                          id="val-username"
                                          name="val-username"
                                          placeholder="Entrez un nom.."
                                          onChange={(e) => setTitle(e.target.value)}
                                       />
                                    </div>
                                 </div>
                                 
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Type de publicité
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                       <input
                                          value={type}
                                          type="text"
                                          className="form-control"
                                          id="val-username"
                                          name="val-username"
                                          placeholder="Entrez le titre ..."
                                          onChange={(e) => setType(e.target.value)}
                                       />
                                    </div>
                                 </div>
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Télécharger le fichier
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                    <div className="custom-file">
                                 <input
                                    type="file"
                                    className="custom-file-input"
                                    multiple
                                    onChange={(event) => {
                                       setImagesToUpload([...event.target.files]);
                                     }}
                                 />
                                 {uploadLoading && <>Chargement en cours ...</>}
                                  {/* {
                                    imagesToUpload.map(a => a.name).join()
                                 }  */}
                                  {/* {imagesUrls && imagesUrls.map((url) => {
                                        return <img src={url} />;
                                    })}  */}
                                 <label className="custom-file-label">
                                 Choisir le fichier
                                 </label>
                                 </div>
                                 <div className="d-flex align-items-center">
                                 {imagesUrls && imagesUrls.map((url , i) => 
                                    <div key={i} role="alert" className="alert p-2 bg-light mx-2 my-2">
                                       <button ata-dismiss="alert" aria-label="Close" type="button" className="close btn" onClick={()=>deleteImageFromUpload(url)}>
                                          <span>
                                          <i className="mdi mdi-close"></i>
                                          </span>
                                       </button>
                                       <div className="media">
                                          <div className="media-body">
                                          <img src={url} width="64" alt="" />
                                          </div>
                                       </div>
                                    </div>
                                    )}
                                 </div>
                                    </div>
                                 </div>
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Temps
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                       <input
                                          value={time}
                                          type="number"
                                          className="form-control"
                                          id="val-username"
                                          name="val-username"
                                          placeholder="Temps.."
                                          onChange={(e) => setTime(e.target.value)}
                                       />
                                    </div>
                                 </div>
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Date de création
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                       <DatePicker   initialDate={new Date()} selected={date} onChange={(date) => setDate((new Date(date)).toString())}/>
                                    </div>
                                 </div>
                                 <div className="form-group row">
                                    <div className="col-lg-4 "></div>
                                    <div className="col-lg-6">
                                       <Button variant="primary" onClick={handleSubmit} disabled={loading}>
                                       Sauvegardez{" "}
                                       </Button>
                                    </div>
                                 </div>
                              </div>
                              
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
           
         </div>
      </Fragment>
   );
};

export default AdvertisingForm;
