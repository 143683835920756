import React, { useEffect, useState} from "react";
import data from "./tableAgency";
import { Table } from "react-bootstrap";
import { realTimeDb } from "../../../Firebase";
import { onValue, ref } from "firebase/database";
const AllAgency = () => {

   const [places, setPlaces] = useState([]);

   useEffect(() => {
     const fetchData = async () => {
       const query = await ref(realTimeDb, "Places");
       await onValue(query, (snapshot) => {
         if (snapshot.exists()) {
           const data = snapshot.val();
           const allplaces = Object.values(data);
           setPlaces(allplaces);
         }
       }, { onlyOnce: true });
     };
     fetchData();
   }, []);
 
   // Move // inside the useEffect to see the data after it's fetched
   useEffect(() => {
     //(places);
   }, [places]);
   return (
      <div className="h-80">
         <div className="row">
            <div className="col-lg-12">
               <div className="card">
                  <div className="card-body">
                     <Table responsive size="sm" className="table-responsive-xl mb-0">
                        <thead>
                           <tr>
                              
                              {data.agencyData.columns.map((p, i) => (
                                 <th className="align-middle" key={i}>
                                    <strong> {p}</strong>
                                 </th>
                              ))}
                              <th></th>
                           </tr>
                        </thead>
                        <tbody id="orders">
                           {places.map((p, i) => (
                              <>
                                    {p.map((item, j) => (
                                          <tr key={Math.random()}>
                                          <td className="fs-2 text fw-bold">{item.Name}</td>
                                          <td>
                                          <a className="btn btn-outline-secondary btn-primary" href={`/${item.Name}/p-page-tv-screen`} target="_blank" variant="outline-primary">
                                             <i className="flaticon-381-television"></i>  Aperçu
                                          </a>

                                          </td>
                                          </tr>
                                    ))}
                              </>
                           ))
                           }
                                <tr>
                                          <td className="fs-2 text fw-bold">No waiting display</td>
                                          <td>
                                          <a className="btn btn-outline-secondary btn-primary" href={`/NoWaiting/NoWaiting`} target="_blank" variant="outline-primary">
                                             <i className="flaticon-381-television"></i>  Aperçu
                                          </a>

                                          </td>
                                          </tr>
                        </tbody>
                     </Table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default AllAgency;
