import { useState, useEffect } from "react";
import { onValue, ref } from "firebase/database";
import { realTimeDb } from "../../../../Firebase";
import { Col, Card, Row } from "react-bootstrap";
export const ExtraCard = (props) => {
  const [place, setPlace] = useState(null);
  const [wating_time, setwating_time] = useState(0);
  const [color, setColor] = useState("bg_green bg-gradient");

  useEffect(() => {
    if (place) {
      let calctime = (place.Queue / place.Current_speed).toFixed(1);
      if (place.Queue === 0) {
        setwating_time(0);
      } else {
        calctime == Infinity ? setwating_time(0) : setwating_time(calctime);
      }
      const percentage = ((place.Queue + place.Sitting) / place.Capacity) * 100;
      if (percentage <= 50) setColor("bg_green bg-gradient");
      if (percentage > 50 && percentage <= 80) setColor("bg-warning");
      if (percentage > 80) setColor("bg_red");
    }
  }, [place?.Queue, place?.Sitting]);
  useEffect(() => {
    const query = ref(realTimeDb, "Places");
    onValue(query, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const allplaces = Object.values(data);
        const allnew = allplaces[0]
        setPlace(allnew.find((item) => item.Name == props.id));
        console.log(`place is ${allnew.find((item) => item.Name == props.id)}`)
      }
    });
  }, []);
  return (
    <Col lg={12} md={12}>
      <Card className={`widget-stat ${color}`}>
        <Card.Body className="py-3">
          <Row>
            <Col lg={12}>
              <div className="d-flex justify-content-between align-items-center border-bottom">
                <p className="text-white">{place?.Name}</p>
                <p
                  className="text-white"
                  style={{ fontSize: 20, fontWeight: 600, letterSpacing: 1 }}
                >
                  Live
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <div className="box-agence text-agence border-right pt-2">
                <h1 className="mb-0 text-white">
                  {place?.Sitting + place?.Queue}
                  <sup style={{ fontSize: 42 }}></sup>
                </h1>
                <p className="mb-1 text-white">Occupation</p>
              </div>
            </Col>
            <Col lg={4}>
              {place?.Capacity >= 25 &&
              <div className="box-agence text-agence pt-2">
                <h1>
                  {wating_time ? wating_time : 0}
                  <sup style={{ fontSize: 30 }}>min</sup>{" "}
                </h1>
                <p className="text-white">Waiting time</p>
              </div>
              }
              {place?.Capacity < 25 &&
              <div className="box-agence text-agence pt-2">
                <h1>
                  {wating_time ? wating_time : 0}
                  <sup style={{ fontSize: 30 }}></sup>{" "}
                </h1>
                <p className="text-white">No waiting Queue</p>
              </div>
              }
            </Col>
            <Col lg={4}>
              <div className="box-agence text-agence border-left pt-2">
                <h1>
                  {"<1"}
                  <sup style={{ fontSize: 30 }}>km</sup>
                </h1>
                <p className="text-white">Distance</p>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
};
