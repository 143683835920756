import React, { Fragment,useEffect,useState } from "react";
import PageTitle from "../../layouts/PageTitle";
import {
   Row,
   Col,
   Card,
   Table,
   Button
} from "react-bootstrap";
import {
   collection,
   query,
   onSnapshot,
   doc,
   updateDoc,
   deleteDoc,
 } from "firebase/firestore";
 import {db} from '../../../Firebase';
/// imge
import a1 from "../../../images/a1.jpg";
import { Link } from "react-router-dom";
import moment from "moment";


const NewsTable = () => {
   const [news,setNews] = useState([]);

   useEffect(() => {
      const q = query(collection(db, "news"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let todosArray = [];
        querySnapshot.forEach((doc) => {
          todosArray.push({ ...doc.data(), id: doc.id });
        });
        setNews(todosArray);
      });
      return () => unsub();
    }, []);

    const handleDelete = async (id) => {
      await deleteDoc(doc(db, "news", id)); 
   }
   return (
      <Fragment>
         <Row className="justify-content-between align-items-center">
         <PageTitle activeMenu="Actualités" motherMenu="Liste Actualités" />
         <Link to="/news-form" className="ai-icon">
            <Button variant="primary">
            Ajouter une actualité{" "}
               <span className="btn-icon-right">
                  <i className="fa fa-plus-circle" />
               </span>
            </Button>
         </Link>
         </Row>
         <Row>
            <Col lg={12}>
               <Card>
                  <Card.Header>
                     <Card.Title>Liste Actualités</Card.Title>
                  </Card.Header>
                  <Card.Body>
                     <Table responsive>
                        <thead>
                           <tr>
                              <th className="width50">
                                 <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                    <input
                                       type="checkbox"
                                       className="custom-control-input"
                                       id="checkAll"
                                       required=""
                                    />
                                    <label
                                       className="custom-control-label"
                                       htmlFor="checkAll"
                                    ></label>
                                 </div>
                              </th>
                              <th>
                                 <strong>NO.</strong>
                              </th>
                              <th>
                                 <strong>Titre</strong>
                              </th>
                              <th>
                                 <strong>Date</strong>
                              </th>
                              <th>
                                 <strong>Agence</strong>
                              </th>
                              <th>
                                 <strong>Statut</strong>
                              </th>
                           </tr>
                        </thead>
                        <tbody>
                           { news && news.map((item) => 
                            <tr>
                            <td>
                               <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                  <input
                                     type="checkbox"
                                     className="custom-control-input"
                                     id="customCheckBox2"
                                     required=""
                                  />
                                  <label
                                     className="custom-control-label"
                                     htmlFor="customCheckBox2"
                                  ></label>
                               </div>
                            </td>
                            <td>
                               <strong>{item?.id}</strong>
                            </td>
                            <td>
                               <div className="d-flex align-items-center">
                                 
                                  <span className="w-space-no">
                                  {item?.title}
                                  </span>
                               </div>
                            </td>
                            <td>{item?.date}</td>
                            <td>{item?.selectedAgence?.label}</td>
                            <td>
                               <div className="d-flex align-items-center">
                                  <i className="fa fa-circle text-success mr-1"></i>{" "}
                                  {item.active ? 'activer' : 'désactiver'}
                               </div>
                            </td>
                            <td>
                            <div className="d-flex">
                                  <a
                                     href={"news-form/"+item.id}
                                     className="btn btn-danger shadow btn-xs sharp"
                                     target="_blank"
                                  >
                                     <i className="fa fa-pencil"></i>
                                  </a>
                                   
                               </div>
                               <div className="d-flex">
                               <Button
                                     onClick={() => handleDelete(item.id)}
                                      href="#"
                                      className="btn btn-danger shadow btn-xs sharp"
                                   >
                                      <i className="fa fa-trash"></i>
                                   </Button>
                               </div>
                            </td>
                         </tr>
                           )

                           }
                          
                        </tbody>
                     </Table>
                  </Card.Body>
               </Card>
            </Col>
         </Row>
      </Fragment>
   );
};

export default NewsTable;
