import { useEffect } from "react";
import { useChartStore } from "../../../zustand-store/chartStore";
import { usePlacesStore } from "../../../zustand-store/store";

var MEALS_NAME = ["Petit-déjeuner", "Déjeuner", "Dîner"];
var MEALS_FILTER = ["breakfast", "lunch", "dinner"];
const RESTURANTS = ["Campus Restaurant", "Modular Restaurant", "CCI Restaurant"]

export const MealsFilter = () => {
    const places = usePlacesStore();
    const chartStore = useChartStore();
    // useEffect(() => {
    //     var checked = false;
    //     chartStore.placesCheckedValues.forEach((item) => {
    //         console.log(item)
    //         if (RESTURANTS.includes(item)) {
    //             checked = true;
    //         }
    //     })
    //     if (checked === false)
    //         chartStore.setMealsCheckedValues([]);
    // },[places.placesCheckedValues])
    const handleMealsChenge = (checked, value) => {
        if (checked) {
            
        var checked = false;
        chartStore.placesCheckedValues.forEach((item) => {
            console.log(item)
            if (RESTURANTS.includes(item)) {
                checked = true;
            }
        })
        if (checked === false)
            chartStore.setPlacesCheckedValues([RESTURANTS[0]]);

            const arrayMeals =  [...chartStore.mealsCheckedValues,value]

            chartStore.setMealsCheckedValues(arrayMeals);
           
        //   var cateArray = places.categoryPlaces.filter((cate) => {
        //     if (cate.includes(value)) {
        //       return cate;
        //     }
        //   })
        //   cateArray =  [].concat.apply([],cateArray)
        //   console.log(cateArray)
        //   console.log(chartStore.placesCheckedValues)
        //   chartStore.setMealsCheckedValues([
        //     ...chartStore.placesCheckedValues.filter((item) => cateArray.includes(item)? item : null),
        //     value,
        //   ]);
        } else {
          chartStore.setMealsCheckedValues(
            chartStore.mealsCheckedValues.filter((item) => item !== value)
          );
        }
      };
  return (
      <div className="d-flex">
    {MEALS_FILTER.map((item, index) => {
        return (
        <>
            <div className="form-check mb-2">
            <input
              type="checkbox"
              className="form-check-input"
              id={index + "meal"}
              value={item}
              onChange={(e) =>
                handleMealsChenge(e.target.checked, item)
              }
              checked={
                chartStore.mealsCheckedValues.includes(item) ? true : false
              }
            />
            <label className="form-check-label" htmlFor={index + "mm"}>
              {" "}
              {MEALS_NAME[index]}{" "}
            </label>
            </div>
        </>
        )
    })}
    </div>
      
  )
}
