import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { usePlacesStore } from "../../../../zustand-store/store";
export const Attractivness = () => {
  const placeStore = usePlacesStore();
  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-header pb-2 border-0">
          <h5 className="card-title">Attractivité</h5>
        </div>
        <Link to={"overview/attractivness"}>
          <div className="card-body widget-stat pt-2 pb-3">
            <div className="media ai-icon">
              <span className="mr-3 bgl-green text-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="45"
                  viewBox="0 0 46.826 55"
                >
                  <g id="GH3WG3.tif" transform="translate(61 -1148)">
                    <g
                      id="Group_8173"
                      data-name="Group 8173"
                      transform="translate(-61 1148)"
                    >
                      <path
                        id="Path_4800"
                        data-name="Path 4800"
                        d="M-61,1494.835c.08-.269.154-.541.242-.807a4.872,4.872,0,0,1,4.381-3.324c.11-.006.22-.016.389-.028-1.709-.631-2.568-1.7-2.588-3.155a3.137,3.137,0,0,1,1.984-3,.589.589,0,0,0,.444-.7,11.884,11.884,0,0,1-.005-1.21,2.407,2.407,0,0,1,2.413-2.4c1.513-.016,3.026,0,4.539,0,.863,0,1.116-.251,1.119-1.1,0-.281,0-.562,0-.844l-.139-.107c-.141.177-.287.348-.42.53s-.243.366-.378.569l-1.135-1.175,2.9-2.9,2.894,2.9-1.1,1.024-1.008-.963c0,.484.006.863,0,1.242a2.414,2.414,0,0,1-2.437,2.432c-1.53.014-3.06,0-4.59,0-.763,0-1.037.274-1.045,1.023a1.373,1.373,0,0,1,0,.151,1.515,1.515,0,0,0,.937,1.828,2.78,2.78,0,0,1,1.46,2.981,3.122,3.122,0,0,1-2.072,2.688c-.138.056-.282.094-.443.147a14.658,14.658,0,0,1,1.457.262,4.81,4.81,0,0,1,3.5,4.544c.013.571,0,1.143,0,1.715H-61Z"
                        transform="translate(61 -1442.156)"
                        fill="#00c396"
                      />
                      <path
                        id="Path_4801"
                        data-name="Path 4801"
                        d="M243.8,1497.176a17.845,17.845,0,0,1,.162-2.757,4.751,4.751,0,0,1,4.482-3.7l.331-.017a8.442,8.442,0,0,1-1.081-.521,3.076,3.076,0,0,1-1.454-2.967,3.027,3.027,0,0,1,1.987-2.686.565.565,0,0,0,.42-.663c-.022-.335,0-.672-.005-1.009-.006-.747-.283-1.021-1.046-1.022-1.463,0-2.925,0-4.388,0a2.437,2.437,0,0,1-2.639-2.638,3.212,3.212,0,0,0-.11-.986l-.858,1-1.1-1.167,2.85-2.848,2.9,2.911-1.113,1.057-.781-1-.155.09a8.491,8.491,0,0,0,.014,1.32c.077.486.458.656.936.653,1.5-.008,2.992-.005,4.489,0a2.434,2.434,0,0,1,2.617,2.61,1.947,1.947,0,0,1,0,.2,1.478,1.478,0,0,0,.913,1.793,2.774,2.774,0,0,1,1.481,3.016,3.112,3.112,0,0,1-2.091,2.672c-.136.054-.277.1-.4.137a11.556,11.556,0,0,1,1.6.316,4.771,4.771,0,0,1,3.232,3.566c.027.109.072.214.109.322v2.321Z"
                        transform="translate(-208.274 -1442.176)"
                        fill="#00c396"
                      />
                      <path
                        id="Path_4802"
                        data-name="Path 4802"
                        d="M115,1497.448a17.862,17.862,0,0,1,.162-2.756,4.751,4.751,0,0,1,4.48-3.7l.4-.021a3.242,3.242,0,0,1-2.607-3.281,3.164,3.164,0,0,1,2.376-3.063V1478.4l-.89,1.157-1.1-1.224,2.854-2.832,2.877,2.87-1.091,1.016-.969-.918v6.155a3.257,3.257,0,0,1-.149,6.307,14.587,14.587,0,0,1,1.453.261,4.81,4.81,0,0,1,3.5,4.544c.013.571,0,1.143,0,1.714Z"
                        transform="translate(-97.239 -1442.448)"
                        fill="#00c396"
                      />
                      <g
                        id="_3RkUnA.tif"
                        data-name="3RkUnA.tif"
                        transform="translate(4.844)"
                      >
                        <g
                          id="Group_8172"
                          data-name="Group 8172"
                          transform="translate(0)"
                        >
                          <path
                            id="Path_4803"
                            data-name="Path 4803"
                            d="M74.587,1148c.582.394.549.97.476,1.588H50.919a1.387,1.387,0,0,1,.457-1.588Z"
                            transform="translate(-44.413 -1148)"
                            fill="#00c396"
                          />
                          <path
                            id="Path_4804"
                            data-name="Path 4804"
                            d="M-13,1228h6.19a26.8,26.8,0,0,1-.179,3.18,2.937,2.937,0,0,1-2.731,2.29A3.068,3.068,0,0,1-12.8,1231.5c-.072-.187-.135-.376-.2-.564Z"
                            transform="translate(13 -1219.93)"
                            fill="#00c396"
                          />
                          <path
                            id="Path_4805"
                            data-name="Path 4805"
                            d="M300.216,1230.931a8.851,8.851,0,0,1-.719,1.42,2.95,2.95,0,0,1-3.2,1.011,3.059,3.059,0,0,1-2.206-2.768c-.045-.834-.008-1.673-.008-2.589h6.135Z"
                            transform="translate(-263.079 -1219.93)"
                            fill="#00c396"
                          />
                          <path
                            id="Path_4806"
                            data-name="Path 4806"
                            d="M41.741,1297.476H25.257v-.507q0-8.072-.008-16.144c0-.386.1-.545.491-.644a4.577,4.577,0,0,0,2.581-1.826,4.7,4.7,0,0,0,3.9,1.984,4.651,4.651,0,0,0,3.842-1.964,4.823,4.823,0,0,0,7.773,0,4.788,4.788,0,0,0,7.749-.017,4.874,4.874,0,0,0,2.857,1.911c.088.02.163.179.2.289a.892.892,0,0,1,.008.3q0,8.047,0,16.094c0,.165-.014.329-.022.518H53.043v-.594q0-6.029,0-12.058a2.5,2.5,0,0,0-2.814-2.829q-2.876,0-5.751,0a2.449,2.449,0,0,0-2.736,2.742q0,6.054,0,12.108Zm-8.237-5.317c1.345,0,2.69,0,4.035,0a2.407,2.407,0,0,0,2.583-2.536q.026-2.547,0-5.094a2.408,2.408,0,0,0-2.542-2.536q-4.085-.025-8.17,0a2.408,2.408,0,0,0-2.533,2.545q-.025,2.547,0,5.094a2.4,2.4,0,0,0,2.542,2.526C30.78,1292.165,32.142,1292.159,33.5,1292.159Z"
                            transform="translate(-21.389 -1265.2)"
                            fill="#00c396"
                          />
                          <path
                            id="Path_4807"
                            data-name="Path 4807"
                            d="M212.716,1344.205h-8.024c-.009-.186-.025-.364-.025-.541q0-6.074,0-12.149c0-.961.221-1.178,1.195-1.178q2.848,0,5.7,0c.946,0,1.182.241,1.182,1.2q0,6.074,0,12.149C212.738,1343.854,212.725,1344.019,212.716,1344.205Z"
                            transform="translate(-182.699 -1311.935)"
                            fill="#00c396"
                          />
                          <path
                            id="Path_4808"
                            data-name="Path 4808"
                            d="M63.857,1228.15h6.207a21.3,21.3,0,0,1-.287,3.386,2.945,2.945,0,0,1-3.39,2.041,3.084,3.084,0,0,1-2.527-2.971C63.844,1229.8,63.857,1229,63.857,1228.15Z"
                            transform="translate(-56.096 -1220.062)"
                            fill="#00c396"
                          />
                          <path
                            id="Path_4809"
                            data-name="Path 4809"
                            d="M217.5,1228.238h6.056a23.487,23.487,0,0,1-.023,2.918,3.041,3.041,0,0,1-6.018-.143C217.436,1230.1,217.5,1229.179,217.5,1228.238Z"
                            transform="translate(-194.219 -1220.141)"
                            fill="#00c396"
                          />
                          <path
                            id="Path_4810"
                            data-name="Path 4810"
                            d="M140.662,1228.225h6.16a21.624,21.624,0,0,1-.19,3.2,2.941,2.941,0,0,1-3.313,2.236,3.059,3.059,0,0,1-2.653-2.91C140.637,1229.927,140.662,1229.1,140.662,1228.225Z"
                            transform="translate(-125.146 -1220.129)"
                            fill="#00c396"
                          />
                          <path
                            id="Path_4811"
                            data-name="Path 4811"
                            d="M148.707,1183.187h-5.64c.271-1.011.526-2,.81-2.978a.458.458,0,0,1,.356-.224c1.108-.017,2.216-.019,3.324,0a.493.493,0,0,1,.354.288C148.189,1181.223,148.436,1182.18,148.707,1183.187Z"
                            transform="translate(-127.317 -1176.745)"
                            fill="#00c396"
                          />
                          <path
                            id="Path_4812"
                            data-name="Path 4812"
                            d="M78.889,1179.357c-.279,1.039-.532,2.013-.809,2.979a.408.408,0,0,1-.3.239c-1.694.015-3.388.011-5.192.011.244-.313.433-.557.624-.8s.376-.474.568-.708c.469-.57.835-1.358,1.439-1.645a5.594,5.594,0,0,1,2.188-.077C77.881,1179.355,78.351,1179.357,78.889,1179.357Z"
                            transform="translate(-63.954 -1176.127)"
                            fill="#00c396"
                          />
                          <path
                            id="Path_4813"
                            data-name="Path 4813"
                            d="M213.323,1183.243c-1.818,0-3.513,0-5.207-.011a.408.408,0,0,1-.3-.239c-.277-.967-.531-1.94-.809-2.977,1.227,0,2.412,0,3.6.008a.451.451,0,0,1,.277.181C211.676,1181.182,212.461,1182.165,213.323,1183.243Z"
                            transform="translate(-184.808 -1176.784)"
                            fill="#00c396"
                          />
                          <path
                            id="Path_4814"
                            data-name="Path 4814"
                            d="M10.674,1180.014c-.871,1.088-1.673,2.093-2.482,3.092a.374.374,0,0,1-.253.128c-1.538.008-3.077.005-4.77.005.529-.4.936-.709,1.344-1.016.872-.657,1.744-1.315,2.62-1.967a.9.9,0,0,1,.43-.231C8.562,1180,9.563,1180.014,10.674,1180.014Z"
                            transform="translate(-1.536 -1176.781)"
                            fill="#00c396"
                          />
                          <path
                            id="Path_4815"
                            data-name="Path 4815"
                            d="M271.924,1183.268c-1.583,0-3.077,0-4.572-.007a.56.56,0,0,1-.388-.157c-.807-.984-1.6-1.98-2.462-3.06,1.116,0,2.13,0,3.145.006a.525.525,0,0,1,.262.134q1.937,1.449,3.87,2.9A1.4,1.4,0,0,1,271.924,1183.268Z"
                            transform="translate(-236.497 -1176.809)"
                            fill="#00c396"
                          />
                          <path
                            id="Path_4816"
                            data-name="Path 4816"
                            d="M62.3,1337.271c-1.311,0-2.623,0-3.934,0-.79,0-1.053-.263-1.056-1.064q-.007-2.421,0-4.842c0-.752.286-1.033,1.046-1.034q3.959-.007,7.918,0c.781,0,1.054.282,1.057,1.075q.008,2.4,0,4.791c0,.823-.261,1.072-1.1,1.073Q64.27,1337.273,62.3,1337.271Z"
                            transform="translate(-50.214 -1311.927)"
                            fill="#00c396"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </span>
              <div className="media-body">
                <p className="mb-2">
                  Pourcentage des visites par rapport au trafic externe
                </p>
                <h3 className="mb-0">{placeStore.attractivness.toFixed(2)}%</h3>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};
