import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { usePlacesStore } from "../../../../zustand-store/store"

export const Occupation = () => {
    const places = usePlacesStore()

    return (
        
            <div className='col-sm-6 pl-2 py-4'>
            <div className='card'>
            <div className='card-header'>
                <h5 className='card-title' style={{textTransform:"none"}}>Occupation (du point vente)</h5>
            </div>
            <Link to={"overview/occupation"}>
            <div className='card-body widget-stat pr-4 py-4'>
                <div className='media ai-icon border-bottom py-4 py-8'>
                    <span className='mr-3 bgl-orang text-primary'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45.173 51.402">
                    <g id="NITJcL.tif" transform="translate(-502.42 -1004.489)">
                        <g id="Group_8202" data-name="Group 8202" transform="translate(502.42 1004.489)">
                        <path id="Path_4841" data-name="Path 4841" d="M502.42,1030.046c.168-.641.293-1.3.513-1.919a8.389,8.389,0,0,1,7.786-5.6c1.955-.032,3.91-.009,5.865-.009.18,0,.359.02.562.03a8.353,8.353,0,0,0,2.489,5.613c-5.405,2.068-7.539,6.1-7.143,11.735a40.982,40.982,0,0,1-10.072-1.915Z" transform="translate(-502.42 -995.41)" fill="#eb660e"/>
                        <path id="Path_4842" data-name="Path 4842" d="M520.753,1004.489a19.662,19.662,0,0,1,2.346.8,7.181,7.181,0,1,1-10.338,7.643,7.238,7.238,0,0,1,5.662-8.312c.176-.036.349-.084.523-.128Z" transform="translate(-497.267 -1004.489)" fill="#eb660e"/>
                        <path id="Path_4843" data-name="Path 4843" d="M510.117,1038.448c.057-1.435.026-2.879.188-4.3a8.317,8.317,0,0,1,6.992-6.962c1.706-.17,3.437-.114,5.155-.122a14.612,14.612,0,0,1,3.936.26,8.437,8.437,0,0,1,6.343,7.853c.081,2.306.03,4.615.009,6.922a.712.712,0,0,1-.38.477,18.637,18.637,0,0,1-5.16,1.534,35.934,35.934,0,0,1-16.656-1.469.454.454,0,0,1-.379-.53c.014-1.219.006-2.441.006-3.662Z" transform="translate(-498.542 -993.121)" fill="#eb660e"/>
                        <path id="Path_4844" data-name="Path 4844" d="M523.94,1022.473c2.357.057,4.655-.018,6.927.194a8.25,8.25,0,0,1,7.007,7c.161,1.456.1,2.937.125,4.406.016,1.12-.015,2.242.013,3.363a.59.59,0,0,1-.411.636,20.672,20.672,0,0,1-5.864,1.617c-.893.122-1.794.182-2.692.266-.128.012-.257,0-.462,0,0-.859.018-1.692,0-2.523a10.019,10.019,0,0,0-6.513-8.992c-.2-.071-.391-.147-.636-.24A8.39,8.39,0,0,0,523.94,1022.473Z" transform="translate(-492.84 -995.427)" fill="#eb660e"/>
                        <path id="Path_4845" data-name="Path 4845" d="M526.5,1024.572a6.628,6.628,0,1,1-6.632-6.635A6.638,6.638,0,0,1,526.5,1024.572Z" transform="translate(-496.966 -997.713)" fill="#eb660e"/>
                        <path id="Path_4846" data-name="Path 4846" d="M505.524,1020.005a6.63,6.63,0,0,1,13.156-1.15c.015.083.017.167.035.248a1.82,1.82,0,0,1-.657,1.963,7.144,7.144,0,0,0-2.274,4.137.88.88,0,0,1-.52.662A6.63,6.63,0,0,1,505.524,1020.005Z" transform="translate(-500.856 -1000.002)" fill="#eb660e"/>
                        <path id="Path_4847" data-name="Path 4847" d="M527.083,1026.767c-.818-.208-1.641-.4-2.45-.638a.6.6,0,0,1-.328-.414,8.267,8.267,0,0,0-3.477-5.582.747.747,0,0,1-.263-.562,6.6,6.6,0,0,1,6.45-6.181A6.68,6.68,0,0,1,533.7,1019,6.8,6.8,0,0,1,527.083,1026.767Z" transform="translate(-493.277 -1000.007)" fill="#eb660e"/>
                        <path id="Path_4848" data-name="Path 4848" d="M522.586,1018.8a8.047,8.047,0,0,0-6.907.314,8.374,8.374,0,0,0-1.566-4.6,85.962,85.962,0,0,1,10-.012A8.211,8.211,0,0,0,522.586,1018.8Z" transform="translate(-496.528 -999.516)" fill="#eb660e"/>
                        </g>
                    </g>
                    </svg>

                    </span>
                    <div className='media-body'>
                    <p className='mb-2'>Occupation maximale</p>
                    <h3 className='mb-0 '>{ places.maxOccupation?.maxOccupancy ?? 0}<sup style={{fontSize:14, marginLeft:5}}></sup></h3>
                    {/* <span className='badge badge-primary'>+3.5%</span> */}
                    </div>
                </div>
                <div className='media ai-icon py-4'>
                    <span className='mr-3 bgl-orang text-primary'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="49" viewBox="0 0 49.782 59.124">
                        <g id="Group_8197" data-name="Group 8197" transform="translate(0)">
                        <g id="jjg8RI.tif" transform="translate(0 15.784)">
                            <g id="Group_8167" data-name="Group 8167" transform="translate(0 0)">
                            <path id="Path_4770" data-name="Path 4770" d="M-780.962,1247.388c-.771-.535-.869-.82-.6-1.757a10.315,10.315,0,0,1,9.907-7.448,10.309,10.309,0,0,1,9.907,7.447c.269.933.171,1.221-.6,1.757Z" transform="translate(796.555 -1204.048)" fill="#eb660e"/>
                            <path id="Path_4771" data-name="Path 4771" d="M-762.452,1182.618l-1.075-.455a6.264,6.264,0,0,0,2.452-6.078,6.169,6.169,0,0,0-2.456-3.974,7.247,7.247,0,0,0-9.264.506,6.264,6.264,0,0,0,.559,9.543.229.229,0,0,1-.039.031c-1.023.495-1.022.495-1.812-.272a2.029,2.029,0,0,0-.373-.342,1.744,1.744,0,0,1-.835-1.777q.042-14.865.018-29.731c0-1,.21-1.195,1.284-1.195h12.335c.969,0,1.21.222,1.21,1.118q0,15.232.008,30.465a.85.85,0,0,1-.371.744C-761.354,1181.632-761.862,1182.1-762.452,1182.618Zm-5.358-30.5c-1.237,0-2.475,0-3.712,0-.575,0-.831.24-.842.77s-.011,1.046,0,1.568c.011.483.25.722.764.752.4.023.8.009,1.2.009,2.021,0,4.043,0,6.065,0,.728,0,.967-.22.975-.857.005-.474.007-.947,0-1.421-.009-.591-.255-.817-.9-.819C-765.44,1152.11-766.625,1152.114-767.81,1152.114Zm-4.557,13.937c0,.26,0,.521,0,.782.01.594.255.839.876.85.574.01,1.148.013,1.721,0,.537-.013.82-.258.838-.744.021-.586.022-1.174,0-1.759-.019-.467-.3-.7-.8-.714-.312-.009-.626,0-.939,0C-772.367,1164.461-772.367,1164.461-772.367,1166.05Zm7.33-1.589c-.3,0-.591,0-.887,0-.6.011-.859.245-.873.8s-.011,1.108,0,1.662a.7.7,0,0,0,.724.747c.676.029,1.357.031,2.033,0a.653.653,0,0,0,.676-.677c.011-.619.017-1.239,0-1.857a.663.663,0,0,0-.734-.669C-764.41,1164.453-764.724,1164.462-765.037,1164.461Zm-7.328-3.655h0c0,.277-.005.554,0,.83.013.546.243.785.81.8q.912.024,1.825,0c.508-.013.782-.255.8-.721.023-.586.019-1.173,0-1.759a.693.693,0,0,0-.742-.727c-.659-.02-1.321-.022-1.98,0a.663.663,0,0,0-.711.693C-772.372,1160.22-772.365,1160.513-772.365,1160.806Zm9.008.05c0-.26,0-.521,0-.781-.009-.6-.237-.837-.869-.851-.591-.014-1.182-.017-1.772,0-.513.018-.777.26-.794.729-.021.586-.021,1.173,0,1.759.017.467.289.7.8.724.312.014.625,0,.938,0C-763.356,1162.443-763.356,1162.443-763.356,1160.856Z" transform="translate(792.761 -1148.875)" fill="#eb660e"/>
                            <path id="Path_4772" data-name="Path 4772" d="M-720.519,1188.369c-.7-.478-1.3-.939-1.952-1.323s-1.367-.69-2.09-1.048a5.994,5.994,0,0,0,2.408-5.893,6.009,6.009,0,0,0-2.5-3.892,7.4,7.4,0,0,0-10.009,1.36v-16.561h12.727c1.062,0,1.416.318,1.416,1.279q0,12.794,0,25.587Zm-7.167-20.6c1.358,0,2.717,0,4.076,0,.686,0,.932-.232.938-.868,0-.54.008-1.08,0-1.62-.009-.567-.267-.814-.9-.816q-4.05-.012-8.1,0c-.633,0-.872.238-.885.827-.011.54-.01,1.08,0,1.62.011.641.249.855.95.856C-730.3,1167.77-728.992,1167.768-727.686,1167.768Zm2.835,6.5v0c.278,0,.556,0,.835,0,.659-.01.91-.234.918-.835.008-.507.012-1.015,0-1.522a.711.711,0,0,0-.784-.773c-.625-.024-1.252-.023-1.877,0-.511.019-.764.265-.775.724-.013.571-.012,1.141,0,1.712.01.433.266.675.746.691C-725.477,1174.272-725.163,1174.263-724.85,1174.263Zm-5.564,0c.278,0,.556,0,.834,0,.642-.011.875-.217.888-.8.011-.523.013-1.046,0-1.568-.015-.5-.258-.74-.792-.759-.607-.021-1.216-.02-1.823,0-.54.017-.789.252-.808.744-.017.475-.008.95-.005,1.425,0,.8.186.961,1.083.963Z" transform="translate(768.688 -1155.684)" fill="#eb660e"/>
                            <path id="Path_4773" data-name="Path 4773" d="M-810.209,1186.618l-3.947,2.372v-.453q0-13.064,0-26.128c0-1.1.321-1.4,1.484-1.4h12.666v17.046a7.072,7.072,0,0,0-5.939-2.833,6.96,6.96,0,0,0-4.639,1.861A6.214,6.214,0,0,0-810.209,1186.618Zm3.126-18.669c1.358,0,2.717,0,4.075,0,.608,0,.869-.23.885-.791q.026-.9,0-1.81c-.016-.535-.273-.787-.842-.789q-4.1-.014-8.2,0c-.565,0-.821.267-.832.8-.011.57-.007,1.141,0,1.712.006.646.256.88.948.882C-809.73,1167.951-808.407,1167.949-807.083,1167.949Zm-2.773,6.671c.3,0,.591.007.887,0,.569-.016.822-.249.832-.781.01-.553.011-1.107,0-1.661-.01-.5-.26-.754-.8-.773-.608-.022-1.218-.022-1.825,0a.732.732,0,0,0-.815.816c-.011.537-.012,1.074,0,1.611s.273.771.833.788C-810.448,1174.627-810.152,1174.62-809.856,1174.62Zm5.561,0v0c.295,0,.591.006.886,0,.587-.013.835-.224.851-.761q.026-.854,0-1.707a.7.7,0,0,0-.774-.745c-.624-.023-1.251-.021-1.875,0-.458.015-.757.243-.767.7-.014.618-.018,1.236,0,1.854.015.424.286.648.739.661C-804.921,1174.625-804.608,1174.619-804.295,1174.619Z" transform="translate(815.785 -1156.425)" fill="#eb660e"/>
                            <path id="Path_4774" data-name="Path 4774" d="M-800.244,1234.168a12.2,12.2,0,0,0-4.115,5.213.551.551,0,0,1-.416.265q-6.117.021-12.233.006a1.037,1.037,0,0,1-1.09-1.392,9.946,9.946,0,0,1,7.69-7.517A9.929,9.929,0,0,1-800.343,1234,1.05,1.05,0,0,1-800.244,1234.168Z" transform="translate(818.152 -1199.463)" fill="#eb660e"/>
                            <path id="Path_4775" data-name="Path 4775" d="M-739.931,1234.124a10.239,10.239,0,0,1,6.156-3.524,10.318,10.318,0,0,1,11.69,7.584,1.072,1.072,0,0,1-1.148,1.473q-6.038.009-12.076-.008a.635.635,0,0,1-.486-.242C-737.287,1236.51-737.34,1236.434-739.931,1234.124Z" transform="translate(771.809 -1199.465)" fill="#eb660e"/>
                            <path id="Path_4776" data-name="Path 4776" d="M-722.979,1207.539a5.235,5.235,0,0,1-5.215,5.221,5.238,5.238,0,0,1-5.212-5.225,5.24,5.24,0,0,1,5.19-5.2A5.24,5.24,0,0,1-722.979,1207.539Z" transform="translate(767.944 -1182.809)" fill="#eb660e"/>
                            <path id="Path_4777" data-name="Path 4777" d="M-801.086,1212.76a5.235,5.235,0,0,1-5.206-5.231,5.237,5.237,0,0,1,5.194-5.193,5.243,5.243,0,0,1,5.234,5.208A5.237,5.237,0,0,1-801.086,1212.76Z" transform="translate(811.126 -1182.809)" fill="#eb660e"/>
                            <path id="Path_4778" data-name="Path 4778" d="M-764.643,1220.457a5.236,5.236,0,0,1-5.207-5.23,5.239,5.239,0,0,1,5.194-5.194,5.24,5.24,0,0,1,5.234,5.207A5.237,5.237,0,0,1-764.643,1220.457Z" transform="translate(789.535 -1187.37)" fill="#eb660e"/>
                            <path id="Path_4779" data-name="Path 4779" d="M-781.447,1226.839l-1.354-.629,1.354-1.47Z" transform="translate(797.208 -1196.083)" fill="#eb660e"/>
                            <path id="Path_4780" data-name="Path 4780" d="M-733.3,1226.2l-1.371.633v-2.114Z" transform="translate(768.69 -1196.068)" fill="#eb660e"/>
                            </g>
                        </g>
                        <g id="Group_8168" data-name="Group 8168" transform="translate(11.12)">
                            <path id="Path_4781" data-name="Path 4781" d="M-763.437,1121.412h-1.638a12.314,12.314,0,0,0-.465-3.216h-2.751v-1.6h2.193a12.117,12.117,0,0,0-3.1-4.234l-1.553,1.574-1.175-1.18,1.415-1.407a12.018,12.018,0,0,0-5.834-1.965v2.357h-1.62v-2.357a12.031,12.031,0,0,0-6.034,2.092l1.609,1.6-1.145,1.142-1.709-1.74a12.138,12.138,0,0,0-2.972,4.1h2.184v1.618h-2.75a12.106,12.106,0,0,0-.459,3.205h-1.627a13.567,13.567,0,0,1,5.779-11.12,13.434,13.434,0,0,1,14.626-.776C-765.92,1112.13-763.675,1116.171-763.437,1121.412Z" transform="translate(790.866 -1107.707)" fill="#eb660e"/>
                            <path id="Path_4782" data-name="Path 4782" d="M-755.514,1125.833l.8,1.388-1.121.644c-.792.453-1.583.907-2.377,1.355a.225.225,0,0,0-.14.268,2.417,2.417,0,0,1-2.141,2.659,2.429,2.429,0,0,1-2.651-2.1,2.415,2.415,0,0,1,1.967-2.678,2.32,2.32,0,0,1,1.83.422.29.29,0,0,0,.375.013c1.072-.62,2.149-1.231,3.225-1.845C-755.681,1125.922-755.608,1125.884-755.514,1125.833Z" transform="translate(774.46 -1118.446)" fill="#eb660e"/>
                        </g>
                        <path id="Path_4783" data-name="Path 4783" d="M-811.318,1135.206l1.746,1.628-1.107,1.06-3.589-3.621,3.636-3.623.976,1.1-1.658,1.6.061.1H-795.4l.053.114a10.955,10.955,0,0,0-1,.707,3.484,3.484,0,0,1-2.528.806c-3.946-.05-7.894-.021-11.84-.021h-.526Z" transform="translate(815.851 -1121.3)" fill="#eb660e"/>
                        <path id="Path_4784" data-name="Path 4784" d="M-746.861,1133.568h16.122l-1.872-1.7,1.213-1.089,3.53,3.554-3.626,3.626-.977-1.023,1.727-1.622-.094-.18h-.5c-4.177,0-8.354-.008-12.531.01a2.062,2.062,0,0,1-1.358-.432C-745.716,1134.335-746.237,1134-746.861,1133.568Z" transform="translate(775.915 -1121.377)" fill="#eb660e"/>
                        </g>
                    </svg>

                    </span>
                    <div className='media-body'>
                    <p className='mb-2'>Pourcentage de temps où l'occupation est supérieure à <span style={{color:'#3841E2'}}> {places.capacity} personne</span></p>
                    <h3 className='mb-0'>{places?.maxOccupation?.overallOccupancyRate?.toFixed(2) ?? 0}%<sup style={{fontSize:12, marginLeft:5}}>Of service time</sup></h3>
                    </div>
                </div>
            </div>
            </Link>
            </div>
        </div>
    )
}
