import { create } from "zustand";
import { realTimeDb } from "../Firebase";
import { onValue, ref, off } from "firebase/database";
import axios from "axios";
axios.defaults.baseURL = "http://204.48.16.65:3006";
export const usePlacesStore = create((set) => ({
  places: [],
  places_names: [],
  categoryPlaces: [],
  maxOccupation: 0,
  occupationRate: 0,
  watingTimeRate: 0,
  OperationsRate: 0,
  queueRate: 0,
  avgOccupation: 0,
  maxQueue: 0,
  maxWaitingTime: 0,
  effectiveOperations: 0,
  attractivness: 0,
  stations: [],
  scoring: [],
  ConversionRate: 0,
  capacity: 0,
  startDate: new Date(),
  endDate: new Date(),
  setStartDate: (date) => set(() => ({ startDate: date })),
  setEndDate: (date) => set(() => ({ endDate: date })),
  // conversion
  setConversion: async (start, end, agencies, meals) => {
    if (agencies) {
      const res = await axios.post("/conversionDaily", {
        startDate: start,
        endDate: end,
        agencies: agencies,
        meals: meals,
      });

      set(() => ({ ConversionRate: res.data.conversionRate }));
      return res?.data?.conversionRate?.toFixed(2);
    }
  },
  setScoring: async (start, end, agencies, meals) => {
    const res = await axios.post("/scoringStationDaily", {
      startDate: start,
      endDate: end,
      agencies: agencies,
      meals: meals,
    });

    set(() => ({ scoring: res.data }));
    return res.data;
  },
  setStations: async (start, end, agencies, meals) => {
    var s = [];
    if (agencies) {
      const res = await axios.post("/stationDaily", {
        startDate: start,
        endDate: end,
        agencies: agencies,
        meals: meals,
      });
      // //(res.data)
      s = res.data;
    }
    // //(s)
    set(() => ({ stations: s }));
    return s;
  },
  setAttractivness: async (start, end, agencies, meals) => {
    let max = 0;
    if (agencies) {
      for (let i = 0; i < agencies.length; i++) {
        const res = await axios.post("/attractivnessDaily", {
          startDate: start,
          endDate: end,
          agencies: [...agencies, agencies[i]],
          meals: meals,
        });
        max += res.data.Attractiveness;
      }
    }
    set(() => ({ attractivness: max }));
    return max;
  },
  setEffectiveOperations: async (start, end, agencies, meals) => {
    let maxOperations = {
      constMinute: 5,
      overAllOperation: 0,
      overallAttractivnessRate: 0,
    };
    let max;
    if (agencies) {
      const res = await axios.post("/effectiveDaily", {
        startDate: start,
        endDate: end,
        agencies: agencies,
        meals: meals,
      });
      max = res.data;
      res.data.forEach((element) => {
        maxOperations.overAllOperation += element.overAllOperation;
        maxOperations.overallAttractivnessRate +=
          element.overallAttractivnessRate;
      });
      maxOperations.overallAttractivnessRate =
        maxOperations.overallAttractivnessRate / res.data.length;
    }
    set(() => ({ effectiveOperations: max }));

    return maxOperations.overAllOperation;
  },
  setMaxWaitingTime: async (start, end, agencies, meals) => {
    let max;
    if (agencies) {
      const res = await axios.post("/waitingTimeDaily", {
        startDate: start,
        endDate: end,
        agencies: agencies,
        meals: meals,
      });
      max = res.data;
    }

    set(() => ({ maxWaitingTime: max }));
    return max;
  },
  setMaxQueue: async (start, end, agencies, meals) => {
    let max;
    if (agencies) {
      const res = await axios.post("/queueDaily", {
        startDate: start,
        endDate: end,
        agencies: agencies,
        meals: meals,
      });
      max = res.data;
    }
    set(() => ({ maxQueue: max }));
    return max.maxQueue;
  },
  setMaxOccupation: async (start, end, agencies, meals) => {
    let max;
    if (agencies) {
      const res = await axios.post("/occupancyDaily", {
        startDate: start,
        endDate: end,
        agencies: agencies,
        meals: meals,
      });
      max = res.data;
    }

    set(() => ({ maxOccupation: max }));
    return max.maxOccupancy;
  },

  setOccupationRate: (rate) => set(() => ({ occupationRate: rate })),

  setPlaces: () => {
    //("request")
    const query = ref(realTimeDb, "Places");
    const onDataChange = (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const allplaces = Object.values(data);
        let placesnames = [];
        set({ places: allplaces });
        var globalCates = [];
        allplaces.map((x) => {
          var cates = [];
          x.map((item) => {
            placesnames.push(item.Name);
            cates.push(item.Name);
          });
          globalCates.push(cates);
        });
        set(() => ({ categoryPlaces: globalCates }));
        set(() => ({ places_names: placesnames }));
      }

      off(query, "value", onDataChange);
    };

    onValue(query, onDataChange);
  },
  setCapacity: (capacity) => set(() => ({ capacity: capacity })),
}));
