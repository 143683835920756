import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { usePlacesStore } from "../../../../zustand-store/store"
export const Queue = () => { 
    const placeStore = usePlacesStore();
    return (
        <div className="col-sm-6 pl-2">
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title" style={{textTransform: 'none'}}>File d'attente</h5>
                      </div>
                      <Link to={"overview/queue"}>
                        <div className="card-body widget-stat pr-4">
                        <div className="media ai-icon border-bottom pb-4">
                          <span className="mr-3 bgl-blue text-primary">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="35"
                              height="35"
                              viewBox="0 0 48.826 48.81"
                            >
                              <g
                                id="JhV6hk.tif"
                                transform="translate(-471.42 -1004.489)"
                              >
                                <g
                                  id="Group_8210"
                                  data-name="Group 8210"
                                  transform="translate(471.42 1004.489)"
                                >
                                  <path
                                    id="Path_4851"
                                    data-name="Path 4851"
                                    d="M479.618,1004.489c.252.081.506.156.757.24a4.775,4.775,0,0,1-1.167,9.3c-.061,0-.122.014-.326.04.249.087.375.132.5.175a6.039,6.039,0,0,1,4.191,5.714c.047,3.508,0,7.019-.006,10.529a1.257,1.257,0,0,1-.029.177h-4v-.571q0-4.6,0-9.2a1.4,1.4,0,0,0-2.718-.527,2.871,2.871,0,0,0-.108.889c-.008,3.653,0,7.3-.009,10.957a4.314,4.314,0,0,1-.105,1.084,2.552,2.552,0,0,1-5-.373,6.745,6.745,0,0,1-.048-.76c-.025-2.159-.043-4.319-.068-6.479,0-.2-.04-.406-.061-.61v-5.91a1.042,1.042,0,0,0,.091-.212,6.088,6.088,0,0,1,4.793-4.892c.371-.078.753-.106,1.213-.169-.171-.074-.255-.113-.34-.147a4.634,4.634,0,0,1-3.049-3.926,4.457,4.457,0,0,1,2.037-4.474,11.473,11.473,0,0,1,1.928-.861Z"
                                    transform="translate(-471.42 -1004.489)"
                                    fill="#0097ff"
                                  />
                                  <path
                                    id="Path_4852"
                                    data-name="Path 4852"
                                    d="M493.721,1004.489a5.773,5.773,0,0,1,2.354,1.156,4.689,4.689,0,0,1,1.43,5.1,4.786,4.786,0,0,1-4.208,3.285c-.057,0-.116.014-.257.034a2.46,2.46,0,0,0,.266.142,5.983,5.983,0,0,1,4.36,5.817c.042,3.477,0,6.956-.009,10.434a2,2,0,0,1-.027.216h-4v-.557q0-4.6,0-9.2a1.4,1.4,0,0,0-1.1-1.422,1.327,1.327,0,0,0-1.5.647,2.452,2.452,0,0,0-.23,1.048c-.016,3.684-.005,7.369-.016,11.053a4.128,4.128,0,0,1-.132,1.174,2.549,2.549,0,0,1-4.942-.375,6.065,6.065,0,0,1-.068-.853c-.043-3.938-.012-7.879-.143-11.814a6.186,6.186,0,0,1,5.9-6.435,2.006,2.006,0,0,0,.212-.043c-.152-.066-.244-.112-.339-.149a4.792,4.792,0,0,1,.578-9.141c.118-.03.232-.079.349-.119Z"
                                    transform="translate(-467.219 -1004.489)"
                                    fill="#0097ff"
                                  />
                                  <path
                                    id="Path_4853"
                                    data-name="Path 4853"
                                    d="M507.81,1004.489c.191.064.38.131.573.188a4.775,4.775,0,0,1-1.015,9.354c-.047,0-.094.013-.195.026a.868.868,0,0,0,.188.14,6.04,6.04,0,0,1,4.39,6.087q0,4.6-.013,9.2c0,.38,0,.761,0,1.182h-4.016v-.538q0-4.6,0-9.2a1.406,1.406,0,0,0-1.1-1.428,1.349,1.349,0,0,0-1.533.677,2.419,2.419,0,0,0-.209,1.006c-.014,3.75-.007,7.5-.01,11.248a2.574,2.574,0,1,1-5.139.048c-.047-3.671-.09-7.34-.107-11.009a13.021,13.021,0,0,1,.167-2.886,6.01,6.01,0,0,1,5.552-4.637l.4-.032-.487-.195a4.8,4.8,0,0,1,.266-8.993c.252-.083.506-.158.761-.238Z"
                                    transform="translate(-463.005 -1004.489)"
                                    fill="#0097ff"
                                  />
                                  <path
                                    id="Path_4854"
                                    data-name="Path 4854"
                                    d="M473.189,1031.375c3.416-.364,4.989-1.589,5.751-4.469h3.985c-.056,1.028-.084,2.04-.171,3.045-.258,2.955-.536,5.909-.817,8.862-.205,2.163-.417,4.325-.648,6.486a1.375,1.375,0,0,1-1.476,1.307q-2.382.008-4.764,0a1.454,1.454,0,0,1-1.565-1.533c-.032-1.143-.053-2.286-.079-3.429q-.107-4.881-.214-9.763C473.187,1031.71,473.189,1031.54,473.189,1031.375Z"
                                    transform="translate(-470.892 -997.799)"
                                    fill="#0097ff"
                                  />
                                  <path
                                    id="Path_4855"
                                    data-name="Path 4855"
                                    d="M497.029,1026.9c-.091,1.431-.151,2.81-.27,4.183-.222,2.544-.477,5.085-.722,7.627q-.319,3.292-.654,6.582a1.386,1.386,0,0,1-1.47,1.317c-1.588.007-3.176,0-4.764,0a1.458,1.458,0,0,1-1.575-1.571c-.023-1.017-.049-2.032-.071-3.049q-.109-5.023-.218-10.049c0-.187,0-.374,0-.571a5.563,5.563,0,0,0,5.77-4.47Z"
                                    transform="translate(-466.686 -997.802)"
                                    fill="#0097ff"
                                  />
                                  <path
                                    id="Path_4856"
                                    data-name="Path 4856"
                                    d="M507.142,1026.9H511.1c-.045.922-.061,1.823-.14,2.718q-.446,5.048-.928,10.094c-.177,1.863-.366,3.725-.557,5.587a1.4,1.4,0,0,1-1.433,1.308c-1.62.008-3.24.005-4.86,0a1.439,1.439,0,0,1-1.513-1.484q-.1-3.929-.183-7.858-.06-2.69-.106-5.382c0-.17,0-.34,0-.516A5.556,5.556,0,0,0,507.142,1026.9Z"
                                    transform="translate(-462.48 -997.801)"
                                    fill="#0097ff"
                                  />
                                </g>
                              </g>
                            </svg>
                          </span>
                          <div className="media-body">
                            <p className="mb-2">Longueur maximale de la file d'attente</p>
                            <h3 className="mb-0">
                              {placeStore.maxQueue.maxQueue}
                              <sup style={{ fontSize: 14, marginLeft: 5 }}>
                              Personne
                              </sup>
                            </h3>
                            {/* <span className='badge badge-primary'>+3.5%</span> */}
                          </div>
                        </div>
                        <div className="media ai-icon mt-4">
                          <span className="mr-3 bgl-blue text-primary">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="45"
                              height="45"
                              viewBox="0 0 54.037 63.931"
                            >
                              <g id="kmJYCt.tif" transform="translate(0 9)">
                                <g
                                  id="Group_8164"
                                  data-name="Group 8164"
                                  transform="translate(0 9)"
                                >
                                  <path
                                    id="Path_4747"
                                    data-name="Path 4747"
                                    d="M-213.529-446c.919.883,1.862,1.744,2.746,2.659a1.039,1.039,0,0,1-.14,1.542q-1.023,1.061-2.084,2.086a1.1,1.1,0,0,1-1.634.068,1.107,1.107,0,0,1,.014-1.594c.057-.07.116-.137.22-.261h-5.566c.118.157.228.291.326.434a1.08,1.08,0,0,1-.123,1.421,1.012,1.012,0,0,1-1.413.1q-1.287-1.187-2.476-2.475a.923.923,0,0,1,.029-1.322c.89-.912,1.829-1.776,2.748-2.659h.669c.257.338.669.651.728,1.021s-.238.79-.37,1.163h5.424c-.793-1.02-.729-1.527.233-2.184Z"
                                    transform="translate(225.344 446)"
                                    fill="#0091ff"
                                  />
                                  <path
                                    id="Path_4748"
                                    data-name="Path 4748"
                                    d="M-190.728-446c.919.883,1.862,1.744,2.747,2.659a1.039,1.039,0,0,1-.14,1.542q-1.024,1.061-2.084,2.086a1.1,1.1,0,0,1-1.634.068,1.107,1.107,0,0,1,.014-1.594c.057-.07.116-.137.22-.261h-5.566c.118.157.228.291.325.434a1.078,1.078,0,0,1-.123,1.421,1.012,1.012,0,0,1-1.413.1q-1.286-1.187-2.476-2.475a.923.923,0,0,1,.03-1.322c.89-.912,1.828-1.776,2.748-2.659h.668c.258.338.67.651.728,1.021s-.237.79-.369,1.163h5.424c-.793-1.019-.729-1.527.233-2.184Z"
                                    transform="translate(218.139 446)"
                                    fill="#0091ff"
                                  />
                                  <path
                                    id="Path_4749"
                                    data-name="Path 4749"
                                    d="M-148.053-425.016c-.1.133-.181.276-.289.4a1.155,1.155,0,0,1-1.636.115,1.188,1.188,0,0,1,0-1.694,1.157,1.157,0,0,1,1.636.115c.108.122.193.265.289.4Z"
                                    transform="translate(202.09 443.431)"
                                    fill="#0091ff"
                                  />
                                  <path
                                    id="Path_4750"
                                    data-name="Path 4750"
                                    d="M-157.1-436.924c0,.9.215,1.315.769,1.5.5.168,1.055-.116,1.5-.772V-446h-16.154a1.347,1.347,0,0,0-.783,1.179c.009,2.19,0,4.379.008,6.569a1.1,1.1,0,0,0,1.153,1.161c.345.014.692,0,1.029,0v13.37h-.536c-1.371,0-1.652.289-1.652,1.672,0,5.622.009,11.245-.012,16.866a1.516,1.516,0,0,0,.791,1.514h16.154v-23.172c-.181-.2-.328-.517-.551-.588a2,2,0,0,0-1.145-.063.981.981,0,0,0-.579,1.045c.008,2.19-.006,4.38.011,6.57,0,.423-.092.581-.553.579q-5.651-.028-11.3,0c-.4,0-.587-.092-.548-.525a5.345,5.345,0,0,0,0-1.056c-.054-.5.177-.577.612-.574,2.746.016,5.493.009,8.24.008,1.021,0,1.4-.385,1.4-1.412q0-7.544,0-15.089c0-1.027-.382-1.411-1.4-1.411-2.747,0-5.494-.014-8.24.011-.5,0-.631-.151-.616-.633.034-1.075.023-2.153,0-3.229-.006-.369.079-.528.491-.527q5.707.021,11.414,0c.4,0,.5.144.5.52C-157.108-441.119-157.1-439.022-157.1-436.924Zm-9.011,22.076q2.833-.008,5.666,0a1.112,1.112,0,0,1,1.2,1.159,1.115,1.115,0,0,1-1.21,1.152c-.962.009-1.926,0-2.889,0-.925,0-1.852.006-2.777,0a1.119,1.119,0,0,1-1.23-1.132A1.118,1.118,0,0,1-166.111-414.849Z"
                                    transform="translate(208.865 449.597)"
                                    fill="#0091ff"
                                  />
                                  <g
                                    id="xQH7p2.tif"
                                    transform="translate(0 12.604)"
                                  >
                                    <g id="Group_8163" data-name="Group 8163">
                                      <path
                                        id="Path_4751"
                                        data-name="Path 4751"
                                        d="M-181.133-429.059c-.055.173-.107.347-.164.518a3.383,3.383,0,0,1-3.128,2.239c.162.058.238.089.317.114a4.1,4.1,0,0,1,2.916,3.856c.021,2.408,0,4.815,0,7.223,0,.042-.008.085-.014.149h-2.72v-.386c0-2.082,0-4.164,0-6.246a.964.964,0,0,0-1.417-.912,1.025,1.025,0,0,0-.536,1q.006,3.839,0,7.679a2.61,2.61,0,0,1-.056.612,1.732,1.732,0,0,1-1.775,1.327,1.744,1.744,0,0,1-1.639-1.532,3.353,3.353,0,0,1-.027-.388c-.031-2.787-.07-5.574-.09-8.361a4.121,4.121,0,0,1,3.363-4.126c.253-.051.514-.066.822-.1-.077-.039-.114-.062-.154-.076a3.3,3.3,0,0,1-2.2-3.425,3.279,3.279,0,0,1,2.681-2.883.594.594,0,0,0,.115-.052h.911a.879.879,0,0,0,.143.059,3.217,3.217,0,0,1,2.492,2.192c.064.179.109.365.164.547Z"
                                        transform="translate(214.456 432.833)"
                                        fill="#0091ff"
                                      />
                                      <path
                                        id="Path_4752"
                                        data-name="Path 4752"
                                        d="M-202.2-432.833a8.6,8.6,0,0,1,.914.309,3.251,3.251,0,0,1,1.826,3.6,3.3,3.3,0,0,1-3.024,2.6c-.042,0-.082.008-.2.019.142.053.218.085.3.109a4.083,4.083,0,0,1,2.928,3.846c.029,2.408,0,4.815,0,7.223,0,.051-.01.1-.016.17h-2.718v-.381c0-2.083,0-4.165,0-6.247a.964.964,0,0,0-1.435-.913,1.02,1.02,0,0,0-.516.979q0,2.522,0,5.043c0,.965.011,1.931-.006,2.9a1.725,1.725,0,0,1-1.588,1.684,1.745,1.745,0,0,1-1.833-1.3,3.153,3.153,0,0,1-.068-.71c-.035-2.733-.071-5.465-.094-8.2a4.124,4.124,0,0,1,3.807-4.259l.431-.051a3.267,3.267,0,0,1-2.192-1.991,3.252,3.252,0,0,1,2.332-4.365c.083-.019.162-.049.243-.073Z"
                                        transform="translate(220.227 432.833)"
                                        fill="#0091ff"
                                      />
                                      <path
                                        id="Path_4753"
                                        data-name="Path 4753"
                                        d="M-220.468-432.833c.174.049.351.094.524.148a3.292,3.292,0,0,1,2.265,3.533,3.309,3.309,0,0,1-3.061,2.834c-.043,0-.086.006-.183.013a1.65,1.65,0,0,0,.2.1,4.114,4.114,0,0,1,3.015,4.075c.007,2.136,0,4.272-.007,6.407v.767h-2.737v-.391q0-3.041,0-6.082a2.079,2.079,0,0,0-.029-.453.966.966,0,0,0-.922-.734.956.956,0,0,0-.946.7,2.047,2.047,0,0,0-.052.514c0,2.559,0,5.117,0,7.676a1.736,1.736,0,0,1-1.315,1.786,1.727,1.727,0,0,1-2.167-1.7c-.039-1.788-.047-3.577-.071-5.365,0-.161-.027-.322-.041-.483v-3.254a.883.883,0,0,0,.057-.147,4.142,4.142,0,0,1,3.316-3.4c.254-.051.516-.068.843-.109-.127-.051-.185-.076-.244-.1a3.161,3.161,0,0,1-2.119-2.717,3.024,3.024,0,0,1,1.387-3.019,7.66,7.66,0,0,1,1.382-.6Z"
                                        transform="translate(226 432.833)"
                                        fill="#0091ff"
                                      />
                                      <path
                                        id="Path_4754"
                                        data-name="Path 4754"
                                        d="M-186.2-390.331a1.153,1.153,0,0,1-.782-1.208c-.046-2.677-.116-5.354-.174-8.032-.008-.378,0-.756,0-1.159a3.8,3.8,0,0,0,3.924-3.052h2.69c-.027.56-.034,1.11-.083,1.656q-.461,5-.937,10c-.019.2-.056.41-.055.614a1.187,1.187,0,0,1-.741,1.179Z"
                                        transform="translate(213.726 423.653)"
                                        fill="#0091ff"
                                      />
                                      <path
                                        id="Path_4755"
                                        data-name="Path 4755"
                                        d="M-222.735-390.332a1.168,1.168,0,0,1-.773-1.216c-.053-2.936-.126-5.874-.191-8.81,0-.118,0-.235,0-.37a3.828,3.828,0,0,0,3.946-3.057h2.687c-.034.642-.047,1.287-.105,1.929-.311,3.4-.638,6.795-.946,10.193-.053.581-.171,1.09-.777,1.331Z"
                                        transform="translate(225.273 423.654)"
                                        fill="#0091ff"
                                      />
                                      <path
                                        id="Path_4756"
                                        data-name="Path 4756"
                                        d="M-204.465-390.332a1.16,1.16,0,0,1-.778-1.212c-.049-2.8-.12-5.593-.182-8.389-.005-.259,0-.517,0-.8a3.808,3.808,0,0,0,3.934-3.054h2.7c-.057.923-.092,1.83-.174,2.733-.219,2.417-.457,4.833-.689,7.249-.083.863-.17,1.725-.254,2.588a1.112,1.112,0,0,1-.717.883Z"
                                        transform="translate(219.499 423.654)"
                                        fill="#0091ff"
                                      />
                                    </g>
                                  </g>
                                  <g
                                    id="rrqOyK.tif"
                                    transform="translate(-452.662 -1022.489)"
                                  >
                                    <g
                                      id="Group_8205"
                                      data-name="Group 8205"
                                      transform="translate(471.42 1004.489)"
                                    >
                                      <path
                                        id="Path_4849"
                                        data-name="Path 4849"
                                        d="M478.192,1004.489h1.024c.233.032.468.056.7.1a7.017,7.017,0,0,1,3.534,1.672,7.1,7.1,0,0,1,2.435,6.7,6.93,6.93,0,0,1-2.7,4.543,7.09,7.09,0,0,1-6,1.4,7.006,7.006,0,0,1-3.6-1.939,7.163,7.163,0,0,1-2.059-3.889c-.048-.26-.078-.523-.116-.785v-1.024c.01-.046.022-.091.028-.137.034-.244.055-.49.1-.73a7.308,7.308,0,0,1,5.872-5.79C477.678,1004.558,477.935,1004.527,478.192,1004.489Zm.515,13.177a5.893,5.893,0,1,0-5.895-5.89A5.9,5.9,0,0,0,478.706,1017.666Z"
                                        transform="translate(-471.42 -1004.489)"
                                        fill="#0094ff"
                                      />
                                      <path
                                        id="Path_4850"
                                        data-name="Path 4850"
                                        d="M480.7,1009.186c0,.289,0,.578,0,.866a.427.427,0,0,0,.571.423.441.441,0,0,0,.283-.435c0-.284,0-.567,0-.852a5.03,5.03,0,0,1,4.6,4.588c-.288,0-.576,0-.864,0a.442.442,0,0,0-.427.272.427.427,0,0,0,.409.58c.246.006.493,0,.74,0h.14a5.033,5.033,0,0,1-4.595,4.591c0-.289,0-.577,0-.866a.427.427,0,0,0-.571-.423.44.44,0,0,0-.283.435c0,.284,0,.567,0,.852a5.032,5.032,0,0,1-4.6-4.588c.289,0,.577,0,.864,0a.44.44,0,0,0,.427-.273.427.427,0,0,0-.41-.58c-.246-.006-.493,0-.74,0h-.14A5.034,5.034,0,0,1,480.7,1009.186Zm.407,4.432c-.037-.031-.063-.052-.087-.073q-.642-.561-1.285-1.121a.421.421,0,0,0-.621.02.43.43,0,0,0,.063.619q.816.718,1.637,1.431a.425.425,0,0,0,.652-.029c.167-.168.331-.339.5-.509q.862-.887,1.723-1.774a.424.424,0,1,0-.607-.591c-.054.053-.106.109-.159.163Z"
                                        transform="translate(-473.845 -1006.921)"
                                        fill="#0094ff"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          </span>
                          <div className="media-body">
                            <p className="mb-2">
                            Pourcentage du temps où la file d'attente a dépassé{" "}
                              <span style={{ color: "#3841E2" }}>
                                {" "}
                                30 personnes
                              </span>
                            </p>
                            <h3 className="mb-0">
                              {placeStore?.maxQueue?.overallAverageQueue?.toFixed(2)}%
                              <sup style={{ fontSize: 12, marginLeft: 5 }}>
                                {/* Of service time (00:37min) */}
                              </sup>
                            </h3>
                            {/* <span className='badge badge-primary'>+3.5%</span> */}
                          </div>
                        </div>
                      </div>
                    </Link>
                    </div>
                  </div>
    )
}