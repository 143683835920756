import React, { useRef, useLayoutEffect, useEffect, useState } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { usePlacesStore } from "../../../../zustand-store/store";
import { useChartStore } from "../../../../zustand-store/chartStore";

am4core.useTheme(am4themes_animated);
const roundToNearestMultiple = (value, multiple = 10) => {
  const nearestMultiple = Math.ceil(value / multiple) * multiple;
  return nearestMultiple;
};
function ChartKPIs1(props) {
  const chart = useRef(null);
  const chartStore = useChartStore();
  const placeStore = usePlacesStore();
  const [waitingData, setWaitingData] = useState();
  const [maxValue, setMaxValue] = useState(10);
  const [showData, setShowData] = useState("");
  const [unit, setUnit] = useState("");
  useEffect(() => {
    switch (props.name) {
      case "occupation":
        setShowData("Personnes");
        setUnit("Personnes");
        break;
      case "waitingtime":
        setShowData("minutes");
        setUnit("minutes");
        break;
      case "queue":
        setShowData("Personnes");
        setUnit("Personnes");
        break;
      case "Operations":
        setShowData("Operation");
        setUnit("Personnes");
        break;
      case "attractivness":
        setShowData("%");
        setUnit("trafic / trafic externe");
        break;
    }
  }, []);
  useEffect(async () => {
    let arrayofData = [];

    if (props.name) {
      for (let i = 0; i < chartStore.placesCheckedValues.length; ++i) {
        var max = 0;
        switch (props.name) {
          case "occupation":
            max = await placeStore.setMaxOccupation(
              chartStore.startDate,
              chartStore.endDate,
              chartStore.placesCheckedValues.slice(i, i + 1),
              chartStore.mealsCheckedValues
            );

            arrayofData.push({
              country: chartStore.placesCheckedValues[i],
              visits: max?.maxOccupation?.maxOccupancy || 0,
            });
            break;
          case "waitingtime":
            max = await placeStore.setMaxWaitingTime(
              chartStore.startDate,
              chartStore.endDate,
              chartStore.placesCheckedValues.slice(i, i + 1),
              chartStore.mealsCheckedValues
            );

            arrayofData.push({
              country: chartStore.placesCheckedValues[i],
              visits: max?.maxWaitingTime,
            });
            break;
          case "queue":
            max = await placeStore.setMaxQueue(
              chartStore.startDate,
              chartStore.endDate,
              chartStore.placesCheckedValues.slice(i, i + 1),
              chartStore.mealsCheckedValues
            );

            arrayofData.push({
              country: chartStore.placesCheckedValues[i],
              visits: max.maxQueue,
            });
            break;
          case "Operations":
            max = await placeStore.setEffectiveOperations(
              chartStore.startDate,
              chartStore.endDate,
              chartStore.placesCheckedValues.slice(i, i + 1),
              chartStore.mealsCheckedValues
            );

            arrayofData.push({
              country: chartStore.placesCheckedValues[i],
              visits: max.overAllOperation,
            });
            break;
          case "attractivness":
            max = await placeStore.setAttractivness(
              chartStore.startDate,
              chartStore.endDate,
              chartStore.placesCheckedValues.slice(i, i + 1),
              chartStore.mealsCheckedValues
            );

            arrayofData.push({
              country: chartStore.placesCheckedValues[i],
              visits: max,
            });
            break;
        }
      }
      setWaitingData(arrayofData);
    }
    //("render chart 1")
  }, []);
  useEffect(async () => {
    let arrayofData = [];
    // setMaxValue(200);
    if (props.name) {
      for (let i = 0; i < chartStore.placesCheckedValues.length; ++i) {
        var max = 0;
        switch (props.name) {
          case "occupation":
            max = await placeStore.setMaxOccupation(
              chartStore.startDate,
              chartStore.endDate,
              chartStore.placesCheckedValues.slice(i, i + 1),
              chartStore.mealsCheckedValues
            );
            if (max > maxValue)
              setMaxValue(roundToNearestMultiple(chartStore.maxOccupation));
            arrayofData.push({
              country: chartStore.placesCheckedValues[i],
              visits: max?.toFixed(2),
            });
            break;
          case "waitingtime":
            max = await placeStore.setMaxWaitingTime(
              chartStore.startDate,
              chartStore.endDate,
              chartStore.placesCheckedValues.slice(i, i + 1),
              chartStore.mealsCheckedValues
            );
            if (max.maxWaitingTime > maxValue)
              setMaxValue(roundToNearestMultiple(chartStore.maxWaitingTime));
            arrayofData.push({
              country: chartStore.placesCheckedValues[i],
              visits: max?.maxWaitingTime.toFixed(2),
            });
            break;
          case "queue":
            max = await placeStore.setMaxQueue(
              chartStore.startDate,
              chartStore.endDate,
              chartStore.placesCheckedValues.slice(i, i + 1),
              chartStore.mealsCheckedValues
            );
            if (max > maxValue)
              setMaxValue(roundToNearestMultiple(chartStore.maxQueue));
            arrayofData.push({
              country: chartStore.placesCheckedValues[i],
              visits: max?.toFixed(2),
            });
            break;
          case "Operations":
            max = await placeStore.setEffectiveOperations(
              chartStore.startDate,
              chartStore.endDate,
              chartStore.placesCheckedValues.slice(i, i + 1),
              chartStore.mealsCheckedValues
            );
            if (max > maxValue)
              setMaxValue(
                roundToNearestMultiple(chartStore.effectiveOperations)
              );
            arrayofData.push({
              country: chartStore.placesCheckedValues[i],
              visits: max?.toFixed(2),
            });
            break;
          case "attractivness":
            max = await placeStore.setAttractivness(
              chartStore.startDate,
              chartStore.endDate,
              chartStore.placesCheckedValues.slice(i, i + 1),
              chartStore.mealsCheckedValues
            );
            if (max > maxValue)
              setMaxValue(roundToNearestMultiple(chartStore.attractivness));
            arrayofData.push({
              country: chartStore.placesCheckedValues[i],
              visits: max?.toFixed(2),
            });
            break;
        }
      }
      setWaitingData(arrayofData);
    }
    //("render chart 1")
  }, [
    props.name,
    chartStore.placesCheckedValues,
    chartStore.startDate,
    chartStore.endDate,
    chartStore.mealsCheckedValues,
  ]);

  useLayoutEffect(() => {
    //("render chart 1")

    chart.current = am4core.create("chartkpi1", am4charts.XYChart);

    chart.current.data = waitingData;

    // Create axes
    let categoryAxis = chart.current.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "country";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 60;
    categoryAxis.tooltip.disabled = true;

    let valueAxis = chart.current.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.minWidth = 50;

    valueAxis.cursorTooltipEnabled = false;
    valueAxis.min = 0; // Minimum value
    valueAxis.max = maxValue;
    valueAxis.paddingLeft = 10;
    valueAxis.paddingRight = 10;
    // valueAxis.layout = "absolute";

    // // Set up valueAxis title
    valueAxis.title.text = unit;
    valueAxis.title.rotation = -90;
    valueAxis.title.align = "center";
    valueAxis.title.valign = "top";
    valueAxis.title.dy = 90;
    // valueAxis.title.dx = -0;
    valueAxis.title.fontWeight = 600;
    //(maxValue)
    // Create series
    let series = chart.current.series.push(new am4charts.ColumnSeries());
    series.sequencedInterpolation = true;
    series.dataFields.valueY = "visits";
    series.dataFields.categoryX = "country";

    series.tooltipText = `{valueY.value} ${showData}`;
    series.columns.template.strokeWidth = 0;

    series.tooltip.pointerOrientation = "vertical";

    series.columns.template.column.cornerRadiusTopLeft = 10;
    series.columns.template.column.cornerRadiusTopRight = 10;
    series.columns.template.column.fillOpacity = 0.8;
    series.columns.template.column.events.on("hit", (event) => {
      const clickedDataItem = event.target.dataItem;
      chartStore.setClickedPlace(clickedDataItem.categories.categoryX);
    });
    // on hover, make corner radiuses bigger
    let hoverState = series.columns.template.column.states.create("hover");
    hoverState.properties.cornerRadiusTopLeft = 0;
    hoverState.properties.cornerRadiusTopRight = 0;
    hoverState.properties.fillOpacity = 1;

    series.columns.template.adapter.add("fill", function (fill, target) {
      return chart.current.colors.getIndex(target.dataItem.index);
    });

    let paretoValueAxis = chart.current.yAxes.push(new am4charts.ValueAxis());

    let paretoSeries = chart.current.series.push(new am4charts.LineSeries());
    paretoSeries.dataFields.valueY = "pareto";
    paretoSeries.dataFields.categoryX = "country";
    paretoSeries.yAxis = paretoValueAxis;
    paretoSeries.tooltipText = `${props.name} : {valueY.value}[/]}`;
    // "Average Waiting Time : {valueY.formatNumber('#.0')} Min[/]";
    paretoSeries.bullets.push(new am4charts.CircleBullet());
    paretoSeries.strokeWidth = 2;
    paretoSeries.stroke = new am4core.InterfaceColorSet().getFor(
      "alternativeBackground"
    );
    paretoSeries.strokeOpacity = 0.5;

    // Cursor
    chart.current.cursor = new am4charts.XYCursor();
    chart.current.cursor.behavior = "panX";
    return () => {
      chart.current.dispose();
    };
  }, [waitingData]);

  return <div id="chartkpi1" style={{ width: "100%", height: "410px" }}></div>;
}
export default ChartKPIs1;
