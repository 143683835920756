import React, { Fragment, useState,useEffect } from "react";
import { Button } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import { db } from '../../../Firebase';
import { collection, addDoc,query,onSnapshot } from 'firebase/firestore';
import { DatePicker } from "@y0c/react-datepicker";
import Select from "react-select";
import {useHistory } from "react-router-dom";


const NewsForm = () => {
   const current = new Date();
   const dateNow = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
   const currentDate = new Date()
   const [title,setTitle] = useState();
   const [date,setDate] = useState(dateNow);
   const [selectedAgence, setSelectedAgence] = useState(null);
   const [content,setContent] = useState();
   const [active,setActive] = useState(false);
   const [agences,setAgences] = useState([]);
   const history = useHistory()
   useEffect(() => {
      const q = query(collection(db, "Agences_v1"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        let todosArray = [];
        querySnapshot.forEach((doc) => {
          todosArray.push({ ...doc.data(), id: doc.id });
        });
        setAgences(todosArray.map(item=> {return {value:item.id,label:item.name}}));
      });
      return () => unsub();
    }, []);

   const handleSubmit = async (e) => {
      e.preventDefault();
     
          await addDoc(collection(db, "news"), {
              title,
              date,
              selectedAgence,
              content,
              active,
          });
          setTitle("");
          setContent("")
          setDate(currentDate.toString())
          setSelectedAgence(null)
          history.push('/news-table');
  }
 
   return (
      <Fragment>
         <PageTitle activeMenu="Agency" motherMenu="Form" />

         <div className="row">
            <div className="col-lg-12">
               <div className="card">
                  <div className="card-header">
                     <h4 className="card-title">Actualités</h4>
                  </div>
                  <div className="card-body">
                     <div className="form-validation">
                        <form
                           className="form-valide"
                        >
                           <div className="row">
                              <div className="col-xl-12">
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Titre de l'actualités
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                       <input
                                          value={title}
                                          type="text"
                                          className="form-control"
                                          id="val-username"
                                          name="val-username"
                                          placeholder="Entrez un titre.."
                                          onChange={(e) => setTitle(e.target.value)}
                                          
                                       />
                                    </div>
                                 </div>
                                 
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Nom d'agence
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                    <Select
                                          defaultValue={selectedAgence}
                                          onChange={setSelectedAgence}
                                          options={agences}
                                          style={{
                                             lineHeight: "40px",
                                             color: "#7e7e7e",
                                             paddingLeft: " 15px",
                                          }}
                                       />
                                    </div>
                                 </div>
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-username"
                                    >
                                       Date de création
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                    {/* <DatePicker initialDate={new Date()} selected={date} onChange={(date) => setDate((new Date(date)).toString())}/> */}
                                    {/* <DatePicker initialDate={new Date()} selected={new Date(date).toString()} onChange={(date) => setDate((new Date(date)).toString())}/> */}
                                    <input
                                          value={date}
                                          type="text"
                                          className="form-control"
                                          id="val-date"
                                          name="val-date"
                                          onChange={(e) => setDate(e.target.value)}
                                          
                                       />
                                    </div>
                                 </div>
                                 
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-suggestions"
                                    >
                                       Contenu{" "}
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                       <textarea
                                       value={content}
                                          className="form-control"
                                          id="val-suggestions"
                                          name="val-suggestions"
                                          rows="5"
                                          placeholder="Entrez votre contenu.."
                                          onChange={(e) => setContent(e.target.value)}
                                          
                                       ></textarea>
                                    </div>
                                 </div>
                                 <div className="form-group row">
                                    <label
                                       className="col-lg-4 col-form-label"
                                       htmlFor="val-suggestions"
                                    >
                                       Active{" "}
                                       <span className="text-danger">*</span>
                                    </label>
                                    <div className="col-lg-6">
                                    <div className="custom-control custom-checkbox">
                                                <input
                                                   type="checkbox"
                                                   className="custom-control-input"
                                                   id="gridCheck"
                                                   onChange={(e) => setActive(!active)}
                                                />
                                                <label
                                                   className="custom-control-label"
                                                   htmlFor="gridCheck"
                                                >
                                                   Oui / Non
                                                </label>
                                             </div>
                                    </div>
                                 </div>
                                 <div className="form-group row">
                                    <div className="col-lg-4 "></div>
                                    <div className="col-lg-6">
                                       <Button variant="primary" onClick={handleSubmit}>
                                       Sauvegarder{" "}
                                       </Button>
                                    </div>
                                 </div>
                              </div>
                              
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
           
         </div>
      </Fragment>
   );
};

export default NewsForm;
