import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useChartStore } from "../../../../zustand-store/chartStore";
import axios from "axios";

am4core.useTheme(am4themes_animated);

function ChartKPIs2(props) {
  const chartStore = useChartStore();
  const [data, setData] = useState([]);
  const [showData, setShowData] = useState("");
  const [Title, setTitle] = useState("");
  useEffect(() => {
    switch (props.name) {
      case "occupation":
        setTitle("Occupation maximal");
        break;
      case "waitingtime":
        setTitle("Temps d'attente maximal");
        break;
      case "queue":
        setTitle("Longueur maximale de la file d'attente");
        break;
      case "Operations":
        setTitle("Nombre d'opérations");
        break;
      case "attractivness":
        setTitle("Attrativite");
        break;
    }
  }, []);
  useEffect(() => {
    switch (props.name) {
      case "occupation":
        setShowData("Personnes");
        break;
      case "waitingtime":
        setShowData("minutes");
        break;
      case "queue":
        setShowData("Personnes");
        break;
      case "Operations":
        setShowData("Operation");
        break;
      case "attractivness":
        setShowData("%");
        break;
    }
  }, []);

  useEffect(async () => {
    if (chartStore.clickedPlace === "") {
      chartStore.setClickedPlace(chartStore.placesCheckedValues[0]);
    }
    var dataObject = [];
    var fetchedData;
    var res;
    switch (props.name) {
      case "occupation":
        res = await axios.get(
          "http://204.48.16.65:3006/occupancyDailyHourlyRange",
          {
            params: {
              startDate: chartStore.startDate,
              endDate: chartStore.endDate,
              agency: chartStore.clickedPlace,
              filter: chartStore.mealsCheckedValues,
            },
          }
        );
        fetchedData = res.data;
        for (let i = 0; i < fetchedData.length; ++i) {
          dataObject.push({
            date: new Date(fetchedData[i].date),
            visits: fetchedData[i].maxOccupancy,
          });
        }
        dataObject.sort((a, b) => a.date - b.date);
        setData(dataObject);
        break;
      case "waitingtime":
        res = await axios.get(
          "http://204.48.16.65:3006/waitingTimeDailyHourlyRange",
          {
            params: {
              startDate: chartStore.startDate,
              endDate: chartStore.endDate,
              agency: chartStore.clickedPlace,
              filter: chartStore.mealsCheckedValues,
            },
          }
        );
        fetchedData = res.data;
        for (let i = 0; i < fetchedData.length; ++i) {
          dataObject.push({
            date: new Date(fetchedData[i].date),
            visits: fetchedData[i].waitingTime.toFixed(2),
          });
        }
        dataObject.sort((a, b) => a.date - b.date);
        setData(dataObject);
        break;
      case "queue":
        res = await axios.get("http://204.48.16.65:3006/queueDailyHourlyRange", {
          params: {
            startDate: chartStore.startDate,
            endDate: chartStore.endDate,
            agency: chartStore.clickedPlace,
            filter: chartStore.mealsCheckedValues,
          },
        });
        fetchedData = res.data;
        for (let i = 0; i < fetchedData.length; ++i) {
          dataObject.push({
            date: new Date(fetchedData[i].date),
            visits: fetchedData[i].maxQueue,
          });
        }
        dataObject.sort((a, b) => a.date - b.date);
        setData(dataObject);
        break;
      case "Operations":
        res = await axios.get(
          "http://204.48.16.65:3006/effectiveDailyHourlyRange",
          {
            params: {
              startDate: chartStore.startDate,
              endDate: chartStore.endDate,
              agency: chartStore.clickedPlace,
              filter: chartStore.mealsCheckedValues,
            },
          }
        );
        fetchedData = res.data;
        for (let i = 0; i < fetchedData.length; ++i) {
          dataObject.push({
            date: new Date(fetchedData[i].date),
            visits: fetchedData[i].operation,
          });
        }
        dataObject.sort((a, b) => a.date - b.date);
        setData(dataObject);
        break;
      case "attractivness":
        res = await axios.get(
          "http://204.48.16.65:3006/attractivnessHourlyRange",
          {
            params: {
              startDate: chartStore.startDate,
              endDate: chartStore.endDate,
              agency: chartStore.clickedPlace,
              filter: chartStore.mealsCheckedValues,
            },
          }
        );
        fetchedData = res.data;
        for (let i = 0; i < fetchedData.length; ++i) {
          dataObject.push({
            date: new Date(fetchedData[i].date),
            visits: fetchedData[i].maxIn,
          });
        }
        dataObject.sort((a, b) => a.date - b.date);
        setData(dataObject);
        break;
    }
    //("render chart 2")
  }, [
    chartStore.clickedPlace,
    chartStore.startDate,
    chartStore.endDate,
    chartStore.placesCheckedValues,
    chartStore.mealsCheckedValues,
  ]);
  useLayoutEffect(() => {
    let chart = am4core.create("chartkpi2", am4charts.XYChart);
    chart.paddingRight = 20;

    chart.data = data;

    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());

    dateAxis.tooltipDateFormat = "HH:mm, d MMMM";

    dateAxis.zoomToDates(chartStore.startDate, chartStore.endDate);

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.tooltip.disabled = true;
    valueAxis.title.text = `${Title}`;
    let series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "visits";
    series.tooltipText = `{valueY.value} ${showData}`;

    series.fillOpacity = 0.3;
    // dateAxis.renderer.labels.template.adapter.add("text", function(text, target) {
    //   if (dateAxis.axisRanges.length > 0) {
    //     let startDate = dateAxis.axisRanges.getIndex(0).date;
    //     let endDate = dateAxis.axisRanges.getIndex(dateAxis.axisRanges.length - 1).endDate;
    //     if (endDate - startDate < 60 * 60 * 1000) {
    //       // If the range is less than 1 hour, format as "HH:00 - HH+1:00"
    //       return am4core.time.format(startDate, "HH:00") + " - " + am4core.time.format(endDate, "HH:00");
    //     }
    //   }
    //   // Default format: "HH:mm"
    //   return am4core.time.format(target.dataItem.date, "HH:mm");
    // });

    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineY.opacity = 0;
    chart.scrollbarX = new am4charts.XYChartScrollbar();
    chart.scrollbarX.series.push(series);

    dateAxis.start = 10000;
    dateAxis.keepSelection = true;
    // dateAxis.renderer.labels.template.adapter.add("text", (text, target) => {
    //   console.log(dateAxis.zoomLevel)
    //   if (dateAxis.zoomLevel >= 20) {
    //     const startDate = new Date(target.dataItem.dates.date);
    //     const endDate = new Date(startDate);
    //     endDate.setHours(endDate.getHours() + 1); // Assuming 1-hour intervals
    //     const dateFormatter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric' });
    //     return `${dateFormatter.format(startDate)} - ${dateFormatter.format(endDate)}`;
    //   } else {
    //     const dateFormatter = new Intl.DateTimeFormat('en-US', {  day: 'numeric', month: 'long' });
    //     return dateFormatter.format(target.dataItem.dates.date);
    //   }
    // });
    //
    return () => {
      chart.dispose();
    };
  }, [data]);

  return <div id="chartkpi2" style={{ width: "100%", height: "410px" }}></div>;
}
export default ChartKPIs2;
