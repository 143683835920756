import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { useChartStore } from "../../../../zustand-store/chartStore";
import axios from 'axios'
am4core.useTheme(am4themes_animated);
 // define colors
 let colors = {
  critical: "#9b6ad6",
  bad: "#95c4e0",
  medium: "#8da8df",
  verygood: "#9686dd",
};
const convertedDataValues = (data) => {
  return data.map((item) => {
    return {
      date: new Date(item.date),
      station: item.station,
      scoring: item.scoring,
    };
  })
}
const getColorBasedOnValue  = (value) => {
  var color ;
  if (value < 10) color = colors.verygood;
  else if (value < 20) color = colors.medium;
  else if (value <= 40) color = colors.bad;
  else if (value > 40) color = colors.critical
  return color
}
const yCategories = [
  "7h-8h,8h-9h", "9h-10h", "10h-11h", "11h-12h", "12h-13h", "13h-14h", "14h-15h", "15h-16h", "16h-17h",
  "17h-18h", "18h-19h", "19h-20h", "20h-21h", "21h-22h"
];
const frenchMonths = ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'];
const frenchDaysOfWeek = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
function ChartKPIs3(props) {
  const chartStore = useChartStore();
  const [data , setData] = useState([])
  console.log(props)
  useEffect(() => {
    const dataMap = {};
    
    // Initialize the dataMap with 0 values for hours 8-22 for each day
    if (props.data.values && props.data.values.length > 0)
    {
    props.data.values.forEach((item) => {
      const dateTimeString = item.date;
      const date = new Date(dateTimeString);
      const day = date.toISOString().split('T')[0];
      dataMap[day] = dataMap[day] || {};
      for (let hour = 8; hour <= 22; hour++) {
        dataMap[day][hour] = 0;
      }
    });
    
    // Populate dataMap with actual values
    props.data.values.forEach((item) => {
      const dateTimeString = item.date;
      const date = new Date(dateTimeString);
      const day = date.toISOString().split('T')[0];
      const hour = date.getHours();
    
       dataMap[day][hour] = item.scoring;
      
     
    
    });
  }
    const arrayData = [];
    for (const day in dataMap) {
      for (let hour = 8; hour <= 22; hour++) {
        const color = getColorBasedOnValue(dataMap[day][hour]);
        const d = new Date(day)

        const dayN = d.getDate();
        const month = d.getMonth();
        arrayData.push({
          date: new Date(day + 'T' + hour + ':00:00'),
          y: hour,
          x : frenchDaysOfWeek[d.getDay()] + ", "+ dayN+ " " + frenchMonths[month],
          dateshow: `${hour - 1}h-${hour}h`,
          color: color,
          value: dataMap[day][hour]?.toFixed(2)
        });
      }
    }
    
    // Sort the data by hour
    arrayData.sort((a, b) => b.y - a.y);
    
    setData(arrayData)
  },[props.data])
    

    
    useLayoutEffect(() => {
    //("render chart 3")
    let chart = am4core.create(props.data.station+"nd", am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; 

    chart.maskBullets = false;

    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    let yAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    // yAxis.data = yCategories;

    xAxis.dataFields.category = "x";
    yAxis.dataFields.category = "dateshow";
    xAxis.renderer.grid.template.disabled = true;
    xAxis.renderer.minGridDistance = 40;
    xAxis.renderer.labels.template.rotation = 75;
    // xAxis.renderer.labels.template.location = 1.5;
    xAxis.renderer.minGridDistance = 40;

    yAxis.renderer.grid.template.disabled = true;
    yAxis.renderer.inversed = true;
    yAxis.renderer.minGridDistance = 30;
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.categoryX = "x";
    series.dataFields.categoryY = "dateshow";
    series.dataFields.value = "value";
    series.sequencedInterpolation = true;
    series.defaultState.transitionDuration = 10;

    // Set up column appearance
    let column = series.columns.template;
    column.strokeWidth = 2;
    column.strokeOpacity = 1;
    column.stroke = am4core.color("#ffffff");
    column.tooltipText = "{x}, {y}:00 , {value.workingValue.formatNumber('#.')}";
    column.width = am4core.percent(100);
    column.height = am4core.percent(100);
    column.column.cornerRadius(6, 6, 6, 6);
    column.propertyFields.fill = "color";

    // Set up bullet appearance
    let bullet1 = series.bullets.push(new am4charts.CircleBullet());
    // bullet1.circle.propertyFields.radius = "value";
    bullet1.circle.fill = am4core.color("#000");
    bullet1.circle.strokeWidth = 0;
    bullet1.circle.fillOpacity = 0.2;
    bullet1.interactionsEnabled = false;

    let bullet2 = series.bullets.push(new am4charts.LabelBullet());
    bullet2.label.text = "{value}";
    bullet2.label.fill = am4core.color("#fff");
    bullet2.zIndex = 1;
    bullet2.fontSize = 15;
    bullet2.interactionsEnabled = false;

   
    chart.data = data;
    
    let baseWidth = Math.min(
      chart.plotContainer.maxWidth,
      chart.plotContainer.maxHeight
    );
    let maxRadius = baseWidth / Math.sqrt(chart.data.length) / 2 - 10; // 2 is jast a margin
    series.heatRules.push({
      min: 10,
      max: maxRadius,
      property: "radius",
      target: bullet1.circle,
    });

    chart.plotContainer.events.on("maxsizechanged", function () {
      let side = Math.min(
        chart.plotContainer.maxWidth,
        chart.plotContainer.maxHeight
      );
      bullet1.circle.clones.each(function (clone) {
        clone.scale = side / baseWidth;
      });
    });
    return () => {
      chart.dispose(); 
    };
  }, [data]);

  return <div id={props.data.station+"nd"} style={{ width: "100%", height: "610px" }}></div>;
}
export default ChartKPIs3;
