import { lazy, Suspense, useEffect } from 'react';
/// Components
import Index from './jsx/index';
import { connect, useDispatch } from 'react-redux';
import {  Route, Switch, withRouter, BrowserRouter } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import {Nav} from './jsx/pages/NavDash/Nav'
import { Overview } from './jsx/pages/RtrtDashboard';
import { Charts } from './jsx/pages/DetailedCharts/Charts';
import { Scoring } from './jsx/pages/ScoringCharts';
// import TvScreenSport from './jsx/pages/PlaceTvInfoSport';
const Login = lazy(() => {
    return new Promise(resolve => {
		setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
	});
});

const TvScreen = lazy(() => {
    return new Promise(resolve => {
		setTimeout(() => resolve(import('./jsx/pages/PlaceTvInfo')), 500);
	});
});
const TvScreenSport = lazy(() => {
    return new Promise(resolve => {
		setTimeout(() => resolve(import('./jsx/pages/PlaceTvInfoSport')), 500);
	});
});
// const Overview = lazy(() => {
//     return new Promise(resolve => {
// 		setTimeout(() => resolve(import('./jsx/pages/RtrtDashboard')), 500);
// 	});
// });

// const Charts = lazy(() => {
//     return new Promise(resolve => {
// 		setTimeout(() => resolve(import('./jsx/pages/DetailedCharts/Charts')), 500);
// 	});
// });

const DetailWidget2 = lazy(() => {
    return new Promise(resolve => {
		setTimeout(() => resolve(import('./jsx/pages/DetailWidget2.js')), 500);
	});
});

const AdvertisingTvScreen = lazy(() => {
    return new Promise(resolve => {
		setTimeout(() => resolve(import('./jsx/pages/AdvertisingTvScreen.js')), 500);
	});
});

function App (props) {
    //("props",props)
    const pathname = props.location.pathname
    const dispatch = useDispatch();
    useEffect(() => {
         if(!pathname.includes("/p-page-tv-screen") && 
            !pathname.includes("/NoWaiting") &&
            !pathname.includes("/p-page-advertising-tv-screen") && 
            !pathname.includes("/detail-widget") && 
            !pathname.includes("/detail-widget2") && 
            !pathname.includes("/overview")){
            checkAutoLogin(dispatch, props.history);
        }
       
    }, [dispatch, props.history]); 
    
    let routes = (  
            <Switch>
                <Route exact path="/overview/:path?">
                    <Nav>
                        <Switch>
                            <Route exact path='/overview' component={Overview} />
                            <Route exact path='/overview/:name' component={Charts} />
                        </Switch>
                    </Nav>
                </Route>
                <Route exact path="/overview/scoring/:path?">
                    <Nav>
                        <Switch>
                            <Route exact path='/overview/scoring/:name' component={Scoring} />
                        </Switch>
                    </Nav>
                </Route>
                <Route exact path='/NoWaiting/:id' component={TvScreenSport} />
                <Route exact path='/:id/p-page-tv-screen' component={TvScreen} />
                <Route path='/login' component={Login} /> 
                <Route exact path='/:agenceId/:uniqueId/p-page-advertising-tv-screen' component={AdvertisingTvScreen} />

            </Switch>
            // <Switch>
            // </Switch>
    );
    if (props.isAuthenticated) {
		return (
			<>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>  
                   }
                >
                    <Index />
                </Suspense>
            </>
        );
	
	}else{
		return (
			<div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    {routes}
                </Suspense>
			</div>
		);
	}
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App)); 
